@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-etapa-2-02{
  padding: 0rem 0rem 0rem 0rem;
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block{
    display: grid;
    grid-template-columns: 1fr 1.25fr;
    column-gap: 7.5rem;
    @include m.mq-md{
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
    .item-box:nth-of-type(1){
      @include m.mq-sm{
        padding: 0rem 3rem;
      }
      h2:nth-of-type(1){
        color: v.$azul-01;
        padding: 0rem 0rem 3rem 0rem;
        border-bottom: .3rem solid transparent;
        border-image-source: v.$gradient-dorado-01; 
        border-image-slice: 1;
        margin: 0rem 0rem 3rem 0rem;
        @include m.mq-sm{
          text-align: center;
          border: none;
          padding: 0rem 0rem 0rem 0rem;
          margin: 0rem 0rem 4rem 0rem;
        }
      }
      ul:nth-of-type(1){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap:1.5rem 0rem;
        @include m.mq-sm{
          padding: 0rem 0rem 5rem 0rem;
          border-bottom: .3rem solid transparent;
          border-image-source: v.$gradient-dorado-01; 
          border-image-slice: 1;
        }
        li{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap:0rem 3.5rem;
          @include m.mq-sm{
            font-size: v.$body-04;
          }
          span{
            i:before{
              display: flex;
              flex-direction: row;
              justify-content: center;
              width: 45px;
              
              color: v.$dorado-02;
              font-size: v.$h2-xl;
            }
          }
        }
      }
    }
    .item-box:nth-of-type(2){
      @include m.mq-md{
        grid-row: 1/2;
      }
    }
  }
}