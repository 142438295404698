@use 'base/variables' as v;
@use 'base/mixins' as m;

.modal-landing{
  z-index: 1800;
  .modal-dialog{
    max-width: 95%;
    margin-top: 0rem;

    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;

    margin: 0rem auto 0rem auto;
    @include m.mq-md{
      max-width: 50%;
    }
    @include m.mq-sm{
      max-width: 70%;
    }
    @include m.mq-xs{
      max-width: 85%;
    }
  }
  .modal-body{
    position: relative;
    padding: 0rem;
    .close-modal{
      position: absolute;
      right: 1rem;
      top: 3rem;
      z-index: 100;
      cursor: pointer;
      @include m.mq-sm{
        top: 1rem;
        right: 0rem;
      }
      span{
        i:before{
          background: v.$gradient-dorado-01;
          font-size: v.$body-01;
          color: v.$azul-01;
          padding: 2rem;
          @include m.mq-sm{
            padding: 1rem;
          }
        }
      }
    }
    .close-modal-mobile{
      cursor: pointer;
      span{
        i:before{
          display: block;
          background: v.$gradient-dorado-01;
          font-size: v.$body-01;
          color: v.$azul-02;
          padding: 1.1rem 1rem 1rem 1rem;
        }
      }
    }
    .close-modal-tabs-casas{
      cursor: pointer;
      span{
        i:before{
          display: block;
          background: v.$gradient-dorado-01;
          font-size: v.$body-01;
          color: v.$azul-02;
          padding: 1.1rem 1rem 1rem 1rem;
        }
      }
    }
    .close-modal-tabs-casas-desktop{
      position: absolute;
      right: 0;
      top: 2rem;
      z-index: 100;
      cursor: pointer;
      span{
        i:before{
          background: v.$gradient-dorado-01;
          font-size: v.$body-01;
          color: v.$azul-02;
          padding: 2rem;
        }
      }
    }
    .close-modal-galeria-01{
      position: absolute;
      right: 1rem;
      top: 3rem;
      z-index: 100;
      cursor: pointer;
      @include m.mq-sm{
        top: 1rem;
        right: 0rem;
      }
      // @include m.mq-xs{
      //   position: static;
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: center;
      //   margin:0rem 0rem 10rem 0rem;
      // }
      span{
        i:before{
          background: v.$gradient-dorado-01;
          font-size: v.$body-01;
          color: v.$azul-01;
          padding: 2rem;
          @include m.mq-sm{
            padding: 1rem;
          }
          // @include m.mq-xs{
          //   background: none;
          //   padding: 1rem;
          //   color: v.$azul-02;
          //   color: v.$azul-02;
          //   border: .1rem solid v.$azul-01;
          // }
        }
      }
    }
    .close-modal-galeria-02{
      position: absolute;
      right: 1rem;
      top: 3rem;
      z-index: 100;
      cursor: pointer;
      @include m.mq-sm{
        top: 1rem;
        right: 0rem;
      } 
      @include m.mq-xs{
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin:0rem 0rem 10rem 0rem;
      }
      span{
        i:before{
          background: v.$gradient-dorado-01;
          font-size: v.$body-01;
          color: v.$azul-01;
          padding: 2rem;
          @include m.mq-sm{
            padding: 1rem;
          }
          @include m.mq-xs{
            background: none;
            padding: 1rem;
            color: v.$dorado-01;
            border: .1rem solid v.$dorado-01;
          }
        }
      }
    }
  }
}

.modal-landing.modal-landing--obra{
  .modal-dialog{
    max-width: 95%;
    margin-top: 0rem;
    @include m.mq-sm{
      max-width: 90%;
      // margin: 1.5rem auto 0rem auto;
      margin: 0rem auto 0rem auto;
    }
  }
  .modal-body{
    background: v.$azul-02;
    padding: 5rem 6rem;
    @include m.mq-sm{
      // padding: 5rem 1.5rem 1.5rem 1.5rem;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    }
    // VIDEO 
    #video-interaction-01,
    #video-interaction-02{
      max-width: 945px;
      margin: 0rem auto 0rem auto;
      box-shadow: 0rem .5rem 1rem rgba(0,0,0,.3);
      // border-radius: 1.5rem;
      @media screen and (max-width: v.$md) {
        margin: 0rem auto 0rem auto;
      }
    }

    #img-preview-video-01,
    #img-preview-video-02{
      &:hover{
        cursor: pointer;
      }
    }

    .video{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    video{
      box-shadow: 0rem .5rem 1rem rgba(0,0,0,.3);
      max-width: 945px;
      width: 100%;
      height: auto;
      // border-radius: 1.5rem;
    }
  }
}

.modal-landing.modal-landing--plano{
  .item-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    p:nth-of-type(1){
      width: 100%;
      padding: .6rem .5rem;
      background: v.$gradient-dorado-01;
      color: v.$azul-01;
      font-family: v.$font-bold-01;
      font-size: v.$h3-sm;
      text-align: center;
    }
    p:nth-of-type(1).etapa-2{
      background: v.$gradient-azul-01;
      color: v.$blanco;
    }
  }
  .tooltip-modal-mobile{
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    z-index: 200;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // gap: 0rem 1.5rem;
    padding: 0rem 0rem 0rem 0rem;

    // border-top: .4rem solid transparent;
    // border-image-source: v.$gradient-dorado-01; 
    // border-image-slice: 1;
    .content-tooltip{
      display: block;
      width: 100%;
      p:nth-of-type(1){
        font-family: v.$font-regular-01;
        font-size: v.$h3-sm;
        margin: 0rem 0rem .5rem 0rem;
        padding: 1rem .5rem 0rem .5rem;
        text-align: center;
        span{
          font-family: v.$font-bold-01;
        }
      }
      p:nth-of-type(2){
        font-size: v.$body-03;
        text-align: center;
        padding: 0rem .5rem .5rem .5rem;
      }
      p:nth-of-type(3){
        font-family: v.$font-bold-01;
        font-size: v.$body-01;
        background: v.$gradient-dorado-01;
        color: v.$azul-01;
        text-align: center;
        // padding: 0rem 1;
        padding: 0rem .5rem .5rem .5rem;
        display: block;
      }
      // img{
      //   width: 5.5rem;
      // }
    }
    &.tooltip-modal-mobile--negro{
      background: v.$negro;
      .content-tooltip{
        p:nth-of-type(1){
          color: v.$blanco;
        }
        p:nth-of-type(2){
          color: v.$blanco;
        }
      }
    }
    &.tooltip-modal-mobile--blanco{
      // background: v.$blanco;
      background: v.$negro;
      .content-tooltip{
        p:nth-of-type(1){
          color: v.$blanco;
        }
        p:nth-of-type(2){
          color: v.$blanco;
        }
        p:nth-of-type(3){
          color: v.$blanco;
          background: v.$gradient-azul-01;
        }
      }
    }
  }
  .indicador-mobile{
    width: 1.9rem;
    height: 1.9rem;
    background: url('./../../build/img/indicador-tooltip.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    position: absolute; // -> Testing
    z-index: 200;
    @include m.mq-xs-02{
      width: 1.6rem;
      height: 1.6rem;
    }
    &:hover{
      transform: scale(1.1);
    }
    // ETAPA 01
    // &.item-01{
    //   right:7%;
    //   top:76%;
    // }
    // &.item-02{
    //   right:24%;
    //   top:76%;
    // }
    // &.item-04{
    //   right:45%;
    //   top:66%;
    // }
    &.item-06{
      right:36%;
      top:50%;
    }
    &.item-11{
      right:46%;
      top:48.5%;
    }
    &.item-12{
      right:42%;
      top:41%;
    }
    &.item-13{
      right:38.5%;
      top:34.5%;
    }
    &.item-17{
      right:55%;
      top:39%;
    }
    &.item-20{
      right:72.5%;
      top:52.5%;
    }
    &.item-21{
      right:68.5%;
      top:45.5%;
    }
    &.item-22{
      right:64%;
      top:37.5%;
    }
    &.item-23{
      right:60%;
      top:30%;
    }
    // &.item-24{
    //   right:59.5%;
    //   top:7%;
    // }
    // &.item-27{
    //   right:58.5%;
    //   top:75%;
    // }
    // &.item-29{
    //   right:76%;
    //   top:65.5%;
    // }
    &.item-31{
      right:78%;
      top:82%;
    }
    &.item-32{
      right:82%;
      top:75%;
    }


    // ETAPA 02
    &.item-52{
      right:81%;
      top:49.5%;
    }
    &.item-53{
      right:76%;
      top:53.5%;
    }
    &.item-54{
      right:71%;
      top:58.5%;
    }
    &.item-55{
      right:76%;
      top:33%;
    }
    &.item-56{
      right:70.5%;
      top:38.5%;
    }
    &.item-57{
      right:64.5%;
      top:44%;
    }
    &.item-58{
      right:58%;
      top:49%;
    }
    &.item-59{
      right:52.5%;
      top:54.5%;
    }
    &.item-60{
      right:47%;
      top:59.5%;
    }
    &.item-61{
      right:61%;
      top:28.25%;
    }
    &.item-62{
      right:55.5%;
      top:33.75%;
    }
    &.item-63{
      right:49.5%;
      top:38.25%;
    }
    &.item-64{
      right:43%;
      top:44.25%;
    }
    &.item-65{
      right:37.5%;
      top:49.75%;
    }
    &.item-66{
      right:32%;
      top:54.75%;
    }
    &.item-67{
      right:36%;
      top:27.5%;
    }
    &.item-68{
      right:30.5%;
      top:33%;
    }
    &.item-69{
      right:24.5%;
      top:38.5%;
    }
    &.item-70{
      right:18%;
      top:44.5%;
    }
    &.item-71{
      right:12.5%;
      top:50%;
    }
  }

  .tooltip-modal-desktop{
    position: absolute;
    bottom: 2.5rem;
    left: 2.5rem;
    z-index: 200;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0rem 1.5rem;
    padding: 0rem 0rem 0rem 0rem;

    // border-top: .4rem solid transparent;
    // border-image-source: v.$gradient-dorado-01; 
    // border-image-slice: 1;

    transform-origin: left bottom;
    transform: scale(1.4);
    .content-tooltip{
      display: block;
      width: 100%;
      p:nth-of-type(1){
        font-family: v.$font-regular-01;
        font-size: v.$h3-sm;
        color: v.$blanco;
        margin: 0rem 0rem .5rem 0rem;
        padding: 1rem 1rem 0rem 1rem;
        text-align: center;
        span{
          font-family: v.$font-bold-01;
        }
      }
      p:nth-of-type(2){
        font-size: v.$body-03;
        padding: 0rem 1rem 1rem 1rem;
        text-align: center;
      }
      p:nth-of-type(3){
        font-family: v.$font-bold-01;
        font-size: v.$body-01;
        background: v.$gradient-dorado-01;
        color: v.$azul-01;
        text-align: center;
        padding: 0rem 1;
      }
      img{
        width: 5.5rem;
      }
    }
    &.tooltip-modal-desktop--negro{
      background: v.$negro;
      .content-tooltip{
        p:nth-of-type(1){
          color: v.$blanco;
        }
        p:nth-of-type(2){
          color: v.$blanco;
        }
      }
    }
    &.tooltip-modal-desktop--blanco{
      // background: v.$blanco;
      background: v.$negro;
      .content-tooltip{
        p:nth-of-type(1){
          // color: v.$azul-01;
          color: v.$blanco;
        }
        p:nth-of-type(2){
          // color: v.$azul-01;
          color: v.$blanco;
        }
        p:nth-of-type(3){
          color: v.$blanco;
          background: v.$gradient-azul-01;
        }
      }
    }
  }
  .indicador-desktop{
    width: 2.2rem;
    height: 2.2rem;
    background: url('./../../build/img/indicador-tooltip.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    position: absolute; // -> Testing
    @include m.mq-lg{
      width: 1.6rem;
      height: 1.6rem;
    }
    &:hover{
      transform: scale(1.1);
    }
    // ETAPA 01
    &.item-01{
      right:10%;
      top:27%;
    }
    &.item-02{
      right:10%;
      top:39%;
    }
    &.item-04{
      right:15%;
      top:57%;
    }
    &.item-06{
      right:30.4%;
      top:51%;
    }
    &.item-11{
      right:31.5%;
      top:58%;
    }
    &.item-12{
      right:37%;
      top:54.5%;
    }
    &.item-13{
      right:42.5%;
      top:51%;
    }
    &.item-17{
      right:38%;
      top:64.5%;
    }
    &.item-20{
      right:29%;
      top:78.5%;
    }
    &.item-21{
      right:34%;
      top:75.5%;
    }
    &.item-22{
      right:39%;
      top:72.25%;
    }
    &.item-23{
      right:44%;
      top:69%;
    }
    &.item-24{
      right:50%;
      top:65%;
    }
    &.item-27{
      right:10%;
      top:64.5%;
    }
    &.item-29{
      right:16%;
      top:77%;
    }
    &.item-31{
      right:9%;
      top:82%;
    }
    &.item-32{
      right:14.5%;
      top:86%;
    }


    // ETAPA 02
    &.item-52{
      right:20.25%;
      top:44.5%;
    }
    &.item-53{
      right:18%;
      top:41.75%;
    }
    &.item-54{
      right:15.75%;
      top:38.75%;
    }
    &.item-55{
      right:27.5%;
      top:41%;
    }
    &.item-56{
      right:25%;
      top:38%;
    }
    &.item-57{
      right:22.75%;
      top:35%;
    }
    &.item-58{
      right:20.5%;
      top:32.25%;
    }
    &.item-59{
      right:18.25%;
      top:29.25%;
    }
    &.item-60{
      right:16%;
      top:26.5%;
    }
    &.item-61{
      right:29.5%;
      top:33.5%;
    }
    &.item-62{
      right:27%;
      top:30.5%;
    }
    &.item-63{
      right:24.75%;
      top:27.75%;
    }
    &.item-64{
      right:22.5%;
      top:25%;
    }
    &.item-65{
      right:20.25%;
      top:22%;
    }
    &.item-66{
      right:17.75%;
      top:19%;
    }
    &.item-67{
      right:29%;
      top:21.25%;
    }
    &.item-68{
      right:26.75%;
      top:18.25%;
    }
    &.item-69{
      right:24.5%;
      top:15.5%;
    }
    &.item-70{
      right:22.25%;
      top:12.75%;
    }
    &.item-71{
      right:20%;
      top:10.25%;
    }
  }
}

.modal-landing.modal-landing--galeria-01{
  .modal-dialog{
    max-width: 95%;
    margin-top: 0rem;
    @include m.mq-md{
      max-width: 60%;
      margin: 0rem auto 0rem auto;
    }
    @include m.mq-xs{
      max-width: 100%;
      margin: 0rem auto 0rem auto;
    }
  }
  .modal-body{
    // background: v.$dorado-01;
    background: v.$azul-01;
    padding: 5rem 0rem 2rem 0rem;
    @include m.mq-md{
      // padding: 4rem 0rem 12rem 0rem;
      padding: 2rem 0rem 2rem 0rem;
    }
    @include m.mq-xs{
      // padding: 4rem 0rem 12rem 0rem;
      padding: 9rem 0rem 9rem 0rem;
    }
    .tx-helper{
      font-family: v.$font-regular-02;
      font-size: v.$body-04;
      color: v.$blanco;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 1rem 0rem 0rem 0rem;
      padding: 0rem 1.5rem;
      gap:0rem 1rem;
      span{
        i:before{
          font-size: v.$h2-sm;
        }
      }
    }
  }
}

.modal-landing.modal-landing--galeria-02{
  .modal-dialog{
    max-width: 95%;
    margin-top: 0rem;
    @include m.mq-md{
      max-width: 60%;
      margin: 0rem auto 0rem auto;
    }
    @include m.mq-xs{
      max-width: 100%;
      margin: 0rem auto 0rem auto;
    }
  }
  .modal-body{
    background: v.$azul-01;
    padding: 1.5rem 0rem 2.5rem 0rem;
    @include m.mq-xs{
      // padding: 5rem 1.5rem 1.5rem 1.5rem;
      padding: 4rem 0rem 15rem 0rem;
    }
    .tx-helper{
      font-family: v.$font-regular-02;
      font-size: v.$body-04;
      color: v.$blanco;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 1rem 0rem 0rem 0rem;
      padding: 0rem 1.5rem;
      gap:0rem 1rem;
      span{
        i:before{
          font-size: v.$h1-sm;
        }
      }
    }
  }
}

.modal-landing.modal-landing--casa-etapa-01{
  .modal-dialog{
    max-width: 1350px;
    margin: 0rem auto 0rem auto;
    @include m.mq-xl{
      max-width: 1150px;
    }
    @include m.mq-lg{
      max-width: 85%;
    }
  }
  .modal-body{
    display: grid;
    grid-template-columns: 65% 35%;
    // column-gap: 8rem;
    padding:3.5rem 0rem 3.5rem 12rem;
    @include m.mq-md{
      padding:3.5rem 0rem 3.5rem 2rem;
    }
    @include m.mq-sm{
      grid-template-columns: 1fr;
      padding:0rem 0rem 0rem 0rem;
    }
    .content-spec{
      .content-interaction{
        padding: 0rem 6rem 0rem 3.5rem;
        @include m.mq-md{
          padding: 1rem 6rem 1rem 2.5rem;
        }
        @include m.mq-sm{
          padding: 1rem 1rem 1rem 1rem;
          background: v.$negro;
        }
        ul{
          @include m.mq-sm{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 1.5rem;
            row-gap: 1.5rem;
          }
          li{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:0rem 1rem;
            margin:0rem 0rem 3rem 0rem;
            @include m.mq-xs-01{
              flex-direction: column;
              text-align: center;
              gap:.2rem 0rem;
            }
            span{
              width: 8rem;
              text-align: center;
              @include m.mq-sm{
                width: 4rem;
              }
              i:before{
                font-size: v.$h1-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h3-sm;
                }
              }
              .icon-spec-parqueadero:before{
                font-size: v.$h2-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h4-sm;
                }
              }
            }
            @include m.mq-sm{
              color: v.$blanco;
              margin:0rem 0rem 0rem 0rem;
              font-size: v.$body-05;
            }
          }
          li:nth-of-type(1){
            font-size: v.$h3-xl;
            font-family: v.$font-bold-01;
            color: v.$dorado-02;
            text-align: center;
            margin: 0rem 0rem 5rem 0rem;
            justify-content: center;
            align-items: center;
            @include m.mq-sm{
              font-size: v.$body-04;
              color: v.$blanco;
              margin: 0rem 0rem 0rem 0rem;
            }
          }
          li:nth-of-type(6){
            margin:0rem 0rem 4rem 0rem;
            @include m.mq-sm{
              margin: 0rem 0rem 0rem 0rem;
            }
          }
          li:last-of-type{
            font-size: v.$body-05;
            text-align: center;
            @include m.mq-sm{
              display: none;
            }
          }
        }
      }
    }
  }
  .block-nav{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // align-items: flex-end;
    align-items: flex-start;
    // padding: 1rem 0rem 0rem 0rem;
    .nav.nav-tabs{
      width: 100%;
      .nav-item{
        width: 50%;
        text-align: center;
        .nav-link{
          font-family: v.$font-bold-01;
          font-size: v.$body-03;
          background: v.$negro;
          color: v.$blanco;
          padding: 1.5rem 0rem 1.2rem 0rem;
          border: none;
          border-radius: 0rem;
          cursor: pointer;
          border-top: .5rem solid transparent;
          border-image-source: v.$negro; 
          border-image-slice: 1;
          // @include m.mq-sm{
          //   font-size: v.$h3-sm;
          //   padding: .5rem 0rem .2rem 0rem;
          // }
          @include m.mq-sm{
            margin:0rem 0rem 0rem 0rem;
            color: v.$blanco;
            font-size: v.$body-02;
            padding: 1rem 0rem .6rem 0rem;
          }
        }
        .nav-link.active{
          border: none;
          border-radius: 0rem;
          background: v.$blanco;
          color: v.$negro;
          border-top: .5rem solid transparent;
          border-image-source: v.$gradient-dorado-01; 
          border-image-slice: 1;
        }
      }
    }
    .nav-tabs {
      border-bottom: none;
    }
  }
  .tab-content{
    position: relative;
    .tab-pane{
      position: relative;
    // padding: 1rem 0rem 0rem 0rem;
      .nav.nav-tabs{
        width: 100%;
        .nav-item{
          width: 50%;
          text-align: center;
          .nav-link{
            font-family: v.$font-bold-01;
            font-size: v.$h3-xl;
            background: v.$negro;
            color: v.$blanco;
            padding: 1rem 0rem .7rem 0rem;
            border: none;
            border-radius: 0rem;
            cursor: pointer;
            border-bottom: .5rem solid transparent;
            border-image-source: v.$negro; 
            border-image-slice: 1;
            // @include m.mq-sm{
            //   font-size: v.$h3-sm;
            //   padding: .5rem 0rem .2rem 0rem;
            // }
            @include m.mq-sm{
              border: .2rem solid transparent;
              border-image-source: v.$negro; 
              border-image-slice: 1;
              font-family: v.$font-regular-02;
              font-size: v.$body-04;
              text-transform: uppercase;
            }
          }
          .nav-link.active{
            border: none;
            border-radius: 0rem;
            background: v.$blanco;
            color: v.$negro;
            border-bottom: .5rem solid transparent;
            border-image-source: v.$gradient-dorado-01; 
            border-image-slice: 1;
            @include m.mq-sm{
              border: .2rem solid transparent;
              border-image-source: v.$gradient-dorado-01; 
              border-image-slice: 1;
              font-family: v.$font-bold-02;
              font-size: v.$body-04;
            }
          }
        }
      }
      .content-tab{
        display: block;
        @include m.mq-sm{
          display: grid;
          grid-template-columns: 1fr;
          .block-nav{
            grid-row: 1/2;
            padding: 1rem 3.5rem 0rem 3.5rem;
          }
        }
      }
      .indicador{
        width: 1.9rem;
        height: 1.9rem;
        background: url('./../../build/img/landing/indicador-tooltip.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        cursor: pointer;
        position: absolute; // -> Testing
        z-index: 1000;
        @include m.mq-xs-02{
          width: 1.6rem;
          height: 1.6rem;
        }
        &:hover{
          transform: scale(1.1);
        }
        // CASA A PISO 01
        &.item-a-01-01{
          left:23%;
          top:55%;
          @include m.mq-sm{
            left:30%;
            top:20%;
          }
        }
        &.item-a-01-02{
          left:55%;
          top:50%;
          @include m.mq-sm{
            left:42%;
            top:50%;
          }
        }
        &.item-a-01-03{
          left:65%;
          top:15%;
          @include m.mq-sm{
            left:80%;
            top:65%;
          }
        }
        &.item-a-01-04{
          left:75%;
          top:60%;
          @include m.mq-sm{
            left:35%;
            top:80%;
          }
        }
        // CASA A PISO 02
        &.item-a-02-01{
          left:22%;
          top:47%;
          @include m.mq-sm{
            left:47%;
            top:20%;
          }
        }
        &.item-a-02-02{
          left:37%;
          top:47%;
          @include m.mq-sm{
            left:47%;
            top:35%;
          }
        }
        &.item-a-02-03{
          left:55%;
          top:60%;
          @include m.mq-sm{
            left:32%;
            top:55%;
          }
        }
        &.item-a-02-04{
          left:65%;
          top:45%;
          @include m.mq-sm{
            left:37%;
            top:75%;
          }
        }
        // CASA B PISO 01
        &.item-b-01-01{
          left:11%;
          top:67%;
          @include m.mq-sm{
            left:27%;
            top:8%;
          }
        }
        &.item-b-01-02{
          left:27%;
          top:50%;
          @include m.mq-sm{
            left:45%;
            top:27%;
          }
        }
        &.item-b-01-03{
          left:52%;
          top:47%;
          @include m.mq-sm{
            left:48%;
            top:52%;
          }
        }
        &.item-b-01-04{
          left:82%;
          top:59%;
          @include m.mq-sm{
            left:35%;
            top:83%;
          }
        }
        &.item-b-01-05{
          left:73%;
          top:11%;
          @include m.mq-sm{
            left:83%;
            top:75%;
          }
        }
        // CASA B PISO 02
        &.item-b-02-01{
          left:26%;
          top:48%;
          @include m.mq-sm{
            left:46%;
            top:26%;
          }
        }
        &.item-b-02-02{
          left:61%;
          top:47%;
          @include m.mq-sm{
            left:46%;
            top:63%;
          }
        }
        &.item-b-02-03{
          left:61%;
          top:40%;
          @include m.mq-sm{
            left:54%;
            top:63%;
          }
        }
        &.item-b-02-04{
          left:55%;
          top:67%;
          @include m.mq-sm{
            left:27%;
            top:57%;
          }
        }
        &.item-b-02-05{
          left:77%;
          top:40%;
          @include m.mq-sm{
            left:54%;
            top:78%;
          }
        }
      }
    }
  }
}

.modal-landing.modal-landing--casa-etapa-02{
  .modal-dialog{
    max-width: 1350px;
    margin: 0rem auto 0rem auto;
    @include m.mq-xl{
      max-width: 1150px;
    }
    @include m.mq-lg{
      max-width: 85%;
    }
  }
  .modal-body{
    display: grid;
    grid-template-columns: 65% 35%;
    // column-gap: 8rem;
    padding:3.5rem 0rem 3.5rem 8rem;
    @include m.mq-md{
      padding:3.5rem 0rem 3.5rem 2rem;
    }
    @include m.mq-sm{
      grid-template-columns: 1fr;
      padding:0rem 0rem 0rem 0rem;
    }
    .content-spec{
      .content-interaction{
        padding: 0rem 6rem 0rem 3.5rem;
        @include m.mq-md{
          padding: 1rem 6rem 1rem 2.5rem;
        }
        @include m.mq-sm{
          padding: 1rem 1rem 1rem 1rem;
          background: v.$negro;
        }
        ul{
          @include m.mq-sm{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 1.5rem;
            row-gap: 1.5rem;
          }
          li{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:0rem 1rem;
            margin:0rem 0rem 3rem 0rem;
            @include m.mq-xs-01{
              flex-direction: column;
              text-align: center;
              gap:.2rem 0rem;
            }
            span{
              width: 8rem;
              text-align: center;
              @include m.mq-sm{
                width: 4rem;
              }
              i:before{
                font-size: v.$h1-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h3-sm;
                }
              }
              .icon-spec-parqueadero:before{
                font-size: v.$h2-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h4-sm;
                }
              }
              .icon-spec-pasillo:before{
                font-size: v.$h3-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h5-sm;
                }
              }
              // .icon-spec-habitacion:before{
              //   font-size: v.$h2-xl;
              //   color: v.$dorado-02;
              //   @include m.mq-sm{
              //     font-size: v.$h4-sm;
              //   }
              // }
            }
            @include m.mq-sm{
              color: v.$blanco;
              margin:0rem 0rem 0rem 0rem;
              font-size: v.$body-05;
            }
          }
          li:nth-of-type(1){
            font-size: v.$h3-xl;
            font-family: v.$font-bold-01;
            color: v.$dorado-02;
            text-align: center;
            margin: 0rem 0rem 5rem 0rem;
            justify-content: center;
            align-items: center;
            @include m.mq-sm{
              font-size: v.$body-04;
              color: v.$blanco;
              margin: 0rem 0rem 0rem 0rem;
            }
          }
          li:nth-of-type(6){
            margin:0rem 0rem 4rem 0rem;
            @include m.mq-sm{
              margin: 0rem 0rem 0rem 0rem;
            }
          }
          li:last-of-type{
            font-size: v.$body-05;
            text-align: center;
            @include m.mq-sm{
              display: none;
            }
          }
        }
      }
    }
  }
  .content-tab{
    display: block;
    @include m.mq-sm{
      display: grid;
      grid-template-columns: 1fr;
      .block-nav{
        grid-row: 1/2;
        // padding: 1rem 3.5rem 0rem 3.5rem;
      }
    }
  }
  .tab-pane{
    position: relative;
  }
  .indicador{
    width: 1.9rem;
    height: 1.9rem;
    background: url('./../../build/img/landing/indicador-tooltip.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    position: absolute; // -> Testing
    z-index: 1000;
    @include m.mq-xs-02{
      width: 1.6rem;
      height: 1.6rem;
    }
    &:hover{
      transform: scale(1.1);
    }
    // CASA C PISO 01
    &.item-c-01-01{
      left:15%;
      top:20%;
      @include m.mq-sm{
        left:21%;
        top:85%;
      }
    }
    &.item-c-01-02{
      left:39%;
      top:20%;
      @include m.mq-sm{
        left:21%;
        top:58%;
      }
    }
    &.item-c-01-03{
      left:39%;
      top:35%;
      @include m.mq-sm{
        left:35%;
        top:58%;
      }
    }
    &.item-c-01-04{
      left:55%;
      top:27%;
      @include m.mq-sm{
        left:28%;
        top:41%;
      }
    }
    &.item-c-01-05{
      left:92%;
      top:3%;
      @include m.mq-sm{
        left:3%;
        top:5%;
      }
    }
    &.item-c-01-06{
      left:80%;
      top:27%;
      @include m.mq-sm{
        left:25%;
        top:20%;
      }
    }
    &.item-c-01-07{
      left:39%;
      top:70%;
      @include m.mq-sm{
        left:65%;
        top:58%;
      }
    }
    &.item-c-01-08{
      left:51%;
      top:88%;
      @include m.mq-sm{
        left:88%;
        top:45%;
      }
    }
    &.item-c-01-09{
      left:63%;
      top:66%;
      @include m.mq-sm{
        left:70%;
        top:25%;
      }
    }
    // CASA C PISO 02
    &.item-c-02-01{
      left:25.5%;
      top:33%;
      @include m.mq-sm{
        left:33%;
        top:70%;
      }
    }
    &.item-c-02-02{
      left:60%;
      top:40%;
      @include m.mq-sm{
        left:40%;
        top:45%;
      }
    }
    &.item-c-02-03{
      left:72%;
      top:35%;
      @include m.mq-sm{
        left:36%;
        top:26%;
      }
    }
    &.item-c-02-04{
      left:23%;
      top:90%;
      @include m.mq-sm{
        left:90%;
        top:73%;
      }
    }
    &.item-c-02-05{
      left:45%;
      top:75%;
      @include m.mq-sm{
        left:70%;
        top:45%;
      }
    }
  }
  .block-nav{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // align-items: flex-end;
    align-items: flex-start;
    // padding: 1rem 0rem 0rem 0rem;
    .nav.nav-tabs{
      width: 100%;
      .nav-item{
        width: 50%;
        text-align: center;
        .nav-link{
          font-family: v.$font-bold-01;
          font-size: v.$h3-xl;
          background: v.$negro;
          color: v.$blanco;
          padding: 1rem 0rem .7rem 0rem;
          border: none;
          border-radius: 0rem;
          cursor: pointer;
          border-bottom: .5rem solid transparent;
          border-image-source: v.$negro; 
          border-image-slice: 1;
          @include m.mq-sm{
            font-family: v.$font-bold-01;
            margin:0rem 0rem 0rem 0rem;
            color: v.$blanco;
            font-size: v.$body-02;
            padding: 1rem 0rem .6rem 0rem;
          }
        }
        .nav-link.active{
          border: none;
          border-radius: 0rem;
          background: v.$blanco;
          color: v.$negro;
          border-bottom: .5rem solid transparent;
          border-image-source: v.$gradient-dorado-01; 
          border-image-slice: 1;
        }
      }
    }
    .nav-tabs {
      border-bottom: none;
    }
  }
}

.modal-landing.modal-landing--tyc{
  .modal-dialog{
    position: relative;
    max-width: 95%;
    margin-top: 0rem;
    @include m.mq-sm{
      max-width: 90%;
      margin: 0rem auto 0rem auto;
    }
  }
  .modal-body{
    padding: 7rem 1rem 1rem 2rem;
    // max-height: 480px;
    @include m.mq-sm{
      // padding: 5rem 1.5rem 1.5rem 1.5rem;
      padding: 5rem .5rem 1rem 1.5rem;
    }
    .content-terminos-condiciones{
      padding: 0rem 0rem 0rem 0rem;
      overflow-y: scroll;
      max-height: 580px;
      @include m.mq-sm{
        max-height: 430px;
      }
    }
  }
}