@use 'base/variables' as v;
@use 'base/mixins' as m;

/* HEADER */
.identidad-visual{
  max-width: 200px;
  @include m.mq-lg{
    max-width: 145px;
  }
  @include m.mq-md{
    margin-left: 3rem;
  }
}

@keyframes opacity-icon {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.icon-transition{
  animation-name: opacity-icon;
  animation-duration: .5s;
  animation-iteration-count: 1;
}

.header{
  padding: 1.5rem 0rem;
  box-shadow: 0rem 0rem 1.5rem v.$gris-01;
  background: v.$blanco;
  position: fixed;
  top: 0rem;
  z-index: 1100;
  width: 100%;
  @include m.mq-sm{
    padding: 2.2rem 0rem;
  }
  &.active{
    @include m.mq-md{
      height: 100vh;
    }
  }
  // .container{
  //   @include m.mq-md{
  //     padding: 0rem 0rem;
  //   }
  // }
  .navbar{
    padding: 0rem 0rem;
  }
  .navbar-expand-lg{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    .navbar-toggler{
      margin-right: 3rem;
      padding: 0rem;
      outline: none;
      span{
        i:before{
          font-size: v.$h4-xl;
          background: v.$gradient-dorado-01; 
          -webkit-background-clip: text;
          color: transparent;
          opacity: 1;
        }
      }
    }
    .navbar-collapse {
      flex-grow: unset;
      .navbar-nav{
        align-items: center;
        // gap: 0rem 1.3rem;
        gap: 0rem .7rem;
        @include m.mq-lg{
          gap: 0rem .5rem;
        }
        @include m.mq-md{
          box-shadow: inset 0rem .5rem 1.5rem  v.$gris-01;
          margin-top: 1.5rem;
        }
        @include m.mq-sm{
          margin-top: 2.2rem;
        }
      }
      .nav-item{
        @include m.mq-md{
          display: block;
          width: 100%;
          text-align: center;
        }
        .nav-link{
          color: v.$azul-01;
          font-family: v.$font-bold-01;
          // padding: 1.8rem 3.5rem 1.8rem 3.5rem;
          padding: .6rem 1.8rem .6rem 1.8rem;
          border-bottom: .2rem solid transparent;
          @include m.mq-lg{
            font-size: v.$h6-sm;
            padding: .6rem 1.5rem .6rem 1.5rem;
          }
          @include m.mq-md{
            padding: 2.5rem 3rem 2.5rem 3rem;
            font-family: v.$font-regular-01;
            font-size: v.$h3-sm;
            // font-size: v.$h3-sm;
            border-bottom: 1px solid v.$gris-01;
            margin: 0rem 3rem;
          }
          &:hover{
            color: v.$dorado-02;
            transition: ease-in-out .3s;
          }
        }
        .nav-link.active{
          // color: v.$dorado-02;
          color: v.$azul-01;
          border-bottom: .2rem solid transparent;
          border-image-source: v.$gradient-dorado-01; 
          border-image-slice: 1;
          @include m.mq-md{
            border-bottom: .5rem solid transparent;
            border-image-source: v.$gradient-dorado-01; 
            border-image-slice: 1;
          }
        }
        .nav-link.faqs{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: auto;
          padding: 0rem 0rem 0rem 0rem;
          @include m.mq-md{
            padding:2.5rem 3rem 2.5rem 3rem;
          }
          &:hover{
            @include m.mq-md{
              color: v.$azul-01;
            }
          }
          &:hover .faqs{
            // background: v.$dorado-02;
            background: v.$gradient-dorado-01;
            transition: ease-in-out .3s;
            color: v.$azul-01;
            @include m.mq-md{
              background: v.$azul-01;
              color: v.$blanco;
            }
          }
          &.active{
            border: none;
            .faqs{
              // background: v.$dorado-02;
              background: v.$gradient-dorado-01;
              color: v.$azul-01;
              @include m.mq-md{
                background: v.$azul-01;
                color: v.$blanco;
                // background: v.$gradient-dorado-01;
                // color: v.$azul-01;
              }
            }
            @include m.mq-md{
              border-bottom: .5rem solid transparent;
              border-image-source: v.$gradient-dorado-01; 
              border-image-slice: 1;
              color: v.$azul-01;
              &:hover .faqs{
                background: v.$azul-01;
                color: v.$blanco;
              }
            }
          }
          .faqs{
          padding: .5rem 1.2rem;
          border-radius: 1.75rem;
          background: v.$azul-01;
          color: v.$blanco;
          margin:0rem 3.5rem 0rem 3.5rem;
            @include m.mq-lg{
              margin:.5rem 2.5rem .5rem 2.5rem;
              padding: .4rem 1rem;
            }
            @include m.mq-md{
              margin:.5rem 1.2rem .5rem 0rem;
              padding: .4rem 1.3rem;
            }
          }
        }
        .nav-link.contacto{
          color: v.$azul-01;
          font-size: v.$h3-sm;
          background: v.$gradient-dorado-01;
          padding: 1.4rem 3rem 1.4rem 3rem;
          font-family: v.$font-bold-01;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap:0rem 1.5rem;
          @include m.mq-lg{
            // font-size: v.$h5-sm;
            font-size: v.$h3-sm;
            padding: 1rem 2.5rem 1rem 2.5rem;
            margin: 1rem 0rem 1rem 0rem;
          }
          @include m.mq-md{
            margin: 2.5rem 2rem 1.5rem 2rem;
          }
          @include m.mq-sm{
            margin: 2.5rem 2rem .8rem 2rem;
          }
          &:hover{
            // color: v.$blanco;
            color: v.$azul-01;
            // background: v.$gradient-azul-01;
            background: v.$gradient-dorado-01;
            transition: ease-in-out .3s;
          }
        //   &.active{
        //     color: v.$blanco;
        //     background: v.$gradient-azul-01;
        //     border: none;
        //   }
        }
      }
    }
  }
}