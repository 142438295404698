/* FONTS */
@font-face{
	font-family: 'Arsenal-Bold';
	src: url('./../../build/fonts/Arsenal-Bold.ttf') format("opentype");
	font-weight:normal;
	font-display:swap;
}
@font-face{
	font-family: 'Arsenal-Regular';
	src: url('./../../build/fonts/Arsenal-Regular.ttf') format("opentype");
	font-weight:normal;
	font-display:swap;
}
@font-face{
	font-family: 'Helvetica-Bold';
	src: url('./../../build/fonts/Helvetica-Bold.ttf') format("truetype");
	font-weight:normal;
	font-display:swap;
}
@font-face{
	font-family: 'Helvetica-Regular';
	src: url('./../../build/fonts/Helvetica-Regular.ttf') format("truetype");
	font-weight:normal;
	font-display:swap;
}

@font-face {
  font-family: 'font-adp';
  src:  url('./../../build/fonts/font-adp/icomoon.eot?e8up72');
  src:  url('./../../build/fonts/font-adp/icomoon.eot?e8up72#iefix') format('embedded-opentype'),
    url('./../../build/fonts/font-adp/icomoon.ttf?e8up72') format('truetype'),
    url('./../../build/fonts/font-adp/icomoon.woff?e8up72') format('woff'),
    url('./../../build/fonts/font-adp/icomoon.svg?e8up72#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'font-adp' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-section:before {
  content: "\e94b";
}
.icon-arrow-up-section:before {
  content: "\e94c";
}
.icon-video:before {
  content: "\e94d";
}
.icon-intereses:before {
  content: "\e900";
}
.icon-alta-rentabilidad:before {
  content: "\e901";
}
.icon-cancha-de-tenis:before {
  content: "\e902";
}
.icon-cancha-multiple:before {
  content: "\e903";
}
.icon-casas-nuevas:before {
  content: "\e904";
}
.icon-cobertura-wifi:before {
  content: "\e905";
}
.icon-descuentos-financieros:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-financiacion-constructora:before {
  content: "\e909";
}
.icon-flecha-recorrido:before {
  content: "\e90a";
}
.icon-galeria:before {
  content: "\e90b";
}
.icon-instagram:before {
  content: "\e90c";
}
.icon-mensaje:before {
  content: "\e90d";
}
.icon-menu:before {
  content: "\e90e";
}
.icon-phone:before {
  content: "\e90f";
}
.icon-plazo:before {
  content: "\e910";
}
.icon-porcentaje-cuota-inicial:before {
  content: "\e911";
}
.icon-recorrido360:before {
  content: "\e912";
}
.icon-reloj:before {
  content: "\e913";
}
.icon-spec-area-piscina:before {
  content: "\e914";
}
.icon-ubicacion:before {
  content: "\e915";
}
.icon-valor-mas-rentable:before {
  content: "\e916";
}
.icon-waze:before {
  content: "\e917";
}
.icon-whatsApp:before {
  content: "\e918";
}
.icon-youtube:before {
  content: "\e919";
}
.icon-zona-de-masajes:before {
  content: "\e91a";
}
.icon-zona-relajacion-bali:before {
  content: "\e91b";
}
.icon-area-construida:before {
  content: "\e91c";
}
.icon-arrow-left-section:before {
  content: "\e91d";
}
.icon-arrow-right-button:before {
  content: "\e91e";
}
.icon-arrow-right-section:before {
  content: "\e91f";
}
.icon-close-interaction:before {
  content: "\e920";
}
.icon-close-interaction-mobile:before {
  content: "\e921";
}
.icon-cobertura-wifi1:before {
  content: "\e922";
}
.icon-financiacion:before {
  content: "\e923";
}
.icon-girar-pantalla:before {
  content: "\e924";
}
.icon-habitaciones:before {
  content: "\e925";
}
.icon-lote-privado:before {
  content: "\e926";
}
.icon-paneles-solares:before {
  content: "\e927";
}
.icon-parqueaderos:before {
  content: "\e928";
}
.icon-piscina-privada:before {
  content: "\e929";
}
.icon-plazo1:before {
  content: "\e92a";
}
.icon-precio:before {
  content: "\e92b";
}
.icon-spec-acabados-lujo:before {
  content: "\e92c";
}
.icon-spec-acabados-madera:before {
  content: "\e92d";
}
.icon-spec-altura:before {
  content: "\e92e";
}
.icon-spec-area-lote:before {
  content: "\e92f";
}
.icon-spec-area-total:before {
  content: "\e930";
}
.icon-spec-balcon:before {
  content: "\e931";
}
.icon-spec-bao:before {
  content: "\e932";
}
.icon-spec-capacitacion:before {
  content: "\e933";
}
.icon-spec-cocina:before {
  content: "\e934";
}
.icon-spec-cocina-isla-central:before {
  content: "\e935";
}
.icon-spec-diferentes-profundidades:before {
  content: "\e936";
}
.icon-spec-escaleras:before {
  content: "\e937";
}
.icon-spec-fachada:before {
  content: "\e938";
}
.icon-spec-fondo:before {
  content: "\e939";
}
.icon-spec-franja-limoncillo:before {
  content: "\e93a";
}
.icon-spec-frente:before {
  content: "\e93b";
}
.icon-spec-habitacion:before {
  content: "\e93c";
}
.icon-spec-habitacion-nios:before {
  content: "\e93d";
}
.icon-spec-habitacion-principal:before {
  content: "\e93e";
}
.icon-spec-iluminacion-natural:before {
  content: "\e93f";
}
.icon-spec-increibles-vistas:before {
  content: "\e940";
}
.icon-spec-instalacion-equipos:before {
  content: "\e941";
}
.icon-spec-lavadora:before {
  content: "\e942";
}
.icon-spec-lavaropa:before {
  content: "\e943";
}
.icon-spec-parqueadero:before {
  content: "\e944";
}
.icon-spec-pasillo:before {
  content: "\e945";
}
.icon-spec-personalizacion:before {
  content: "\e946";
}
.icon-spec-piscina:before {
  content: "\e947";
}
.icon-spec-ventanales:before {
  content: "\e948";
}
.icon-urbanismo-acueducto:before {
  content: "\e949";
}
.icon-valorizacion:before {
  content: "\e94a";
}
