@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-home-02{
  padding: 0rem 0rem 6.5rem 0rem;
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .border-section{
    width: 60%;
    height: .6rem;
    background: v.$gradient-dorado-01;
    margin: 0rem 0rem 9rem 0rem;
  }
  .content-block{
    display: grid;
    grid-template-columns: 1fr 1.25fr;
    column-gap: 5rem;
    @include m.mq-sm{
      grid-template-columns: 1fr;
      // row-gap: 5rem;
    }
    .item-box:nth-of-type(1){
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include m.mq-sm{
        grid-row: 2/3;
      }
      .ubicacion-xs{
        margin-bottom: 3.5rem;
      }
      h2:nth-of-type(1){
        color: v.$dorado-02;
        padding: 0rem 1.5rem 4rem 1.5rem;

        border-bottom: .3rem solid transparent;
        border-image-source: v.$gradient-dorado-01; 
        border-image-slice: 1;

        margin: 0rem 0rem 4rem 0rem;
        @include m.mq-sm{
          text-align: center;
          border: none;
          margin: 0rem 0rem 0rem 0rem;
        }
      }
      p:nth-of-type(1){
        color: v.$azul-01;
        padding: 0rem 1.5rem 4rem 1.5rem;
        span{
          font-family: v.$font-bold-02;
        }
        @include m.mq-sm{
          font-size: v.$body-02;
        }
      }
    }

    .item-box:nth-of-type(2){
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .box{
    background: v.$negro;
    padding: 5rem 4.5rem;

    border-bottom: .6rem solid transparent;
    border-image-source: v.$gradient-dorado-01; 
    border-image-slice: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    h2:nth-of-type(1){
      color: v.$blanco;
      margin: 0rem 0rem 2rem 0rem;
      @include m.mq-sm{
        text-align: center;
      }
    }
    h4:nth-of-type(1){
      color: v.$blanco;
      margin: 0rem 0rem 2rem 0rem;
      @include m.mq-sm{
        text-align: center;
      }
    }
    p:nth-of-type(1){
      color: v.$blanco;
      padding: 0rem 0rem 4rem 0rem;
      span{
        font-family: v.$font-bold-02;
      }
      @include m.mq-sm{
        font-size: v.$body-02;
      }
    }
    .item{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap:0rem 2rem;
      @include m.mq-sm{
        flex-direction: column;
        gap: 1.5rem 0rem;
      }
      a{
        color: v.$blanco;
        border: .1rem solid v.$blanco;
        font-family: v.$font-bold-01;
        font-size: v.$body-01;
        padding: .7rem 1rem;
        display: inline-block;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &:focus{
          color: v.$azul-01;
          background: v.$blanco;
        }
        &:hover{
          color: v.$azul-01;
          background: v.$blanco;
        }
        span{
          i:before{
            margin: 0rem 3rem 0rem 0rem;
          }
        }
        @include m.mq-sm{
          font-size: v.$h3-sm;
        }
      }
    }
  }
}