@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.main-gracias{
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
    @include m.mq-md{
      grid-template-columns: 1fr;
      row-gap: 8rem;
    }
    .item-box:nth-of-type(1){
      @include m.mq-md{
        grid-row: 2/3;
      }
    }
    .item-box:nth-of-type(2){
      padding: 1.5rem 8.33% 1.5rem 8.33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include m.mq-md{
        padding: 6rem 8.33% 1.5rem 8.33%;
      }
      h1:nth-of-type(1){
        margin: 0rem 0rem 3.5rem 0rem;
        @include m.mq-md{
          text-align: center;
        }
      }
      h4:nth-of-type(1){
        line-height: 1.2;
        margin: 0rem 0rem 3.5rem 0rem;
        font-family: v.$font-regular-01;
        font-size: v.$h5-xl;
        @include m.mq-md{
          font-family: v.$font-regular-02;
          font-size: v.$body-03;
        }
      }
      h4:nth-of-type(2){
        line-height: 1.2;
        margin: 0rem 0rem 2.5rem 0rem;
        font-family: v.$font-regular-01;
        font-size: v.$h5-xl;
        @include m.mq-md{
          font-family: v.$font-regular-02;
          font-size: v.$body-03;
        }
      }
      ul{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap:0rem 4rem;
        margin: 0rem 0rem 7rem 0rem;
        @include m.mq-sm{
          gap:0rem 3rem;
          justify-content: center;
        }
        span{
          i:before{
            color: v.$gris-02;
            font-size: 4.4rem;
          }
          i:hover:before{
            background: v.$gradient-dorado-01; 
            -webkit-background-clip: text;
            color: transparent;
            opacity: 1;
          }
        }
      }
    }
  }
}