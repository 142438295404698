@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.main-error-404{
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
    @include m.mq-md{
      grid-template-columns: 1fr;
      row-gap: 8rem;
    }
    .item-box:nth-of-type(1){
      @include m.mq-md{
        grid-row: 2/3;
      }
    }
    .item-box:nth-of-type(2){
      padding: 1.5rem 8.33% 1.5rem 8.33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include m.mq-md{
        padding: 6rem 8.33% 1.5rem 8.33%;
      }
      h1:nth-of-type(1){
        margin: 0rem 0rem 3.5rem 0rem;
        @include m.mq-md{
          text-align: center;
        }
      }
      p:nth-of-type(1){
        line-height: 1.2;
        margin: 0rem 0rem 3rem 0rem;
        font-family: v.$font-regular-01;
        @include m.mq-md{
          font-family: v.$font-regular-02;
          font-size: v.$body-02;
        }
      }
    }
  }
}