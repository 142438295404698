@use 'variables' as v;
@use 'mixins' as m;

/* GLOBALES */
html {
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%;
}

body{
  font-size: v.$body-01;
  font-family: v.$font-regular-02;
  color: v.$azul-01;
  @include m.mq-sm{
    font-size: v.$body-03;
  }
  &.stop-scroll{
    overflow-y: hidden;
  }
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.hide-item{
    opacity: 0;
}

// CONTAINER WIDTH ADP
// Depurar
.container, .container-lg, .container-md, .container-sm, .container-xl {
  @media (min-width: 1200px) {
    max-width: 1336px !important;
  }
  // @media (max-width: 1200px) {
  //   max-width: 1140px !important;
  // }
}

// SCROLLBAR
body::-webkit-scrollbar {
  -webkit-appearance: none;
}
body::-webkit-scrollbar:vertical {
  width:1.7rem;
}
body::-webkit-scrollbar-button:increment, body::-webkit-scrollbar-button {
  display: none;
} 
body::-webkit-scrollbar:horizontal {
  height: 10px;
}
body::-webkit-scrollbar-thumb {
  // background-color: v.$dorado-02;
  background-color: v.$dorado-02;
  // border-radius: 20px;
  // border-radius: .5rem;
  border: 1px solid rgba(0,0,0,.1);
}
// body::-webkit-scrollbar-track {
//   border-radius: 10px;  
// }

/* FONT SIZING */
h1, h2, h3, h4, h5, h6, p, li, a{
  margin: 0rem;
  padding: 0rem;
  line-height: 1.1;
}

h1{
  font-size: v.$h1-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h1-sm;
  }
}
h2{
  font-size: v.$h2-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h2-sm;
  }
}
h3{
  font-size: v.$h3-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h3-sm;
  }
}
h4{
  font-size: v.$h4-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h4-sm;
  }
}
h5{
  font-size: v.$h5-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h5-sm;
  }
}
h6{
  font-size: v.$h6-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h6-sm;
  }
}
p{
  font-size: v.$body-01;
  font-family: v.$font-regular-02;
  @include m.mq-sm{
    font-size: v.$body-03;
  }
  strong{
    font-family: v.$font-bold-02;
    // font-weight: bolder;
  }
}
a{
  font-size: v.$body-01;
  text-decoration: none;
  @include m.mq-sm{
    font-size: v.$body-03;
  }
  strong{
    font-family: v.$font-bold-02;
  }
  &:focus{
    text-decoration: none;
  }
  &:hover{
    text-decoration: none;
  }
}
ul, ol{
  padding: 0rem;
  margin: 0rem;
  list-style: none;
  li{
    font-size: v.$body-01;
    @include m.mq-sm{
      font-size: v.$body-03;
    }
    strong{
      font-family: v.$font-bold-02;
    }
  }
}

.center-recaptcha{
  // display: flex;
  // justify-content: center;
  // padding-top: .5rem;
  padding-bottom: 1rem;
  @media screen and (max-width: v.$md) {
    padding-bottom: 2rem;
  }
}

iframe{
  width: 100% !important;
}

// RECAPTCHA
.g-recaptcha{
  position: relative;
  width: 225px;
  height: 60px;
  @media screen and (max-width: v.$sm) {
    height: 55px;
  }
}

.g-recaptcha > :first-child {
  transform: scale(.8);
  // position: absolute; 
  // left: 0; 
  transform-origin: 0% 0;
  @media screen and (max-width: v.$xs-01) {
    transform: scale(.65);
    // transform-origin: 0 0;
    // margin-left: 0px; 
  }
}

// Utilidades
.reset-padding{
  @include m.mq-md{
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
}

.center-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.center-btn-responsive{
  @media screen and (max-width: v.$md) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.align-right{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @include m.mq-md{
    justify-content: center;
  }
}

.align-right-full{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 1040;
  z-index: 1700;
  width: 100vw;
  height: 100vh;
  // background-color: #000;
  background-color: v.$azul-alfa-02;
}

.grid-section-controller{
  display: grid;
  grid-template-columns: 1fr;
  .section-form.scrolled{
    grid-row: 7/8;
  }
}

main{
  margin: 8.8rem 0rem 0rem 0rem;
  @include m.mq-lg{
    margin: 8.8rem 0rem 0rem 0rem;
  }
  @include m.mq-md{
    margin: 7rem 0rem 0rem 0rem;
  }
  @include m.mq-sm{
    margin: 8.3rem 0rem 0rem 0rem;
  }
}