@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-etapa-1-05{
  padding:10rem 0rem 8rem 0rem;
  .padding-alterno{
    padding-left: 0rem;
    @include m.mq-sm{
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  .content-block{
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    column-gap: 7.5rem;
    @include m.mq-md{
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
    .item-box:nth-of-type(2){
      h2:nth-of-type(1){     
        font-family: v.$font-regular-01;  
        margin: 0rem 0rem 4rem 0rem;
        padding: 0rem 0rem 3.5rem 0rem;

        border-bottom: .3rem solid transparent;
        border-image-source: v.$gradient-dorado-01; 
        border-image-slice: 1;
        span{
          font-family: v.$font-bold-01;
        }
        @include m.mq-md{
          padding: 0rem 1.5rem 3.5rem 1.5rem;
        }
      }
      p:nth-of-type(1){
        margin: 0rem 0rem 5rem 0rem;
        span{
          font-family: v.$font-bold-02;
        }
        @include m.mq-md{
          padding: 0rem 1.5rem 0rem 1.5rem;
          font-size: v.$body-02;
        }
      }
    }
  }
}