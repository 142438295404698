@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-home-03{
  padding: 0rem 0rem 10rem 0rem;
  @include m.mq-sm{
    padding: 0rem 0rem 0rem 0rem;
  }
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .border-section{
    width: 60%;
    height: .6rem;
    background: v.$gradient-dorado-01;
    margin: 0rem 0rem 9rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .content-block{
    display: grid;
    grid-template-columns: 1fr 1.25fr;
    column-gap: 5rem;
    @include m.mq-sm{
      grid-template-columns: 1fr;
      row-gap: 5rem;
    }
    .item-box:nth-of-type(1){
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include m.mq-sm{
        // grid-row: 2/3;
        padding: 0rem 1.5rem;
      }
      h2:nth-of-type(1){
        color: v.$dorado-02;
        padding: 0rem 0rem 4rem 0rem;

        border-bottom: .3rem solid transparent;
        border-image-source: v.$gradient-dorado-01; 
        border-image-slice: 1;

        margin: 0rem 0rem 4rem 0rem;
        @include m.mq-sm{
          text-align: center;
          border: none;
          margin: 0rem 0rem 0rem 0rem;
        }
      }
      p:nth-of-type(1){
        color: v.$azul-01;
        padding: 0rem 0rem 4rem 0rem;
        // font-size: v.$body-01;
        span{
          font-family: v.$font-bold-02;
        }
        @include m.mq-sm{
          font-size: v.$body-02;
        }
      }
    }
    .item-box:nth-of-type(2){
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}