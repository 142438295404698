@use 'base/variables' as v;
@use 'base/mixins' as m;

.btn-cta-01{
  background: v.$gradient-dorado-01;
  color: v.$azul-01;
  font-family: v.$font-bold-01;
  font-size: v.$h4-xl;
  padding: 1.5rem 4rem;
  text-align: center;
  &:focus{
    background: v.$gradient-dorado-01;
    color: v.$azul-01;
  }
  &:hover{
    background: v.$gradient-azul-01;
    color: v.$blanco;
  }
  @include m.mq-sm{
    font-size: v.$h3-sm;
    padding: 1.2rem 2rem;
  }
}

.btn-cta-urbanismo{
  border: .1rem solid v.$azul-01;
  padding: .7rem 1.5rem .7rem 5rem;
  color: v.$azul-01;
  font-family: v.$font-bold-01;
  font-size: v.$h3-sm;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    i:before{
      margin-left: 5rem;
      font-weight: bolder;
      @include m.mq-md{
        margin-left: 2.5rem;
      }
    }
  }
  &:focus{
    color: v.$azul-01;
  }
  &:hover{
    color: v.$blanco;
  }
  &.--etapa-1{
    &:hover{
      color: v.$azul-01;
      background: v.$gradient-dorado-01;
      border: .1rem solid transparent;
    }
  }
  &.--etapa-2{
    &:hover{
      color: v.$blanco;
      background: v.$gradient-azul-01;
      border: .1rem solid transparent;
    }
  }
}

.btn-cta-ubicacion{
  border: .1rem solid v.$blanco;
  background: transparent;
  padding: .5rem 1rem .5rem 1rem;
  color: v.$blanco;
  display: block;
  width: 100%;
  font-family: v.$font-bold-01;
  font-size: v.$h3-sm;
  span{
    i:before{
      font-weight: bolder;
      margin-right: 1rem;
    }
  }
  &:focus{
    color: v.$azul-01;
    border: .1rem solid transparent;
    background: v.$blanco;
  }
  &:hover{
    color: v.$azul-01;
    border: .1rem solid transparent;
    background: v.$blanco;
  }
}

.btn-cta-etapa{
  border: .1rem solid v.$azul-01;
  padding: .7rem 3.5rem;
  font-family: v.$font-bold-01;
  font-size: v.$h3-sm;
  text-align: center;
  color: v.$azul-01;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  @include m.mq-sm{
    margin: 0rem 1.5rem 0rem 1.5rem;
    padding: .7rem 2.5rem;
  }
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    i:before{
      margin: 0rem 0rem 0rem 1.5rem;
      font-weight: bolder;
    }
  }
  &.--etapa-1{
    &:hover{
      color: v.$azul-01;
      background: v.$gradient-dorado-01;
      border: .1rem solid transparent;
    }
  }
  &.--etapa-2{
    &:hover{
      color: v.$blanco;
      background: v.$gradient-azul-01;
      border: .1rem solid transparent;
    }
  }
}

.btn-cta-link{
  border: .1rem solid v.$azul-01;
  padding: .7rem 3.5rem;
  font-family: v.$font-bold-01;
  font-size: v.$h3-sm;
  text-align: center;
  color: v.$azul-01;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  &:focus{
    color: v.$azul-01;
    background: v.$gradient-dorado-01;
    border: .1rem solid transparent;
  }
  &:hover{
    color: v.$azul-01;
    background: v.$gradient-dorado-01;
    border: .1rem solid transparent;
  }
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    i:before{
      margin: 0rem 0rem 0rem 5rem;
      font-weight: bolder;
    }
  }
  @include m.mq-sm{
    width: 100%;
    // display: block;
    text-align: center;
    justify-content: center;
    span{
      i:before{
        margin: 0rem 0rem 0rem 2rem;
      }
    }
  }
}

.btn-cta-regresar{
  border: .1rem solid v.$azul-01;
  padding: .7rem 3.5rem;
  font-family: v.$font-bold-01;
  font-size: v.$h3-sm;
  text-align: center;
  color: v.$azul-01;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  @include m.mq-sm{
    width: 100%;
    justify-content: center;
    position: relative;
  }
  &:focus{
    color: v.$azul-01;
    background: v.$gradient-dorado-01;
    border: .1rem solid transparent;
  }
  &:hover{
    color: v.$azul-01;
    background: v.$gradient-dorado-01;
    border: .1rem solid transparent;
  }
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    @include m.mq-sm{
      position: absolute;
      left: 2.5rem;
      top: .7rem;
    }
    i{
      transform: rotate(-180deg);
    }
    i:before{
      margin: 0rem 0rem 0rem 3.5rem;
      font-weight: bolder;
    }
  }
}

.btn-cta-regresar-02{
  color: v.$azul-01;
  font-family: v.$font-bold-01;
  font-size: v.$body-01;
  @include m.mq-sm{
    font-family: v.$font-regular-02;
    font-size: v.$body-05;
  }
  span{
    i:before{
      font-size: v.$body-04;
      margin: 0rem 1rem 0rem 0rem;
      @include m.mq-sm{
        font-size: v.$body-05;
      }
    }
  }
  &:focus{
    color: v.$dorado-02;
  }
  &:hover{
    color: v.$dorado-02;
  }
}

.btn-submit{
  background: v.$gradient-dorado-01;
  color: v.$azul-01;
  font-family: v.$font-bold-01;
  font-size: v.$h3-sm;
  padding: 1rem 6rem;
  text-align: center;
  border: none;
  outline: none;
  width: fit-content;
  &:focus{
    background: v.$gradient-dorado-01;
    color: v.$azul-01;
  }
  &:hover{
    background: v.$gradient-azul-01;
    color: v.$blanco;
  }
  @include m.mq-sm{
    font-size: v.$h3-sm;
    // padding: .7rem 2rem;
  }
}