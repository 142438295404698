@use 'base/variables' as v;
@use 'base/mixins' as m;

/* FOOTER */
.footer{
  padding: 3rem 0rem 3rem 0rem;
  .block-footer{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    // grid-template-rows: 2fr;
    // row-gap: 2rem;
    column-gap: 3rem;
    margin: 0 auto;
    @include m.mq-sm{
      grid-template-columns: 1fr;
      row-gap: 4rem;
    }
    .content-block:nth-child(1){
      p{
        text-align: right;
        color: v.$azul-02;
        font-size: v.$body-06;
        @include m.mq-md{
          text-align: center;
        }
        @include m.mq-sm{
          text-align: center;
          // font-size: v.$body-06;
          font-size: v.$body-04;
        }
      }
      @include m.mq-sm{
        grid-row: 1/2;
      }
    }
    .content-block:nth-child(2){
      display: flex;
      align-items: center;
      h4:nth-child(1){
        color: v.$dorado-02;
        font-family: v.$font-bold-01;
        // font-size: v.$h2-sm;
        @include m.mq-sm{
          text-align: center;
        }
      }
    }
    .content-block:nth-child(3){
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap:1.5rem 0rem;
      // align-items: center;
      p:nth-child(1){
        color: v.$azul-02;
        font-size: v.$body-03;
        @include m.mq-sm{
          text-align: center;
        }
      }
      .item-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:0rem 3rem;
        @include m.mq-sm{
          flex-direction: column;
          align-items: center;
          gap:2rem 0rem;
        }
        p:nth-child(1){
          color: v.$azul-02;
          font-size: v.$body-06;
          @include m.mq-sm{
            text-align: center;
            font-size: v.$body-04;
          }
        }
        ul{
          display: flex;
          flex-direction: row;
          gap:0rem 2rem;
          @include m.mq-sm{
            gap:0rem 3rem;
          }
          span{
            i:before{
              color: v.$gris-02;
              font-size: 2.9rem;
              @include m.mq-sm{
                font-size: 4.4rem;
              }
            }
            i:hover:before{
              background: v.$gradient-dorado-01; 
              -webkit-background-clip: text;
              color: transparent;
              opacity: 1;
            }
          }
        }
      }
    }
    // .content-block:nth-child(4){
    //   grid-column: 3/4;
    //   @include m.mq-sm{
    //     grid-column: auto;
    //   }
    //   p:nth-child(1){
    //     color: v.$azul-02;
    //     font-size: v.$body-04;
    //     @include m.mq-sm{
    //       text-align: center;
    //     }
    //   }
    // }
  }
}