@use 'variables' as v;

/** Media Queries **/
@mixin mq-xs {
  @media (max-width: v.$xs) {
    @content;
  }
}

@mixin mq-xs-01 {
  @media (max-width: v.$xs-01) {
    @content;
  }
}

@mixin mq-xs-02 {
  @media (max-width: v.$xs-02) {
    @content;
  }
}

@mixin mq-sm {
  @media (max-width: v.$sm) {
    @content;
  }
}
  
@mixin mq-md {
  @media (max-width: v.$md) {
    @content;
  }
}

@mixin mq-lg {
  @media (max-width: v.$lg) {
    @content;
  }
}

@mixin mq-xl {
  @media (max-width: v.$xl) {
    @content;
  }
}

@mixin mq-1080 {
  @media (max-width: 1920px) {
    @content;
  }
}