/* VARIABLES */
// Fuentes
$font-regular-01: 'Arsenal-Regular';
$font-regular-02: 'Helvetica-Regular';

$font-bold-01: 'Arsenal-Bold';
$font-bold-02: 'Helvetica-Bold';

// Tamaño de Media Queries
$xl: 1560px;
$lg: 1270px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$xs-01: 480px;
$xs-02: 380px;

// Font size
// XL 
$h1-xl: 5rem;
$h2-xl: 4.0rem;
$h3-xl: 3.6rem;
$h4-xl: 3rem;
$h5-xl: 2.4rem;
$h6-xl: 2rem;
$li-xl: 2rem;

// SM
$h1-sm: 3.6rem;
$h2-sm: 3.2rem;
$h3-sm: 2.6rem;
$h4-sm: 2.4rem;
$h5-sm: 2.0rem;
$h6-sm: 1.8rem;
$li-sm: 1.6rem;

// Body sizes
$body-01: 2.0rem;
$body-02: 1.8rem;
$body-03: 1.6rem;
$body-04: 1.4rem;
$body-05: 1.2rem;
$body-06: 1rem;
$body-07: .7rem;

// Colores
$dorado-01: rgba(236, 235, 162, 1);
$dorado-02: rgba(188, 145, 62, 1);
$dorado-03: #EAE79D;

$azul-01: rgba(17, 35, 52, 1);
$azul-02: #112334;
$azul-03: #0D101F;
$azul-04: #00FFC2;
$azul-05: rgba(55, 92, 128, 1);

$verde-01: #4CAD20;

$blanco: #FFF;
$negro: #000;
$gris-01: rgba(229, 229, 229, 1);
$gris-02: #898989;
$gris-03: #E5E5E5;

$rojo-alerta: #7B2627;

// Colores en canal alfa
$dorado-alfa-01: rgba(242,187,75,.8);

$azul-alfa-01: rgba(17,35,52,.7);
$azul-alfa-02: rgba(17,35,52,.7);

$negro-alfa-01: rgba(0,0,0,.8);
$blanco-alfa-01: rgba(255,255,255,.8);

// Gradientes
$gradient-dorado-01: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
$gradient-dorado-02: linear-gradient(90deg, $dorado-alfa-01 0%, $dorado-alfa-01 100%);
$gradient-dorado-03: linear-gradient(180deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);

$gradient-azul-01: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
$gradient-azul-02: linear-gradient(90deg, $azul-alfa-01 0%, $azul-alfa-01 100%);
// $gradient-azul-01: linear-gradient(90deg, $azul-alfa-02 0%, $azul-alfa-02 100%);
// $gradient-azul-02: linear-gradient(90deg, $azul-alfa-01 0%, $azul-alfa-01 100%);




