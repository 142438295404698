@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-condominio-02{
  padding: 7rem 0rem 10rem 0rem;
  @include m.mq-sm{
    padding: 7rem 0rem 0rem 0rem;
  }
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  h2:nth-of-type(1){
    color: v.$dorado-02;
    text-align: center;
    padding: 7rem 1.5rem 4.5rem 1.5rem;

    border-top: .3rem solid transparent;
    border-image-source: v.$gradient-dorado-01; 
    border-image-slice: 1;
    @include m.mq-sm{
      padding: 0rem 1.5rem 4.5rem 1.5rem;
      border: none;
    }
  }

  .content-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 8.33% 2.5rem 8.33%;
    box-shadow: 0rem 0rem 1rem v.$gris-01;
    position: relative;
    @include m.mq-md{
      padding: 2.5rem 1.5rem 2.5rem 1.5rem;
    }
    .item-box:nth-of-type(2){
      display: flex;
      flex-direction: row;
      justify-content: center;
      p:nth-of-type(1){
        font-family: v.$font-bold-01;
        font-size: v.$h3-sm;
        text-align: center;
        @include m.mq-md{
          font-family: v.$font-regular-02;
          font-size: v.$body-02;
          max-width: 290px;
        }
        @include m.mq-sm{
          max-width: 210px;
        }
      }
    }
    .item-box:nth-of-type(3){
      position: absolute;
      right: 17.5%;
      @include m.mq-md{
        right: 12.5%;
      }
      p:nth-of-type(1){
        font-family: v.$font-bold-01;
        font-size: v.$body-01;
        text-align: center;
        @include m.mq-md{
          font-family: v.$font-regular-02;
          font-size: v.$body-02;
        }
      }
    }
  }
}