@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-como-llegar{
  padding: 0rem 0rem 0rem 0rem;
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 7.5rem;
    background: v.$negro;
    padding: 5rem 8.33% 5rem 8.33%;
    @include m.mq-md{
      grid-template-columns: 1fr;
      row-gap: 3rem;

      border-bottom: .6rem solid transparent;
      border-image-source: v.$gradient-dorado-01; 
      border-image-slice: 1;
    }
    .item-box:nth-of-type(1){
      h2:nth-of-type(1){
        color: v.$blanco;
        margin: 0rem 0rem 1rem 0rem; 
        @include m.mq-md{
          text-align: center;
          margin: 0rem 0rem 3rem 0rem; 
        }
      }
      p:nth-of-type(1){
        color: v.$blanco;
        @include m.mq-md{
          text-align: center;
          font-size: v.$body-02;
        }
        span{
          font-family: v.$font-bold-02;
          i:before{
            font-size: v.$h3-sm;
          }
        }
      }
    }
    .item-box:nth-of-type(2){
      display: flex;
      flex-direction: row;
      align-items: center;
      gap:0rem 2rem;
      @include m.mq-md{
        flex-direction: column;
        gap:2rem 0rem;
      }
      p:nth-of-type(1),
      p:nth-of-type(2){
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }
}