@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-form{
  background: v.$negro;
  // padding: 6rem 0rem 6rem 0rem;
  padding: 0rem 0rem 0rem 0rem;

  border-top: .6rem solid transparent;
  border-bottom: .6rem solid transparent;
  border-image-source: v.$gradient-dorado-01; 
  border-image-slice: 1;
  .padding-alterno{
    padding-right: 0rem;
    @include m.mq-sm{
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  .content-block{
    display: grid;
    grid-template-columns: 1fr 1.25fr;
    column-gap: 7.5rem;
    @include m.mq-md{
      grid-template-columns: 1fr;
    }
    .item-box:nth-of-type(2){
      background: url('./../../build/img/casa-fachada-posterior-piscina-formulario-altos-del-palmar.webp');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .formulario{
    padding: 5rem 0rem 4rem 0rem;
    @include m.mq-sm{
      padding: 5rem 1.5rem 4rem 1.5rem;
    }
    h3:nth-of-type(1){
      background: v.$gradient-dorado-01; 
      font-size: v.$h5-xl;
      -webkit-background-clip: text;
      color: transparent;
      opacity: 1;
      margin: 0rem 0rem 2rem 0rem;
      @include m.mq-sm{
        font-size: v.$h3-sm;
        text-align: center;
      }
    }
    .descripcion{
      color: v.$blanco;
      margin: 0rem 0rem 3rem 0rem;
      font-size: v.$body-03;
      @include m.mq-sm{
        font-size: v.$body-03;
      }
    }
    .form-group{
      margin-bottom: 1rem;
    }
    .form-group.submit{
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      // gap:0rem 2rem;
      // @include m.mq-sm{
      //   flex-direction: column;
      //   margin: 0rem 0rem 0rem 0rem;
      // }
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      column-gap: 1.5rem;
      @include m.mq-sm{
        grid-template-columns: 1fr;
        // row-gap: 2.5rem;
        .pristine-error{
          grid-row: 2/3;
          margin: .5rem 0rem 1.25rem 1rem; 
        }
        .btn-submit{
          margin: 3rem 0rem 0rem 0rem;
          // font-size: v.$h3-sm;
        }
      }
    }
    .form-control {
      display: block;
      width: 100%;
      height: 3.3rem;
      padding: .3rem 2rem;
      font-size: v.$body-04;
      font-weight: 400;
      line-height: 1.5;
      // color: v.$dorado-02;
      color: v.$azul-01;
      background-color: #fff;
      background-clip: padding-box;
      border: .1rem solid v.$dorado-02;
      border-radius: 0rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    .item-checbox{
      padding: 0rem 0rem 0rem 0rem;
      display: flex;
      flex-direction: row;
      // align-items: flex-start;
      align-items: center;
      gap:0rem 1.5rem;
      p:nth-of-type(1){
        color: v.$blanco;
        // font-size: v.$body-05;
        font-size: v.$body-06;
        span{
          a{
            color: v.$blanco;
            // font-size: v.$body-05;
            font-size: v.$body-06;
            text-decoration: underline;
          }
        }
      }
    }
  }
  /* PRISTINE IMPLEMENTATION */
  .pristine-error.text-help{
    // font-size: v.$body-05;
    font-size: v.$body-06;
    color: v.$blanco;
    margin: .5rem 0rem 0rem 1rem;
  }

  .input-error{
    // font-size: v.$body-05;
    font-size: v.$body-06;
    color: v.$blanco;
    margin: .5rem 0rem 0rem 1rem;
  }
  // .formulario{
  //   .block-section-form{
  //     display: grid;
  //     grid-template-columns: 1fr 1fr 1fr auto;
  //     column-gap: 2.5rem;
  //     @include m.mq-md{
  //       grid-template-columns: 1fr;
  //       // grid-template-rows: repeat(5, 1fr);
  //     }
  //     .content-block:nth-of-type(1){
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       h3{
  //         color: v.$dorado-02;
  //         text-align: center;
  //       }
  //       @include m.mq-md{
  //         display: block;
  //         padding: 0rem 0rem 3rem 0rem;
  //       }
  //     }
  //     .content-block:nth-of-type(2),
  //     .content-block:nth-of-type(3),
  //     .content-block:nth-of-type(4){
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: flex-end;
  //       // justify-content: flex-start;
  //       @include m.mq-md{
  //         display: block;
  //         padding: 0rem 0rem 1rem 0rem;
  //       }
  //     }
  //     .content-block:nth-of-type(5){
  //       grid-column: 3/5;
  //       grid-row: 2/3;
  //       @include m.mq-md{
  //         display: block;
  //         grid-column: 1/2;
  //         grid-row: 4/5;
  //         padding: 1rem 0rem 2rem 0rem;
  //       }
  //     }
  //     .form-group.has-error{
  //       margin-bottom: .5rem;
  //     }
  //     .form-group {
  //       // position: relative;
  //       margin-bottom: 1rem;
  //       @media screen and (max-width: v.$sm) {
  //         margin-bottom: 1rem;
  //       }
  //       .container-option-check{
  //         display: flex;
  //         flex-direction: row;
  //         justify-content: flex-start;
  //         align-items: center;
  //         min-height: 4rem;
  //         // border: 1px solid v.$gris-01;
  //         background: v.$blanco;

  //         border: .1rem solid transparent;
  //         border-image-source: v.$gradient-dorado-01; 
  //         border-image-slice: 1;

  //         padding: 0px 10px;
  //         // margin-bottom: 10px;
  //         margin-bottom: 1rem;
  //       }
  //       .container-option-check.error{
  //         border: 1px solid v.$rojo-alerta;
  //       }
  //       .container-option-check.ultimo{
  //         margin-bottom: 0px;
  //       }
  //       .option-checked{
  //         display: flex;
  //         flex-direction: row;
  //         justify-content: flex-start;
  //         align-items: center;
    
  //         // border: 1px solid v.$dorado-02;
  //         border: .1rem solid transparent;
  //         border-image-source: v.$gradient-dorado-01; 
  //         border-image-slice: 1;
    
  //         padding: 0px 10px;
  //         margin-bottom: 10px;
  //         color: v.$dorado-02;
  //       }
  //       .option-checked > .tx-opcion-check{
  //         border-left:1px solid v.$dorado-02;
  //       }
  //       .checkbox-opcion{
  //         color: v.$blanco;
  //         font-family: v.$font-regular-02;
  //         margin-right:10px;
  //         cursor: pointer;
  //       }
  //       .tx-opcion-check{
  //         color: v.$gris-01;
  //         font-family: v.$font-regular-02;
  //         font-size: v.$body-03;
  //         border-left:1px solid v.$gris-01;
  //         padding:.7rem 0px .7rem 1.5rem;
  //         display: flex;
  //         flex-direction: column;
  //         justify-content: center;
  //         height: 100%;
  //         @media screen and (max-width: v.$lg) {
  //           font-size: v.$body-05;
  //         }
  //         @media screen and (max-width: v.$md) {
  //           font-size: v.$body-03;
  //         }
  //       }
  //       .tx-opcion-check.error{
  //         border-left:1px solid v.$rojo-alerta;
  //         color: v.$rojo-alerta;
  //       }
  //       .help-block.with-errors .list-unstyled{
  //         font-family: v.$font-regular-02;
  //         // color:v.$rojo-alerta !important;
  //         color:v.$blanco;
  //         margin:3px 0px 0px 1rem;
  //         display: flex;
  //         flex-direction: row;
  //         align-items: flex-start;
  //         line-height: 1;
  //         li{
  //           font-size: v.$body-05;
  //           @media screen and (max-width: v.$sm) {
  //             font-size: v.$body-05;
  //           }
  //         }
  //         @media screen and (max-width: v.$sm) {
  //           // bottom: 5px;
  //           margin:1.5px 0px 0px 1rem;
  //         }
  //       }
  //     }
  //     .form-group:last-of-type{
  //       margin-bottom: 0rem;
  //       @media screen and (max-width: v.$sm) {
  //         margin-bottom: 0rem;
  //       }
  //     }
  //     .checkbox{
  //       font-family: v.$font-regular-02;
  //       // font-size: v.$body-04 - .2;
  //       display: flex;
  //       flex-direction: row;
  //       // align-items: flex-start;
  //       align-items: center;
  //       // justify-content: center;
  //       gap: 0rem 1rem;
  //       margin: 1.5rem 0rem 0rem 0rem;
  //       @media screen and (max-width: v.$md) {
  //         margin: 0rem 0rem 0rem 0rem;
  //       }
  //       input{
  //         margin: 0rem 0rem 0rem 0rem;
  //       }
  //       p:nth-of-type(1){
  //         color: v.$blanco;
  //         font-family: v.$font-regular-02;
  //         font-size: v.$body-06;
  //         margin:0rem 0rem 0rem 0rem;
  //         line-height: 1.25;
  //         text-align: left;
  //         // width: 80%;
  //         // width: 268px;
  //         .tx_terminos{
  //           color: v.$blanco;
  //           font-family: v.$font-regular-02;
  //           font-size: v.$body-06;
  //           text-decoration: underline;
  //           cursor: pointer;
  //           @media screen and (max-width: v.$sm) {
  //             font-size: v.$body-06;
  //           }
  //         }
  //         @media screen and (max-width: v.$sm) {
  //           font-size: v.$body-06;
  //           width: auto;
  //         }
  //       }
  //     }
  //     #msgSubmitCondiciones,
  //     #msgSubmit{
  //       text-align: center;
  //       font-size: v.$body-05;
  //       font-family: v.$font-regular-02;
  //       // color:v.$rojo-alerta !important;
  //       color:v.$blanco;
  //       margin:3px 0px 0px 0px;
  //       @media screen and (max-width: v.$sm) {
  //         font-family: v.$font-regular-02;
  //         margin:1.5px 0px 0px 0px;
  //         font-size: v.$body-05;
  //       }
  //     }
  //     #msgSubmitRecaptcha{
  //       text-align: center;
  //       font-size: v.$body-05;
  //       font-family: v.$font-regular-02;
  //       // color:v.$rojo-alerta !important;
  //       color:v.$blanco;
  //       margin:3px 0px 0px 0px;
  //       @media screen and (max-width: v.$sm) {
  //         font-family: v.$font-regular-02;
  //         margin:1.5px 0px 0px 1rem;
  //         font-size: v.$body-05;
  //         text-align: left;
  //       }
  //     }
  //     .form-control {
  //       display: block;
  //       width: 100%;
  //       // height: calc(1.5em + .75rem + 2px);
  //       padding: .375rem 1rem;
  //       font-weight: 400;
  //       line-height: 1.5;
  //       height: 4rem;
  //       // color: #495057;
  //       color: v.$gris-01;
  //       background-color: #fff;
  //       background-clip: padding-box;
    
  //       border: .1rem solid transparent;
  //       border-image-source: v.$gradient-dorado-01; 
  //       border-image-slice: 1;
  //       border-radius: 0rem;
    
  //       color: v.$gris-01;
  //       font-family: v.$font-regular-02;
  //       font-size: v.$body-03;
    
  //       transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  //       @media screen and (max-width: v.$lg) {
  //         font-size: v.$body-05;
  //       }
  //       @media screen and (max-width: v.$md) {
  //         font-size: v.$body-03;
  //       }
  //     }
  //     .form-control:focus {
  //       // color: #495057;
  //       color: v.$gris-01;
  //       background-color: #fff;
  //       // border-color: #80bdff;
  //       border-color: v.$dorado-02;
  //       outline: 0;
  //       // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  //       box-shadow: none;
  //     }
  //     .form-control.error{
  //       // border-color: v.$rojo-alerta;
  //       border: .1rem solid v.$rojo-alerta;
  //       box-shadow: none;
  //       color: v.$rojo-alerta;
  //     }
  //     .form-control.error::placeholder{
  //       color: v.$rojo-alerta;
  //     }
  //     .form-control::placeholder{
  //       color: v.$gris-01;
  //     }
  //     label{
  //       font-family: v.$font-bold-02;
  //       font-size: v.$body-03;
  //       color: v.$blanco;
  //       line-height: 1;
  //       // margin: 1.5rem 0rem 2.5rem 0rem;
  //       margin: 0rem 0rem 1rem 0rem;
  //       @media screen and (max-width: v.$md) {
  //         display: block;
  //         margin: 0rem 0rem 2rem 0rem;
  //         font-size: v.$body-03;
  //         text-align: center;
  //       }
  //     }
  //     .enviar-form{
  //       display:block;
  //       background: v.$gradient-dorado-01;
  //       font-size: v.$h3-xl;
  //       font-family: v.$font-bold-01;
  //       padding-top: 1rem;
  //       padding-bottom: .6rem;
  //       line-height: 1;
  //       // width: 100%;
  //       width: 225px;
  //       border: none;
  //       margin:0rem 0rem 0rem 0rem;
  //       @media screen and (max-width: v.$sm) {
  //         width: 190px;
  //         padding-top: .8rem;
  //         padding-bottom: .4rem;
  //       }
  //       &:focus{
  //         text-decoration: none;
  //         color: v.$azul-02;
  //         background: v.$gradient-dorado-01;
  //       }
  //       &:hover{
  //         text-decoration: none;
  //         color:v.$blanco;
  //         background: v.$azul-02;
  //       }
  //     }
  //   }
  // }
}

@keyframes transicion-envio{
  0%{
    transform: scale(initial);
  }
  20%{
    transform: scale(.9);
  }
  35%{
    transform: scale(1.1);
  }
  60%{
    transform: scale(0.25);
    opacity:1;
  }
  90%{
    transform: scale(0.25);
  }
  100%{
    opacity: 0;
  }
}

.animacion-form{
  animation-name: transicion-envio;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  opacity: 1;
}

@keyframes opacidad-contenido{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.suavizar-opacidad{
  animation-name: opacidad-contenido;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.spinner-border.loader-form{
  // color: v.$negro;
  color: v.$dorado-02;
  width: 60px;
  height: 60px;
  margin-top: 15px;
}
@media screen and (max-width: 575px) {
  .spinner-border.loader-form{
    margin-top: 35px;
  }
}

.procesando-datos{
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.tx-seccion-form-send {
  color: v.$blanco;
  font-size: v.$body-03;
  text-align: center;
  margin: 20px 0px 20px 0px;
  @media screen and (max-width: v.$md) {
    font-size: v.$body-04;
    margin: 20px 0px 100px 0px;
  }
}

.tx-seccion-form-send-chatbot {
  color: v.$negro;
  font-size: v.$body-04;
  text-align: center;
  margin: 20px 0px 20px 0px;
  // @media screen and (max-width: v.$md) {
    // font-size: v.$body-04;
    // margin: 20px 0px 100px 0px;
  // }
}
