@use '../../base/variables' as v;
@use '../../base/mixins' as m;


.main-etapa-1{
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block{
    padding: 3.5rem 1.5rem 4rem 1.5rem;
    background: v.$negro;

    border-top: .6rem solid transparent;
    border-image-source: v.$gradient-dorado-01; 
    border-image-slice: 1;
    @include m.mq-sm{
      border-top: none;
    }
  }
  h1:nth-of-type(1){
    color: v.$blanco;
    font-family: v.$font-bold-01;
    text-align: center;
    line-height: 1.5;
    @include m.mq-sm{
      line-height: 1.3;
    }
    span{
      background: v.$gradient-dorado-01;
      // color: v.$azul-01;
      color: v.$negro;
      padding: 0rem 7rem;
      @include m.mq-sm{
        padding: 0rem .25rem;
      }
    }
  }
}