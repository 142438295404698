@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-home-01{
  padding: 0rem 0rem 8rem 0rem;
  @include m.mq-sm{
    padding: 0rem 0rem 5rem 0rem;
  }
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block.head{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .item-box:nth-of-type(1){
      height: 440px;
      background: url('./../../build/img/casa-campestre-etapa-uno-home-altos-del-palmar.webp');
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // h2:nth-of-type(1){
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: center;
      //   align-items: center;
      //   background: v.$blanco;
      //   a:nth-of-type(1){
      //     // display: block;
      //     text-align: center;
      //     // width: 100%;
      //     background: v.$gradient-dorado-01;
      //     color: v.$azul-01;
      //     font-size: v.$h2-xl;
      //     padding: 1rem 1.5rem;
      //     @include m.mq-sm{
      //       font-size: v.$h2-sm;
      //     }
      //     &:focus{
      //       background: v.$blanco;
      //       color: v.$dorado-02;
      //     }
      //     &:hover{
      //       background: v.$blanco;
      //       color: v.$dorado-02;

      //       background: v.$gradient-dorado-01; 
      //       -webkit-background-clip: text;
      //       color: transparent;
      //     }
      //   }
      // }
      h2:nth-of-type(1){
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: v.$blanco;

        // display: block;
        text-align: center;
        // width: 100%;
        background: v.$gradient-dorado-01;
        color: v.$azul-01;
        font-size: v.$h2-xl;
        padding: 1rem 1.5rem;
        @include m.mq-sm{
          font-size: v.$h2-sm;
        }
        // &:focus{
        //   background: v.$blanco;
        //   color: v.$dorado-02;
        //   a{
        //     background: v.$gradient-dorado-01; 
        //     -webkit-background-clip: text;
        //     color: transparent;
        //   }
        // }
        // &:hover{
        //   background: v.$blanco;
        //   color: v.$dorado-02;
        //   span{
        //     background: v.$gradient-dorado-01; 
        //     -webkit-background-clip: text;
        //     color: transparent;
        //   }
        //   a{
        //     background: v.$gradient-dorado-01; 
        //     -webkit-background-clip: text;
        //     color: transparent;
        //   }
        // }
      }
      h4:nth-of-type(1){
        background: v.$negro-alfa-01;
        color: v.$dorado-02;

        border-bottom: .6rem solid transparent;
        border-image-source: v.$gradient-dorado-01; 
        border-image-slice: 1;

        padding: 1rem 1.5rem;
        margin: 0rem 4.5rem 3rem 4.5rem;

        text-align: center;
        display: inline-block;
        span{
          background: v.$gradient-dorado-01; 
          -webkit-background-clip: text;
          color: transparent;
        }
      }
    }
    .item-box:nth-of-type(2){
      height: 440px;
      background: url('./../../build/img/casa-campestre-etapa-dos-home-altos-del-palmar.webp');
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // h2:nth-of-type(1){
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: center;
      //   align-items: center;
      //   a:nth-of-type(1){
      //     display: block;
      //     text-align: center;
      //     width: 100%;
      //     font-size: v.$h2-xl;
      //     background: v.$gradient-azul-01;
      //     color: v.$blanco;
      //     padding: 1rem 1.5rem;
      //     @include m.mq-sm{
      //       font-size: v.$h2-sm;
      //     }
      //     &:focus{
      //       background: v.$blanco;
      //       color: v.$azul-01;
      //     }
      //     &:hover{
      //       background: v.$blanco;
      //       color: v.$azul-01;
      //     }
      //   }
      // }
      h2:nth-of-type(1){
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        // display: block;
        text-align: center;
        // width: 100%;
        font-size: v.$h2-xl;
        background: v.$gradient-azul-01;
        color: v.$blanco;
        padding: 1rem 1.5rem;
        @include m.mq-sm{
          font-size: v.$h2-sm;
        }
        // &:focus{
        //   background: v.$blanco;
        //   color: v.$azul-01;
        //   a{
        //     background: v.$gradient-azul-01; 
        //     -webkit-background-clip: text;
        //     color: transparent;
        //   }
        // }
        // &:hover{
        //   background: v.$blanco;
        //   color: v.$azul-01;
        //   span{
        //     background: v.$gradient-azul-01; 
        //     -webkit-background-clip: text;
        //     color: transparent;
        //   }
        //   a{
        //     background: v.$gradient-azul-01; 
        //     -webkit-background-clip: text;
        //     color: transparent;
        //   }
        // }
      }
      h4:nth-of-type(1){
        background: v.$blanco-alfa-01;
        color: v.$azul-01;

        border-bottom: .6rem solid transparent;
        border-image-source: v.$gradient-azul-01; 
        border-image-slice: 1;

        padding: 1rem 1.5rem;
        margin: 0rem 4.5rem 3rem 4.5rem;

        text-align: center;
        display: inline-block;
        span{
          background: v.$gradient-azul-01; 
            -webkit-background-clip: text;
            color: transparent;
        }
      }
    }
  }
  .block-section-06:nth-of-type(1){
    padding: 0rem 0rem 0rem 0rem;
    display: grid;
    .content-block{
      h2{
        text-align: center;
        padding: 0rem 3rem 4rem 3rem;
        color: v.$dorado-02; 
        @include m.mq-sm{
          background: v.$blanco;
          color: v.$dorado-02; 
          padding: 6rem 8.3% 4rem 8.3%;
        }
      }
    }
  }
  // MOBILE
  .mobile-content{
    .block-nav-mobile{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      // padding: 1rem 0rem 0rem 0rem;
      .nav.nav-tabs{
        width: 100%;
        .nav-item{
          width: 50%;
          text-align: center;
          .nav-link{
            font-family: v.$font-bold-01;
            // font-size: v.$h3-xl;
            font-size: v.$h3-sm;
            background: v.$gris-03;
            color: v.$azul-02;
            padding: 1rem 0rem 1rem 0rem;
            border: none;
            border-radius: 0rem;
            cursor: pointer;
            // @include m.mq-sm{
            //   font-size: v.$h3-sm;
            //   padding: .5rem 0rem .2rem 0rem;
            // }
          }
          .nav-link.active{
            border: none;
            border-radius: 0rem;
          }
        }
        .nav-item:nth-of-type(1){
          .nav-link.active{
            background: v.$gradient-dorado-01;
            color: v.$azul-01;
          }
        }
        .nav-item:nth-of-type(2){
          .nav-link.active{
            background: v.$gradient-azul-01;
            color: v.$blanco;
          }
        }
      }
      .nav-tabs {
        border-bottom: none;
      }
    }
    .tab-content{
      position: relative;
      .content-block{
        @include m.mq-sm{
          padding: 0rem 0rem 6rem 0rem;

          border-bottom: .6rem solid transparent;
          border-image-source: v.$gradient-dorado-01; 
          border-image-slice: 1;
        }
        .bg-etapa-1{
          background: url('./../../build/img/casa-campestre-etapa-uno-xs-home-altos-del-palmar.webp');
          background-size: cover;
          background-position: center top;
          background-repeat: no-repeat;
          height: 250px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .descripcion{
            display: block;
            width: 100%;
            text-align: center;
            color: v.$dorado-02;
            font-family: v.$font-bold-01;
            // font-size: v.$body-01;
            font-size: v.$h3-sm;
            &.etapa-1{
              background: v.$negro-alfa-01;
              padding: 1rem 1.5rem;
              span{
                background: v.$gradient-dorado-01; 
                -webkit-background-clip: text;
                color: transparent;
              }
            }
          }
        }
        .bg-etapa-2{
          background: url('./../../build/img/casa-campestre-etapa-dos-xs-home-altos-del-palmar.webp');
          background-size: cover;
          background-position: center top;
          background-repeat: no-repeat;
          height: 250px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          .descripcion{
            display: block;
            width: 100%;
            text-align: center;
            color: v.$azul-01;
            font-family: v.$font-bold-01;
            // font-size: v.$body-01;
            font-size: v.$h3-sm;
            &.etapa-2{
              background: v.$blanco-alfa-01;
              padding: 1rem 1.5rem;
              span{
                background: v.$gradient-azul-01; 
                -webkit-background-clip: text;
                color: transparent;
              }
            }
          }
        }
        .precio{
          // font-size: v.$body-02;
          // font-size: v.$body-01 + .2;
          font-size: v.$h3-sm;
          font-family: v.$font-bold-02;
          text-align: center;
          padding: 1.5rem 0rem;
          &.etapa-1{
            background: v.$gradient-dorado-01;
            color: v.$azul-01;
          }
          &.etapa-2{
            background: v.$gradient-azul-01;
            color: v.$blanco;
          }
        }
        .block{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 0rem 1.5rem;
          padding: 1.5rem 0rem 1.5rem 0rem;
          max-width: 280px;
          margin: 0 auto;
          span{
            // display: block;
            width: 7rem;
            text-align: center;
            i:before{
              color: v.$dorado-02;
              font-size: v.$h1-sm;
            }
          }
          .box{
            p:nth-of-type(1){
              color: v.$azul-01;
              // font-size: v.$body-03;
              font-size: v.$body-04;
              margin: 0rem 0rem .5rem 0rem;
              font-family: v.$font-regular-01;
              span{
                font-family: v.$font-bold-01;
              }
            }
          }
        }
        .block:nth-of-type(5){
          padding: 2rem 0rem 4.5rem 0rem;
        }
      }
    }
  }


  // DESKTOP
  .desktop-content{
    .block-section-06:nth-of-type(1){
      display: grid;
      grid-template-columns: 1fr;
      padding: 0rem 0rem 0rem 0rem;
      .content-block:nth-of-type(2),
      .content-block:nth-of-type(3),
      .content-block:nth-of-type(4),
      .content-block:nth-of-type(5){
        padding: 0rem 8.33% 0rem 8.33%;
        @include m.mq-sm{
          padding: 0rem 1.5rem 0rem 1.5rem;
        }
        ul{
          display: grid;
          grid-template-columns: 1fr 15% 1fr;
          border-bottom: .1rem solid v.$gris-03;
          padding: 1.5rem 0rem 1.5rem 0rem;
        }
        li{
          text-align: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap:1rem .5rem;
          font-family: v.$font-regular-01;
          span{
            font-family: v.$font-bold-01;
            i:before{
              // font-size: v.$h1-xl;
              font-size: v.$h3-xl;
            }
          }  
        }
        li:nth-of-type(2){
          color: v.$dorado-02; 
        }
      }
      // .content-block:nth-of-type(6){
      //   ul{
      //     border-bottom: none;
      //   }
      // }
  
      .content-block:nth-of-type(1),
      .content-block:nth-of-type(6){
        ul{
          display: grid;
          grid-template-columns: 1fr 1fr;
          li{
            text-align: center;
          }
        }
      }
      .content-block:nth-of-type(1){
        ul{
          // padding: 2.5rem 0rem 2.5rem 0rem;
          margin: 0rem 0rem 2.5rem 0rem;
          li.etapa-1{
            font-family: v.$font-regular-01;
            // font-size: v.$h3-xl;
            padding: 1rem 1.5rem;

            border-bottom: .3rem solid transparent;
            border-image-source: v.$gradient-dorado-01; 
            border-image-slice: 1;
            h3:nth-of-type(1){
              font-family: v.$font-regular-01;
            }
            span{
              font-family: v.$font-bold-01;
            }
            // @include m.mq-sm{
            //   font-size: v.$h3-sm;
            // }
          }
          li.etapa-2{
            font-family: v.$font-regular-01;
            // font-size: v.$h3-xl;
            padding: 1rem 1.5rem;

            border-bottom: .3rem solid transparent;
            border-image-source: v.$gradient-azul-01; 
            border-image-slice: 1;
            h3:nth-of-type(1){
              font-family: v.$font-regular-01;
            }
            span{
              font-family: v.$font-bold-01;
            }
            // @include m.mq-sm{
            //   font-size: v.$h3-sm;
            // }
          }
        }
      }
      .content-block:nth-of-type(6){
        ul{
          padding: 2.5rem 0rem 0rem 0rem;
        }
      }
    }
  }

  .description{
    margin: 8rem 0rem 0rem 0rem;
    padding: 0rem 1.5rem;
    font-family: v.$font-regular-01;
    text-align: center;
    span{
      font-family: v.$font-bold-01;
    }
    @include m.mq-sm{
      margin: 6rem 3rem 0rem 3rem;
      padding: 0rem 0rem 5rem 0rem;

      border-bottom: .3rem solid transparent;
      border-image-source: v.$gradient-dorado-01; 
      border-image-slice: 1;
    }
  }
}