@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-planes-financiacion{
  padding: 0rem 0rem 11rem 0rem;
  @include m.mq-sm{
    padding: 5rem 0rem 6rem 0rem;
  }
  .padding-alterno{
    padding-left: 0rem;
    @include m.mq-sm{
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  .content-block{
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    column-gap: 7.5rem;
    @include m.mq-md{
      grid-template-columns: 1fr;
    }
    .item-box:nth-of-type(1){
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .item-box:nth-of-type(2){
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h2:nth-of-type(1){
        color: v.$dorado-02;
        margin: 0rem 0rem 4rem 0rem;
        @include m.mq-md{
          margin: 4rem 0rem 4rem 0rem;
          text-align: center;
        }
        @include m.mq-sm{
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
      p:nth-of-type(1){
        margin: 0rem 0rem 3.5rem 0rem;
        @include m.mq-sm{
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          font-size: v.$body-02;
        }
      }
      img:nth-of-type(1){
        @include m.mq-md{
          margin:0rem 0rem 5rem 0rem;
        }
      }
      p:nth-of-type(2),
      p:nth-of-type(3),
      p:nth-of-type(4){
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:0rem 1.5rem;
        margin: 0rem 0rem 4rem 0rem;
        font-size: v.$body-03;
        @include m.mq-sm{
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          // font-size: v.$body-03;
          font-size: v.$body-04;
        }
        span{
          i:before{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 45px;

            color: v.$dorado-02;
            font-size: v.$h2-xl;
          }
          a{
            font-size: v.$body-03;
            text-decoration: underline;
            color: v.$azul-01;
            @include m.mq-sm{
              font-size: v.$body-04;
            }
            &:focus{
              color: v.$dorado-02;
              text-decoration: underline;
            }
            &:hover{
              color: v.$dorado-02;
              text-decoration: underline;
            }
          }
        }
      }
      p:last-of-type{
        margin: 0rem 0rem 0rem 0rem;
      }
    }
  }
}