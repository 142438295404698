/* VARIABLES */
/** Media Queries **/
/* GLOBALES */
html {
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%;
}

body {
  font-size: 2rem;
  font-family: "Helvetica-Regular";
  color: #112334;
}
@media (max-width: 767px) {
  body {
    font-size: 1.6rem;
  }
}
body.stop-scroll {
  overflow-y: hidden;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.hide-item {
  opacity: 0;
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1336px !important;
  }
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 1.7rem;
}

body::-webkit-scrollbar-button:increment, body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #bc913e;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* FONT SIZING */
h1, h2, h3, h4, h5, h6, p, li, a {
  margin: 0rem;
  padding: 0rem;
  line-height: 1.1;
}

h1 {
  font-size: 5rem;
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  h1 {
    font-size: 3.6rem;
  }
}

h2 {
  font-size: 4rem;
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  h2 {
    font-size: 3.2rem;
  }
}

h3 {
  font-size: 3.6rem;
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  h3 {
    font-size: 2.6rem;
  }
}

h4 {
  font-size: 3rem;
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  h4 {
    font-size: 2.4rem;
  }
}

h5 {
  font-size: 2.4rem;
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  h5 {
    font-size: 2rem;
  }
}

h6 {
  font-size: 2rem;
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  h6 {
    font-size: 1.8rem;
  }
}

p {
  font-size: 2rem;
  font-family: "Helvetica-Regular";
}
@media (max-width: 767px) {
  p {
    font-size: 1.6rem;
  }
}
p strong {
  font-family: "Helvetica-Bold";
}

a {
  font-size: 2rem;
  text-decoration: none;
}
@media (max-width: 767px) {
  a {
    font-size: 1.6rem;
  }
}
a strong {
  font-family: "Helvetica-Bold";
}
a:focus {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

ul, ol {
  padding: 0rem;
  margin: 0rem;
  list-style: none;
}
ul li, ol li {
  font-size: 2rem;
}
@media (max-width: 767px) {
  ul li, ol li {
    font-size: 1.6rem;
  }
}
ul li strong, ol li strong {
  font-family: "Helvetica-Bold";
}

.center-recaptcha {
  padding-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .center-recaptcha {
    padding-bottom: 2rem;
  }
}

iframe {
  width: 100% !important;
}

.g-recaptcha {
  position: relative;
  width: 225px;
  height: 60px;
}
@media screen and (max-width: 767px) {
  .g-recaptcha {
    height: 55px;
  }
}

.g-recaptcha > :first-child {
  transform: scale(0.8);
  transform-origin: 0% 0;
}
@media screen and (max-width: 480px) {
  .g-recaptcha > :first-child {
    transform: scale(0.65);
  }
}

@media (max-width: 991px) {
  .reset-padding {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
  }
}

.center-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .center-btn-responsive {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .align-right {
    justify-content: center;
  }
}

.align-right-full {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1700;
  width: 100vw;
  height: 100vh;
  background-color: rgba(17, 35, 52, 0.7);
}

.grid-section-controller {
  display: grid;
  grid-template-columns: 1fr;
}
.grid-section-controller .section-form.scrolled {
  grid-row: 7/8;
}

main {
  margin: 8.8rem 0rem 0rem 0rem;
}
@media (max-width: 1270px) {
  main {
    margin: 8.8rem 0rem 0rem 0rem;
  }
}
@media (max-width: 991px) {
  main {
    margin: 7rem 0rem 0rem 0rem;
  }
}
@media (max-width: 767px) {
  main {
    margin: 8.3rem 0rem 0rem 0rem;
  }
}

/* FONTS */
@font-face {
  font-family: "Arsenal-Bold";
  src: url("./../../build/fonts/Arsenal-Bold.ttf") format("opentype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arsenal-Regular";
  src: url("./../../build/fonts/Arsenal-Regular.ttf") format("opentype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica-Bold";
  src: url("./../../build/fonts/Helvetica-Bold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica-Regular";
  src: url("./../../build/fonts/Helvetica-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "font-adp";
  src: url("./../../build/fonts/font-adp/icomoon.eot?e8up72");
  src: url("./../../build/fonts/font-adp/icomoon.eot?e8up72#iefix") format("embedded-opentype"), url("./../../build/fonts/font-adp/icomoon.ttf?e8up72") format("truetype"), url("./../../build/fonts/font-adp/icomoon.woff?e8up72") format("woff"), url("./../../build/fonts/font-adp/icomoon.svg?e8up72#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "font-adp" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-section:before {
  content: "\e94b";
}

.icon-arrow-up-section:before {
  content: "\e94c";
}

.icon-video:before {
  content: "\e94d";
}

.icon-intereses:before {
  content: "\e900";
}

.icon-alta-rentabilidad:before {
  content: "\e901";
}

.icon-cancha-de-tenis:before {
  content: "\e902";
}

.icon-cancha-multiple:before {
  content: "\e903";
}

.icon-casas-nuevas:before {
  content: "\e904";
}

.icon-cobertura-wifi:before {
  content: "\e905";
}

.icon-descuentos-financieros:before {
  content: "\e906";
}

.icon-email:before {
  content: "\e907";
}

.icon-facebook:before {
  content: "\e908";
}

.icon-financiacion-constructora:before {
  content: "\e909";
}

.icon-flecha-recorrido:before {
  content: "\e90a";
}

.icon-galeria:before {
  content: "\e90b";
}

.icon-instagram:before {
  content: "\e90c";
}

.icon-mensaje:before {
  content: "\e90d";
}

.icon-menu:before {
  content: "\e90e";
}

.icon-phone:before {
  content: "\e90f";
}

.icon-plazo:before {
  content: "\e910";
}

.icon-porcentaje-cuota-inicial:before {
  content: "\e911";
}

.icon-recorrido360:before {
  content: "\e912";
}

.icon-reloj:before {
  content: "\e913";
}

.icon-spec-area-piscina:before {
  content: "\e914";
}

.icon-ubicacion:before {
  content: "\e915";
}

.icon-valor-mas-rentable:before {
  content: "\e916";
}

.icon-waze:before {
  content: "\e917";
}

.icon-whatsApp:before {
  content: "\e918";
}

.icon-youtube:before {
  content: "\e919";
}

.icon-zona-de-masajes:before {
  content: "\e91a";
}

.icon-zona-relajacion-bali:before {
  content: "\e91b";
}

.icon-area-construida:before {
  content: "\e91c";
}

.icon-arrow-left-section:before {
  content: "\e91d";
}

.icon-arrow-right-button:before {
  content: "\e91e";
}

.icon-arrow-right-section:before {
  content: "\e91f";
}

.icon-close-interaction:before {
  content: "\e920";
}

.icon-close-interaction-mobile:before {
  content: "\e921";
}

.icon-cobertura-wifi1:before {
  content: "\e922";
}

.icon-financiacion:before {
  content: "\e923";
}

.icon-girar-pantalla:before {
  content: "\e924";
}

.icon-habitaciones:before {
  content: "\e925";
}

.icon-lote-privado:before {
  content: "\e926";
}

.icon-paneles-solares:before {
  content: "\e927";
}

.icon-parqueaderos:before {
  content: "\e928";
}

.icon-piscina-privada:before {
  content: "\e929";
}

.icon-plazo1:before {
  content: "\e92a";
}

.icon-precio:before {
  content: "\e92b";
}

.icon-spec-acabados-lujo:before {
  content: "\e92c";
}

.icon-spec-acabados-madera:before {
  content: "\e92d";
}

.icon-spec-altura:before {
  content: "\e92e";
}

.icon-spec-area-lote:before {
  content: "\e92f";
}

.icon-spec-area-total:before {
  content: "\e930";
}

.icon-spec-balcon:before {
  content: "\e931";
}

.icon-spec-bao:before {
  content: "\e932";
}

.icon-spec-capacitacion:before {
  content: "\e933";
}

.icon-spec-cocina:before {
  content: "\e934";
}

.icon-spec-cocina-isla-central:before {
  content: "\e935";
}

.icon-spec-diferentes-profundidades:before {
  content: "\e936";
}

.icon-spec-escaleras:before {
  content: "\e937";
}

.icon-spec-fachada:before {
  content: "\e938";
}

.icon-spec-fondo:before {
  content: "\e939";
}

.icon-spec-franja-limoncillo:before {
  content: "\e93a";
}

.icon-spec-frente:before {
  content: "\e93b";
}

.icon-spec-habitacion:before {
  content: "\e93c";
}

.icon-spec-habitacion-nios:before {
  content: "\e93d";
}

.icon-spec-habitacion-principal:before {
  content: "\e93e";
}

.icon-spec-iluminacion-natural:before {
  content: "\e93f";
}

.icon-spec-increibles-vistas:before {
  content: "\e940";
}

.icon-spec-instalacion-equipos:before {
  content: "\e941";
}

.icon-spec-lavadora:before {
  content: "\e942";
}

.icon-spec-lavaropa:before {
  content: "\e943";
}

.icon-spec-parqueadero:before {
  content: "\e944";
}

.icon-spec-pasillo:before {
  content: "\e945";
}

.icon-spec-personalizacion:before {
  content: "\e946";
}

.icon-spec-piscina:before {
  content: "\e947";
}

.icon-spec-ventanales:before {
  content: "\e948";
}

.icon-urbanismo-acueducto:before {
  content: "\e949";
}

.icon-valorizacion:before {
  content: "\e94a";
}

.btn-cta-01 {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
  font-family: "Arsenal-Bold";
  font-size: 3rem;
  padding: 1.5rem 4rem;
  text-align: center;
}
.btn-cta-01:focus {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
}
.btn-cta-01:hover {
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  color: #FFF;
}
@media (max-width: 767px) {
  .btn-cta-01 {
    font-size: 2.6rem;
    padding: 1.2rem 2rem;
  }
}

.btn-cta-urbanismo {
  border: 0.1rem solid #112334;
  padding: 0.7rem 1.5rem 0.7rem 5rem;
  color: #112334;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}
.btn-cta-urbanismo span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-cta-urbanismo span i:before {
  margin-left: 5rem;
  font-weight: bolder;
}
@media (max-width: 991px) {
  .btn-cta-urbanismo span i:before {
    margin-left: 2.5rem;
  }
}
.btn-cta-urbanismo:focus {
  color: #112334;
}
.btn-cta-urbanismo:hover {
  color: #FFF;
}
.btn-cta-urbanismo.--etapa-1:hover {
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.btn-cta-urbanismo.--etapa-2:hover {
  color: #FFF;
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border: 0.1rem solid transparent;
}

.btn-cta-ubicacion {
  border: 0.1rem solid #FFF;
  background: transparent;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: #FFF;
  display: block;
  width: 100%;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
}
.btn-cta-ubicacion span i:before {
  font-weight: bolder;
  margin-right: 1rem;
}
.btn-cta-ubicacion:focus {
  color: #112334;
  border: 0.1rem solid transparent;
  background: #FFF;
}
.btn-cta-ubicacion:hover {
  color: #112334;
  border: 0.1rem solid transparent;
  background: #FFF;
}

.btn-cta-etapa {
  border: 0.1rem solid #112334;
  padding: 0.7rem 3.5rem;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  text-align: center;
  color: #112334;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}
@media (max-width: 767px) {
  .btn-cta-etapa {
    margin: 0rem 1.5rem 0rem 1.5rem;
    padding: 0.7rem 2.5rem;
  }
}
.btn-cta-etapa span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-cta-etapa span i:before {
  margin: 0rem 0rem 0rem 1.5rem;
  font-weight: bolder;
}
.btn-cta-etapa.--etapa-1:hover {
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.btn-cta-etapa.--etapa-2:hover {
  color: #FFF;
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border: 0.1rem solid transparent;
}

.btn-cta-link {
  border: 0.1rem solid #112334;
  padding: 0.7rem 3.5rem;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  text-align: center;
  color: #112334;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}
.btn-cta-link:focus {
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.btn-cta-link:hover {
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.btn-cta-link span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-cta-link span i:before {
  margin: 0rem 0rem 0rem 5rem;
  font-weight: bolder;
}
@media (max-width: 767px) {
  .btn-cta-link {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .btn-cta-link span i:before {
    margin: 0rem 0rem 0rem 2rem;
  }
}

.btn-cta-regresar {
  border: 0.1rem solid #112334;
  padding: 0.7rem 3.5rem;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  text-align: center;
  color: #112334;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}
@media (max-width: 767px) {
  .btn-cta-regresar {
    width: 100%;
    justify-content: center;
    position: relative;
  }
}
.btn-cta-regresar:focus {
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.btn-cta-regresar:hover {
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.btn-cta-regresar span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767px) {
  .btn-cta-regresar span {
    position: absolute;
    left: 2.5rem;
    top: 0.7rem;
  }
}
.btn-cta-regresar span i {
  transform: rotate(-180deg);
}
.btn-cta-regresar span i:before {
  margin: 0rem 0rem 0rem 3.5rem;
  font-weight: bolder;
}

.btn-cta-regresar-02 {
  color: #112334;
  font-family: "Arsenal-Bold";
  font-size: 2rem;
}
@media (max-width: 767px) {
  .btn-cta-regresar-02 {
    font-family: "Helvetica-Regular";
    font-size: 1.2rem;
  }
}
.btn-cta-regresar-02 span i:before {
  font-size: 1.4rem;
  margin: 0rem 1rem 0rem 0rem;
}
@media (max-width: 767px) {
  .btn-cta-regresar-02 span i:before {
    font-size: 1.2rem;
  }
}
.btn-cta-regresar-02:focus {
  color: #bc913e;
}
.btn-cta-regresar-02:hover {
  color: #bc913e;
}

.btn-submit {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  padding: 1rem 6rem;
  text-align: center;
  border: none;
  outline: none;
  width: fit-content;
}
.btn-submit:focus {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
}
.btn-submit:hover {
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  color: #FFF;
}
@media (max-width: 767px) {
  .btn-submit {
    font-size: 2.6rem;
  }
}

/* CHATBOT */
@keyframes pulse-scale {
  0% {
    transform: scale(1.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes number-transition {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }
  25% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.chatbot-initial-state {
  position: fixed;
  bottom: 2.5rem;
  right: 2rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  transform: scale(1);
  animation: pulse 2s infinite;
  z-index: 1200;
  padding: 0.7rem;
}
@media screen and (max-width: 767px) {
  .chatbot-initial-state {
    bottom: 1.7rem;
    right: 1.1rem;
    padding: 0.3rem;
  }
}
.chatbot-initial-state:hover {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  transform: scale(1.1);
  animation: pulse-scale 2s infinite;
}
.chatbot-initial-state .contendor-btn {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}
.chatbot-initial-state .contendor-btn img:nth-of-type(1) {
  margin: 0.7rem;
}
@media screen and (max-width: 767px) {
  .chatbot-initial-state .contendor-btn img:nth-of-type(1) {
    margin: 0.7rem;
    width: 50px;
    height: 50px;
  }
}
.chatbot-initial-state .contendor-btn .contador-msn {
  position: absolute;
  background: #EAE79D;
  color: #112334;
  font-family: "Helvetica-Bold";
  font-size: 1.6rem;
  border-radius: 50%;
  padding: 0.5rem;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #112334;
  animation-name: number-transition;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 767px) {
  .chatbot-initial-state .contendor-btn .contador-msn {
    font-size: 1.2rem;
    padding: 0.25rem;
    width: 17px;
    height: 17px;
  }
}

/* CHATBOT SMALL */
.chatbot-small {
  z-index: 1150;
  position: fixed;
  bottom: 2rem;
  right: 6.2rem;
  width: 300px;
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  .chatbot-small {
    bottom: 1.7rem;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 95%;
  }
}
.chatbot-small .card {
  border: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0rem;
}
@media screen and (max-width: 767px) {
  .chatbot-small .card {
    border-radius: 0rem;
  }
}
.chatbot-small .card-body {
  padding: 1.2rem 3rem 2rem 1.4rem;
}

@keyframes transition-content-chatbot {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  75% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.body-chatbot-small {
  padding: 1.5rem 1.6rem 0rem 0rem;
}
.body-chatbot-small .msn-default {
  color: #000;
  font-family: "Helvetica-Regular";
  font-size: 1.6rem;
  margin: 0rem 0rem 1rem 0rem;
}
.body-chatbot-small.transition {
  animation-name: transition-content-chatbot;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.75s;
  opacity: 1;
  transform: scale(1);
}
.body-chatbot-small p:nth-of-type(3) {
  color: #B0B0B0;
  font-family: "Arsenal-Regular";
  font-size: 1.4rem;
  margin: 1.5rem 0rem 0rem 0rem;
}

/* CHATBOT FULL */
.chatbot-full {
  z-index: 1350;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 350px;
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  .chatbot-full {
    width: 100%;
    bottom: 0rem;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
.chatbot-full .card {
  border: none;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0rem;
}
@media screen and (max-width: 767px) {
  .chatbot-full .card {
    height: 100vh;
    border-radius: 0rem;
  }
}
.chatbot-full .card-body {
  padding: 1.2rem 3rem 2rem 1.4rem;
  border-top: 0.8rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media screen and (max-width: 767px) {
  .chatbot-full .card-body {
    border: none;
    overflow-y: scroll;
    padding: 6rem 1.4rem 2rem 1.4rem;
  }
}

.head-chabot-full {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom: 0.1rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 0rem 0rem 1rem 0rem;
  background: #FFF;
}
@media screen and (max-width: 767px) {
  .head-chabot-full {
    height: auto;
  }
}
.head-chabot-full.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  z-index: 1600;
  padding: 1.2rem 1.4rem 1rem 1.4rem;
}
.head-chabot-full .logo-chatbot {
  width: 13.8rem;
}
.head-chabot-full .content-head {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 0rem 0.7rem;
}
@media screen and (max-width: 767px) {
  .head-chabot-full .content-head {
    align-items: center;
    height: auto;
  }
}
.head-chabot-full .status-chatbot {
  color: #000;
  font-family: "Helvetica-Regular";
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0rem 0.8rem;
  height: 100%;
}
.head-chabot-full .status-chatbot .online-circle {
  width: 1.2rem;
  height: 1.2rem;
  background: #81C342;
  border-radius: 50%;
}
.head-chabot-full .close-chatbot span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.head-chabot-full .close-chatbot span i:before {
  display: inline-flex;
  color: #FFF;
  background: #bc913e;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 50%;
  font-size: 1rem;
}

.body-chatbot-full {
  padding: 2rem 0rem 0rem 0rem;
  margin: 0rem 1.2rem 0rem 2.6rem;
}
@media screen and (max-width: 767px) {
  .body-chatbot-full {
    padding: 0rem 0rem 0rem 0rem;
    margin: 0rem 1.2rem 0rem 1.2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.body-chatbot-full .powered {
  color: #B0B0B0;
  font-family: "Arsenal-Bold";
  font-size: 1.2rem;
  text-align: center;
  margin: 2.5rem 0rem 0rem 0rem;
}
@media screen and (max-width: 767px) {
  .body-chatbot-full .powered {
    margin: 2.5rem 0rem 0rem 0rem;
  }
}
.body-chatbot-full .content-chatbot-full-inicio p {
  color: #bc913e;
  font-family: "Helvetica-Regular";
  font-size: 1.6rem;
  margin: 0rem 0rem 2rem 0rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .body-chatbot-full .content-chatbot-full-inicio p {
    margin: 0rem 0rem 3rem 0rem;
    font-size: 1.6rem;
  }
}
.body-chatbot-full .content-chatbot-full-inicio ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.body-chatbot-full .content-chatbot-full-inicio ul li {
  margin: 0rem 0rem 1rem 0rem;
}
@media screen and (max-width: 767px) {
  .body-chatbot-full .content-chatbot-full-inicio ul li {
    margin: 0rem 0rem 2rem 0rem;
  }
}
.body-chatbot-full .content-chatbot-full-inicio ul li button {
  background-color: #FFF;
  position: relative;
  display: block;
  color: #bc913e;
  font-family: "Arsenal-Bold";
  font-size: 1.6rem;
  width: 100%;
  border: 0.1rem solid #bc913e;
  padding: 1.4rem 0.5rem 1.1rem 0.5rem;
  text-align: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 767px) {
  .body-chatbot-full .content-chatbot-full-inicio ul li button {
    font-size: 2.8rem;
  }
}
.body-chatbot-full .content-chatbot-full-inicio ul li button:hover {
  color: #112334;
  cursor: pointer;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.body-chatbot-full .content-chatbot-full-inicio ul li button .icon-arrow-right:before {
  position: absolute;
  right: 1.5rem;
  top: 1.2rem;
  color: #FFF;
  font-size: 0.8rem;
  font-weight: bolder;
}
.body-chatbot-full .content-chatbot-full-inicio ul li:last-of-type {
  margin: 0rem 0rem 0rem 0rem;
}
.body-chatbot-full .content-chatbot-full-inicio.transition {
  animation-name: transition-content-chatbot;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.75s;
  opacity: 1;
  transform: scale(1);
}
.body-chatbot-full .content-chatbot-full-asesoria p:nth-of-type(1) {
  color: #bc913e;
  font-family: "Helvetica-Regular";
  font-size: 1.6rem;
  margin: 0rem 0rem 1.5rem 0rem;
  text-align: center;
}
.body-chatbot-full .content-chatbot-full-asesoria ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.body-chatbot-full .content-chatbot-full-asesoria ul li {
  margin: 0rem 0rem 1rem 0rem;
}
@media screen and (max-width: 767px) {
  .body-chatbot-full .content-chatbot-full-asesoria ul li {
    margin: 0rem 0rem 2rem 0rem;
  }
}
.body-chatbot-full .content-chatbot-full-asesoria ul li button {
  background-color: #FFF;
  position: relative;
  display: block;
  color: #112334;
  font-family: "Arsenal-Bold";
  font-size: 1.2rem;
  width: 100%;
  border: 0.1rem solid #bc913e;
  padding: 0.6rem 0.5rem;
  text-align: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  background-color: none;
}
.body-chatbot-full .content-chatbot-full-asesoria ul li button:hover {
  color: #112334;
  cursor: pointer;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border: 0.1rem solid transparent;
}
.body-chatbot-full .content-chatbot-full-asesoria ul li button .icon-arrow-right:before {
  position: absolute;
  right: 1.5rem;
  top: 1.2rem;
  color: #FFF;
  font-size: 0.8rem;
  font-weight: bolder;
}
.body-chatbot-full .content-chatbot-full-asesoria ul li:last-of-type {
  margin: 0rem 0rem 0rem 0rem;
}
.body-chatbot-full .content-chatbot-full-asesoria.transition {
  animation-name: transition-content-chatbot;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.75s;
  opacity: 1;
  transform: scale(1);
}

.body-chatbot-full.step-02,
.body-chatbot-full.step-03 {
  padding: 2rem 0rem 0rem 0rem;
}

/* CONTENT FORM CHATBOT */
.content-form-chatbot {
  /* PRISTINE IMPLEMENTATION */
}
.content-form-chatbot .form-group.has-error {
  margin-bottom: 0.5rem;
}
.content-form-chatbot .form-group {
  margin-bottom: 0.7rem;
}
@media screen and (max-width: 767px) {
  .content-form-chatbot .form-group {
    margin-bottom: 0.7rem;
  }
}
.content-form-chatbot .form-group .container-option-check {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e5e5e5;
  padding: 0px 10px;
  margin-bottom: 1rem;
}
.content-form-chatbot .form-group .container-option-check.error {
  border: 1px solid #7B2627;
}
.content-form-chatbot .form-group .container-option-check.ultimo {
  margin-bottom: 0px;
}
.content-form-chatbot .form-group .option-checked {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 0.1rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 0px 10px;
  margin-bottom: 10px;
  color: #bc913e;
}
.content-form-chatbot .form-group .option-checked > .tx-opcion-check {
  border-left: 1px solid #bc913e;
}
.content-form-chatbot .form-group .checkbox-opcion {
  color: #112334;
  font-family: "Helvetica-Regular";
  font-size: 1.2rem !important;
  margin-right: 10px;
  cursor: pointer;
}
.content-form-chatbot .form-group .tx-opcion-check {
  font-family: "Helvetica-Regular";
  font-size: 1.2rem;
  border-left: 1px solid #e5e5e5;
  padding: 8px 0px 2px 10px;
  height: 33px;
}
@media screen and (max-width: 1560px) {
  .content-form-chatbot .form-group .tx-opcion-check {
    padding: 7px 0px 0px 10px;
    font-size: 1.2rem;
    height: 30px;
  }
}
@media screen and (max-width: 1270px) {
  .content-form-chatbot .form-group .tx-opcion-check {
    font-size: 1.2rem;
  }
}
.content-form-chatbot .form-group .tx-opcion-check.error {
  border-left: 1px solid #7B2627;
  color: #7B2627;
}
.content-form-chatbot .form-group .help-block.with-errors .list-unstyled {
  font-family: "Helvetica-Regular";
  color: #7B2627 !important;
  margin: 3px 0px 0px 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  line-height: 1;
}
.content-form-chatbot .form-group .help-block.with-errors .list-unstyled li {
  font-size: 1.2rem;
}
@media screen and (max-width: 767px) {
  .content-form-chatbot .form-group .help-block.with-errors .list-unstyled li {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 767px) {
  .content-form-chatbot .form-group .help-block.with-errors .list-unstyled {
    margin: 1.5px 0px 0px 1rem;
  }
}
.content-form-chatbot .checkbox {
  font-family: "Helvetica-Regular";
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 0rem 1rem;
  margin: 0rem 0rem 0rem 0rem;
}
.content-form-chatbot .checkbox input {
  margin: 0.5rem 0rem 0rem 0rem;
}
.content-form-chatbot .checkbox p:nth-of-type(1) {
  color: #112334;
  font-family: "Helvetica-Regular";
  font-size: 1rem;
  margin: 0rem 0rem 0rem 0rem;
  line-height: 1.25;
  text-align: left;
}
.content-form-chatbot .checkbox p:nth-of-type(1) .tx_terminos {
  color: #112334;
  font-family: "Helvetica-Regular";
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .content-form-chatbot .checkbox p:nth-of-type(1) .tx_terminos {
    font-size: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .content-form-chatbot .checkbox p:nth-of-type(1) {
    font-size: 1rem;
    width: auto;
  }
}
.content-form-chatbot .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e5e5e5;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.1rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  border-radius: 0rem;
  color: #112334;
  font-family: "Helvetica-Regular";
  font-size: 1.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.content-form-chatbot .form-control:focus {
  box-shadow: none;
}
.content-form-chatbot .item-checbox {
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1.5rem;
}
.content-form-chatbot .item-checbox p:nth-of-type(1) {
  color: #112334;
  font-size: 1rem;
}
.content-form-chatbot .item-checbox p:nth-of-type(1) span a {
  color: #112334;
  font-size: 1rem;
  text-decoration: underline;
}
.content-form-chatbot .pristine-error.text-help {
  font-size: 1rem;
  color: #7B2627;
  margin: 0.5rem 0rem 0rem 1rem;
  text-align: left;
}
.content-form-chatbot .input-error {
  font-size: 1rem;
  color: #7B2627;
  margin: 0.5rem 0rem 0rem 1rem;
}
.content-form-chatbot .form-control::placeholder {
  color: #112334;
}
.content-form-chatbot label {
  color: #112334;
  font-family: "Helvetica-Regular";
  font-size: 1.4rem;
  line-height: 1;
  margin: 0.3rem 0rem 1rem 0rem;
}
@media screen and (max-width: 767px) {
  .content-form-chatbot label {
    font-size: 1.4rem;
  }
}
.content-form-chatbot .enviar-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0rem 1rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2rem;
  font-family: "Arsenal-Bold";
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  width: 100%;
  border: 0.1rem solid transparent;
  margin: 0.7rem 0rem 0rem 0rem;
}
.content-form-chatbot .enviar-form:focus {
  text-decoration: none;
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  outline: none;
}
.content-form-chatbot .enviar-form:hover {
  text-decoration: none;
  color: #112334;
  background: #FFF;
  border: 0.1rem solid #112334;
}

.procesando-datos-chatbot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@keyframes pulse-scale {
  0% {
    transform: scale(1.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.menu-agil {
  position: fixed;
  z-index: 1000;
  bottom: 17.5%;
  right: 4rem;
}
@media (max-width: 767px) {
  .menu-agil {
    bottom: 1rem;
    right: 1rem;
  }
}
.menu-agil span i:before {
  display: block;
  color: #FFF;
  font-size: 5rem;
  padding: 2rem;
  background: #4CAD20;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  transform: scale(1);
  animation: pulse 2s infinite;
  z-index: 1200;
  transition: ease-in-out 0.3s;
}
@media (max-width: 767px) {
  .menu-agil span i:before {
    font-size: 3.5rem;
    padding: 1.5rem;
  }
}
.menu-agil:hover {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  border-radius: 50%;
  transform: scale(1.1);
  animation: pulse-scale 2s infinite;
}

/** TERMINOS CONDICIONES **/
.main-terminos-condiciones {
  padding: 4rem 0rem 0rem 0rem;
}

.content-terminos-condiciones {
  padding: 7rem 0rem 0rem 0rem;
}
.content-terminos-condiciones h1 {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .content-terminos-condiciones h1 {
    font-size: 3.6rem;
  }
}
.content-terminos-condiciones h2 {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .content-terminos-condiciones h2 {
    font-size: 3.2rem;
  }
}
.content-terminos-condiciones p {
  font-family: "Helvetica-Regular";
  font-size: 2rem;
}
@media (max-width: 767px) {
  .content-terminos-condiciones p {
    font-size: 1.6rem;
  }
}

.modal-landing {
  z-index: 1800;
}
.modal-landing .modal-dialog {
  max-width: 95%;
  margin-top: 0rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  margin: 0rem auto 0rem auto;
}
@media (max-width: 991px) {
  .modal-landing .modal-dialog {
    max-width: 50%;
  }
}
@media (max-width: 767px) {
  .modal-landing .modal-dialog {
    max-width: 70%;
  }
}
@media (max-width: 575px) {
  .modal-landing .modal-dialog {
    max-width: 85%;
  }
}
.modal-landing .modal-body {
  position: relative;
  padding: 0rem;
}
.modal-landing .modal-body .close-modal {
  position: absolute;
  right: 1rem;
  top: 3rem;
  z-index: 100;
  cursor: pointer;
}
@media (max-width: 767px) {
  .modal-landing .modal-body .close-modal {
    top: 1rem;
    right: 0rem;
  }
}
.modal-landing .modal-body .close-modal span i:before {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2rem;
  color: #112334;
  padding: 2rem;
}
@media (max-width: 767px) {
  .modal-landing .modal-body .close-modal span i:before {
    padding: 1rem;
  }
}
.modal-landing .modal-body .close-modal-mobile {
  cursor: pointer;
}
.modal-landing .modal-body .close-modal-mobile span i:before {
  display: block;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2rem;
  color: #112334;
  padding: 1.1rem 1rem 1rem 1rem;
}
.modal-landing .modal-body .close-modal-tabs-casas {
  cursor: pointer;
}
.modal-landing .modal-body .close-modal-tabs-casas span i:before {
  display: block;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2rem;
  color: #112334;
  padding: 1.1rem 1rem 1rem 1rem;
}
.modal-landing .modal-body .close-modal-tabs-casas-desktop {
  position: absolute;
  right: 0;
  top: 2rem;
  z-index: 100;
  cursor: pointer;
}
.modal-landing .modal-body .close-modal-tabs-casas-desktop span i:before {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2rem;
  color: #112334;
  padding: 2rem;
}
.modal-landing .modal-body .close-modal-galeria-01 {
  position: absolute;
  right: 1rem;
  top: 3rem;
  z-index: 100;
  cursor: pointer;
}
@media (max-width: 767px) {
  .modal-landing .modal-body .close-modal-galeria-01 {
    top: 1rem;
    right: 0rem;
  }
}
.modal-landing .modal-body .close-modal-galeria-01 span i:before {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2rem;
  color: #112334;
  padding: 2rem;
}
@media (max-width: 767px) {
  .modal-landing .modal-body .close-modal-galeria-01 span i:before {
    padding: 1rem;
  }
}
.modal-landing .modal-body .close-modal-galeria-02 {
  position: absolute;
  right: 1rem;
  top: 3rem;
  z-index: 100;
  cursor: pointer;
}
@media (max-width: 767px) {
  .modal-landing .modal-body .close-modal-galeria-02 {
    top: 1rem;
    right: 0rem;
  }
}
@media (max-width: 575px) {
  .modal-landing .modal-body .close-modal-galeria-02 {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0rem 0rem 10rem 0rem;
  }
}
.modal-landing .modal-body .close-modal-galeria-02 span i:before {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2rem;
  color: #112334;
  padding: 2rem;
}
@media (max-width: 767px) {
  .modal-landing .modal-body .close-modal-galeria-02 span i:before {
    padding: 1rem;
  }
}
@media (max-width: 575px) {
  .modal-landing .modal-body .close-modal-galeria-02 span i:before {
    background: none;
    padding: 1rem;
    color: #eceba2;
    border: 0.1rem solid #eceba2;
  }
}

.modal-landing.modal-landing--obra .modal-dialog {
  max-width: 95%;
  margin-top: 0rem;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--obra .modal-dialog {
    max-width: 90%;
    margin: 0rem auto 0rem auto;
  }
}
.modal-landing.modal-landing--obra .modal-body {
  background: #112334;
  padding: 5rem 6rem;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--obra .modal-body {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
}
.modal-landing.modal-landing--obra .modal-body #video-interaction-01,
.modal-landing.modal-landing--obra .modal-body #video-interaction-02 {
  max-width: 945px;
  margin: 0rem auto 0rem auto;
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 991px) {
  .modal-landing.modal-landing--obra .modal-body #video-interaction-01,
.modal-landing.modal-landing--obra .modal-body #video-interaction-02 {
    margin: 0rem auto 0rem auto;
  }
}
.modal-landing.modal-landing--obra .modal-body #img-preview-video-01:hover,
.modal-landing.modal-landing--obra .modal-body #img-preview-video-02:hover {
  cursor: pointer;
}
.modal-landing.modal-landing--obra .modal-body .video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modal-landing.modal-landing--obra .modal-body video {
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
  max-width: 945px;
  width: 100%;
  height: auto;
}

.modal-landing.modal-landing--plano .item-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.modal-landing.modal-landing--plano .item-box p:nth-of-type(1) {
  width: 100%;
  padding: 0.6rem 0.5rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  text-align: center;
}
.modal-landing.modal-landing--plano .item-box p:nth-of-type(1).etapa-2 {
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  z-index: 200;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0rem 0rem 0rem;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile .content-tooltip {
  display: block;
  width: 100%;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile .content-tooltip p:nth-of-type(1) {
  font-family: "Arsenal-Regular";
  font-size: 2.6rem;
  margin: 0rem 0rem 0.5rem 0rem;
  padding: 1rem 0.5rem 0rem 0.5rem;
  text-align: center;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile .content-tooltip p:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile .content-tooltip p:nth-of-type(2) {
  font-size: 1.6rem;
  text-align: center;
  padding: 0rem 0.5rem 0.5rem 0.5rem;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile .content-tooltip p:nth-of-type(3) {
  font-family: "Arsenal-Bold";
  font-size: 2rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
  text-align: center;
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  display: block;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile.tooltip-modal-mobile--negro {
  background: #000;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile.tooltip-modal-mobile--negro .content-tooltip p:nth-of-type(1) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile.tooltip-modal-mobile--negro .content-tooltip p:nth-of-type(2) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile.tooltip-modal-mobile--blanco {
  background: #000;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile.tooltip-modal-mobile--blanco .content-tooltip p:nth-of-type(1) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile.tooltip-modal-mobile--blanco .content-tooltip p:nth-of-type(2) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-mobile.tooltip-modal-mobile--blanco .content-tooltip p:nth-of-type(3) {
  color: #FFF;
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
}
.modal-landing.modal-landing--plano .indicador-mobile {
  width: 1.9rem;
  height: 1.9rem;
  background: url("./../../build/img/indicador-tooltip.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: absolute;
  z-index: 200;
}
@media (max-width: 380px) {
  .modal-landing.modal-landing--plano .indicador-mobile {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.modal-landing.modal-landing--plano .indicador-mobile:hover {
  transform: scale(1.1);
}
.modal-landing.modal-landing--plano .indicador-mobile.item-06 {
  right: 36%;
  top: 50%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-11 {
  right: 46%;
  top: 48.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-12 {
  right: 42%;
  top: 41%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-13 {
  right: 38.5%;
  top: 34.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-17 {
  right: 55%;
  top: 39%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-20 {
  right: 72.5%;
  top: 52.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-21 {
  right: 68.5%;
  top: 45.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-22 {
  right: 64%;
  top: 37.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-23 {
  right: 60%;
  top: 30%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-31 {
  right: 78%;
  top: 82%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-32 {
  right: 82%;
  top: 75%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-52 {
  right: 81%;
  top: 49.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-53 {
  right: 76%;
  top: 53.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-54 {
  right: 71%;
  top: 58.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-55 {
  right: 76%;
  top: 33%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-56 {
  right: 70.5%;
  top: 38.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-57 {
  right: 64.5%;
  top: 44%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-58 {
  right: 58%;
  top: 49%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-59 {
  right: 52.5%;
  top: 54.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-60 {
  right: 47%;
  top: 59.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-61 {
  right: 61%;
  top: 28.25%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-62 {
  right: 55.5%;
  top: 33.75%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-63 {
  right: 49.5%;
  top: 38.25%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-64 {
  right: 43%;
  top: 44.25%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-65 {
  right: 37.5%;
  top: 49.75%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-66 {
  right: 32%;
  top: 54.75%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-67 {
  right: 36%;
  top: 27.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-68 {
  right: 30.5%;
  top: 33%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-69 {
  right: 24.5%;
  top: 38.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-70 {
  right: 18%;
  top: 44.5%;
}
.modal-landing.modal-landing--plano .indicador-mobile.item-71 {
  right: 12.5%;
  top: 50%;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop {
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  z-index: 200;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0rem 1.5rem;
  padding: 0rem 0rem 0rem 0rem;
  transform-origin: left bottom;
  transform: scale(1.4);
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop .content-tooltip {
  display: block;
  width: 100%;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop .content-tooltip p:nth-of-type(1) {
  font-family: "Arsenal-Regular";
  font-size: 2.6rem;
  color: #FFF;
  margin: 0rem 0rem 0.5rem 0rem;
  padding: 1rem 1rem 0rem 1rem;
  text-align: center;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop .content-tooltip p:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop .content-tooltip p:nth-of-type(2) {
  font-size: 1.6rem;
  padding: 0rem 1rem 1rem 1rem;
  text-align: center;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop .content-tooltip p:nth-of-type(3) {
  font-family: "Arsenal-Bold";
  font-size: 2rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
  text-align: center;
  padding: 0rem 1;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop .content-tooltip img {
  width: 5.5rem;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop.tooltip-modal-desktop--negro {
  background: #000;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop.tooltip-modal-desktop--negro .content-tooltip p:nth-of-type(1) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop.tooltip-modal-desktop--negro .content-tooltip p:nth-of-type(2) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop.tooltip-modal-desktop--blanco {
  background: #000;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop.tooltip-modal-desktop--blanco .content-tooltip p:nth-of-type(1) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop.tooltip-modal-desktop--blanco .content-tooltip p:nth-of-type(2) {
  color: #FFF;
}
.modal-landing.modal-landing--plano .tooltip-modal-desktop.tooltip-modal-desktop--blanco .content-tooltip p:nth-of-type(3) {
  color: #FFF;
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
}
.modal-landing.modal-landing--plano .indicador-desktop {
  width: 2.2rem;
  height: 2.2rem;
  background: url("./../../build/img/indicador-tooltip.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: absolute;
}
@media (max-width: 1270px) {
  .modal-landing.modal-landing--plano .indicador-desktop {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.modal-landing.modal-landing--plano .indicador-desktop:hover {
  transform: scale(1.1);
}
.modal-landing.modal-landing--plano .indicador-desktop.item-01 {
  right: 10%;
  top: 27%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-02 {
  right: 10%;
  top: 39%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-04 {
  right: 15%;
  top: 57%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-06 {
  right: 30.4%;
  top: 51%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-11 {
  right: 31.5%;
  top: 58%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-12 {
  right: 37%;
  top: 54.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-13 {
  right: 42.5%;
  top: 51%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-17 {
  right: 38%;
  top: 64.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-20 {
  right: 29%;
  top: 78.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-21 {
  right: 34%;
  top: 75.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-22 {
  right: 39%;
  top: 72.25%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-23 {
  right: 44%;
  top: 69%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-24 {
  right: 50%;
  top: 65%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-27 {
  right: 10%;
  top: 64.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-29 {
  right: 16%;
  top: 77%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-31 {
  right: 9%;
  top: 82%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-32 {
  right: 14.5%;
  top: 86%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-52 {
  right: 20.25%;
  top: 44.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-53 {
  right: 18%;
  top: 41.75%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-54 {
  right: 15.75%;
  top: 38.75%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-55 {
  right: 27.5%;
  top: 41%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-56 {
  right: 25%;
  top: 38%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-57 {
  right: 22.75%;
  top: 35%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-58 {
  right: 20.5%;
  top: 32.25%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-59 {
  right: 18.25%;
  top: 29.25%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-60 {
  right: 16%;
  top: 26.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-61 {
  right: 29.5%;
  top: 33.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-62 {
  right: 27%;
  top: 30.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-63 {
  right: 24.75%;
  top: 27.75%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-64 {
  right: 22.5%;
  top: 25%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-65 {
  right: 20.25%;
  top: 22%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-66 {
  right: 17.75%;
  top: 19%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-67 {
  right: 29%;
  top: 21.25%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-68 {
  right: 26.75%;
  top: 18.25%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-69 {
  right: 24.5%;
  top: 15.5%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-70 {
  right: 22.25%;
  top: 12.75%;
}
.modal-landing.modal-landing--plano .indicador-desktop.item-71 {
  right: 20%;
  top: 10.25%;
}

.modal-landing.modal-landing--galeria-01 .modal-dialog {
  max-width: 95%;
  margin-top: 0rem;
}
@media (max-width: 991px) {
  .modal-landing.modal-landing--galeria-01 .modal-dialog {
    max-width: 60%;
    margin: 0rem auto 0rem auto;
  }
}
@media (max-width: 575px) {
  .modal-landing.modal-landing--galeria-01 .modal-dialog {
    max-width: 100%;
    margin: 0rem auto 0rem auto;
  }
}
.modal-landing.modal-landing--galeria-01 .modal-body {
  background: #112334;
  padding: 5rem 0rem 2rem 0rem;
}
@media (max-width: 991px) {
  .modal-landing.modal-landing--galeria-01 .modal-body {
    padding: 2rem 0rem 2rem 0rem;
  }
}
@media (max-width: 575px) {
  .modal-landing.modal-landing--galeria-01 .modal-body {
    padding: 9rem 0rem 9rem 0rem;
  }
}
.modal-landing.modal-landing--galeria-01 .modal-body .tx-helper {
  font-family: "Helvetica-Regular";
  font-size: 1.4rem;
  color: #FFF;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem 0rem 0rem;
  padding: 0rem 1.5rem;
  gap: 0rem 1rem;
}
.modal-landing.modal-landing--galeria-01 .modal-body .tx-helper span i:before {
  font-size: 3.2rem;
}

.modal-landing.modal-landing--galeria-02 .modal-dialog {
  max-width: 95%;
  margin-top: 0rem;
}
@media (max-width: 991px) {
  .modal-landing.modal-landing--galeria-02 .modal-dialog {
    max-width: 60%;
    margin: 0rem auto 0rem auto;
  }
}
@media (max-width: 575px) {
  .modal-landing.modal-landing--galeria-02 .modal-dialog {
    max-width: 100%;
    margin: 0rem auto 0rem auto;
  }
}
.modal-landing.modal-landing--galeria-02 .modal-body {
  background: #112334;
  padding: 1.5rem 0rem 2.5rem 0rem;
}
@media (max-width: 575px) {
  .modal-landing.modal-landing--galeria-02 .modal-body {
    padding: 4rem 0rem 15rem 0rem;
  }
}
.modal-landing.modal-landing--galeria-02 .modal-body .tx-helper {
  font-family: "Helvetica-Regular";
  font-size: 1.4rem;
  color: #FFF;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem 0rem 0rem;
  padding: 0rem 1.5rem;
  gap: 0rem 1rem;
}
.modal-landing.modal-landing--galeria-02 .modal-body .tx-helper span i:before {
  font-size: 3.6rem;
}

.modal-landing.modal-landing--casa-etapa-01 .modal-dialog {
  max-width: 1350px;
  margin: 0rem auto 0rem auto;
}
@media (max-width: 1560px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-dialog {
    max-width: 1150px;
  }
}
@media (max-width: 1270px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-dialog {
    max-width: 85%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body {
  display: grid;
  grid-template-columns: 65% 35%;
  padding: 3.5rem 0rem 3.5rem 12rem;
}
@media (max-width: 991px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body {
    padding: 3.5rem 0rem 3.5rem 2rem;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body {
    grid-template-columns: 1fr;
    padding: 0rem 0rem 0rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction {
  padding: 0rem 6rem 0rem 3.5rem;
}
@media (max-width: 991px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction {
    padding: 1rem 6rem 1rem 2.5rem;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction {
    padding: 1rem 1rem 1rem 1rem;
    background: #000;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1rem;
  margin: 0rem 0rem 3rem 0rem;
}
@media (max-width: 480px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li {
    flex-direction: column;
    text-align: center;
    gap: 0.2rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li span {
  width: 8rem;
  text-align: center;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li span {
    width: 4rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li span i:before {
  font-size: 5rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li span i:before {
    font-size: 2.6rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
  font-size: 4rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li {
    color: #FFF;
    margin: 0rem 0rem 0rem 0rem;
    font-size: 1.2rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li:nth-of-type(1) {
  font-size: 3.6rem;
  font-family: "Arsenal-Bold";
  color: #bc913e;
  text-align: center;
  margin: 0rem 0rem 5rem 0rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li:nth-of-type(1) {
    font-size: 1.4rem;
    color: #FFF;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li:nth-of-type(6) {
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li:nth-of-type(6) {
    margin: 0rem 0rem 0rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li:last-of-type {
  font-size: 1.2rem;
  text-align: center;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .modal-body .content-spec .content-interaction ul li:last-of-type {
    display: none;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .block-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}
.modal-landing.modal-landing--casa-etapa-01 .block-nav .nav.nav-tabs {
  width: 100%;
}
.modal-landing.modal-landing--casa-etapa-01 .block-nav .nav.nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.modal-landing.modal-landing--casa-etapa-01 .block-nav .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Bold";
  font-size: 1.6rem;
  background: #000;
  color: #FFF;
  padding: 1.5rem 0rem 1.2rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  border-top: 0.5rem solid transparent;
  border-image-source: #000;
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .block-nav .nav.nav-tabs .nav-item .nav-link {
    margin: 0rem 0rem 0rem 0rem;
    color: #FFF;
    font-size: 1.8rem;
    padding: 1rem 0rem 0.6rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .block-nav .nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-radius: 0rem;
  background: #FFF;
  color: #000;
  border-top: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
.modal-landing.modal-landing--casa-etapa-01 .block-nav .nav-tabs {
  border-bottom: none;
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content {
  position: relative;
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane {
  position: relative;
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .nav.nav-tabs {
  width: 100%;
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .nav.nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Bold";
  font-size: 3.6rem;
  background: #000;
  color: #FFF;
  padding: 1rem 0rem 0.7rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  border-bottom: 0.5rem solid transparent;
  border-image-source: #000;
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link {
    border: 0.2rem solid transparent;
    border-image-source: #000;
    border-image-slice: 1;
    font-family: "Helvetica-Regular";
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-radius: 0rem;
  background: #FFF;
  color: #000;
  border-bottom: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.active {
    border: 0.2rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
    font-family: "Helvetica-Bold";
    font-size: 1.4rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .content-tab {
  display: block;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .content-tab {
    display: grid;
    grid-template-columns: 1fr;
  }
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .content-tab .block-nav {
    grid-row: 1/2;
    padding: 1rem 3.5rem 0rem 3.5rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador {
  width: 1.9rem;
  height: 1.9rem;
  background: url("./../../build/img/landing/indicador-tooltip.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
}
@media (max-width: 380px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador:hover {
  transform: scale(1.1);
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-01 {
  left: 23%;
  top: 55%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-01 {
    left: 30%;
    top: 20%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-02 {
  left: 55%;
  top: 50%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-02 {
    left: 42%;
    top: 50%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-03 {
  left: 65%;
  top: 15%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-03 {
    left: 80%;
    top: 65%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-04 {
  left: 75%;
  top: 60%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-01-04 {
    left: 35%;
    top: 80%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-01 {
  left: 22%;
  top: 47%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-01 {
    left: 47%;
    top: 20%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-02 {
  left: 37%;
  top: 47%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-02 {
    left: 47%;
    top: 35%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-03 {
  left: 55%;
  top: 60%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-03 {
    left: 32%;
    top: 55%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-04 {
  left: 65%;
  top: 45%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-a-02-04 {
    left: 37%;
    top: 75%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-01 {
  left: 11%;
  top: 67%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-01 {
    left: 27%;
    top: 8%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-02 {
  left: 27%;
  top: 50%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-02 {
    left: 45%;
    top: 27%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-03 {
  left: 52%;
  top: 47%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-03 {
    left: 48%;
    top: 52%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-04 {
  left: 82%;
  top: 59%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-04 {
    left: 35%;
    top: 83%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-05 {
  left: 73%;
  top: 11%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-01-05 {
    left: 83%;
    top: 75%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-01 {
  left: 26%;
  top: 48%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-01 {
    left: 46%;
    top: 26%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-02 {
  left: 61%;
  top: 47%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-02 {
    left: 46%;
    top: 63%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-03 {
  left: 61%;
  top: 40%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-03 {
    left: 54%;
    top: 63%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-04 {
  left: 55%;
  top: 67%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-04 {
    left: 27%;
    top: 57%;
  }
}
.modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-05 {
  left: 77%;
  top: 40%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-01 .tab-content .tab-pane .indicador.item-b-02-05 {
    left: 54%;
    top: 78%;
  }
}

.modal-landing.modal-landing--casa-etapa-02 .modal-dialog {
  max-width: 1350px;
  margin: 0rem auto 0rem auto;
}
@media (max-width: 1560px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-dialog {
    max-width: 1150px;
  }
}
@media (max-width: 1270px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-dialog {
    max-width: 85%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body {
  display: grid;
  grid-template-columns: 65% 35%;
  padding: 3.5rem 0rem 3.5rem 8rem;
}
@media (max-width: 991px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body {
    padding: 3.5rem 0rem 3.5rem 2rem;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body {
    grid-template-columns: 1fr;
    padding: 0rem 0rem 0rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction {
  padding: 0rem 6rem 0rem 3.5rem;
}
@media (max-width: 991px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction {
    padding: 1rem 6rem 1rem 2.5rem;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction {
    padding: 1rem 1rem 1rem 1rem;
    background: #000;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1rem;
  margin: 0rem 0rem 3rem 0rem;
}
@media (max-width: 480px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li {
    flex-direction: column;
    text-align: center;
    gap: 0.2rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span {
  width: 8rem;
  text-align: center;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span {
    width: 4rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span i:before {
  font-size: 5rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span i:before {
    font-size: 2.6rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
  font-size: 4rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
    font-size: 2.4rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span .icon-spec-pasillo:before {
  font-size: 3.6rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li span .icon-spec-pasillo:before {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li {
    color: #FFF;
    margin: 0rem 0rem 0rem 0rem;
    font-size: 1.2rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li:nth-of-type(1) {
  font-size: 3.6rem;
  font-family: "Arsenal-Bold";
  color: #bc913e;
  text-align: center;
  margin: 0rem 0rem 5rem 0rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li:nth-of-type(1) {
    font-size: 1.4rem;
    color: #FFF;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li:nth-of-type(6) {
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li:nth-of-type(6) {
    margin: 0rem 0rem 0rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li:last-of-type {
  font-size: 1.2rem;
  text-align: center;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .modal-body .content-spec .content-interaction ul li:last-of-type {
    display: none;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .content-tab {
  display: block;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .content-tab {
    display: grid;
    grid-template-columns: 1fr;
  }
  .modal-landing.modal-landing--casa-etapa-02 .content-tab .block-nav {
    grid-row: 1/2;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .tab-pane {
  position: relative;
}
.modal-landing.modal-landing--casa-etapa-02 .indicador {
  width: 1.9rem;
  height: 1.9rem;
  background: url("./../../build/img/landing/indicador-tooltip.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
}
@media (max-width: 380px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador:hover {
  transform: scale(1.1);
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-01 {
  left: 15%;
  top: 20%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-01 {
    left: 21%;
    top: 85%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-02 {
  left: 39%;
  top: 20%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-02 {
    left: 21%;
    top: 58%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-03 {
  left: 39%;
  top: 35%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-03 {
    left: 35%;
    top: 58%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-04 {
  left: 55%;
  top: 27%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-04 {
    left: 28%;
    top: 41%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-05 {
  left: 92%;
  top: 3%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-05 {
    left: 3%;
    top: 5%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-06 {
  left: 80%;
  top: 27%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-06 {
    left: 25%;
    top: 20%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-07 {
  left: 39%;
  top: 70%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-07 {
    left: 65%;
    top: 58%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-08 {
  left: 51%;
  top: 88%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-08 {
    left: 88%;
    top: 45%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-09 {
  left: 63%;
  top: 66%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-01-09 {
    left: 70%;
    top: 25%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-01 {
  left: 25.5%;
  top: 33%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-01 {
    left: 33%;
    top: 70%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-02 {
  left: 60%;
  top: 40%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-02 {
    left: 40%;
    top: 45%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-03 {
  left: 72%;
  top: 35%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-03 {
    left: 36%;
    top: 26%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-04 {
  left: 23%;
  top: 90%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-04 {
    left: 90%;
    top: 73%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-05 {
  left: 45%;
  top: 75%;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .indicador.item-c-02-05 {
    left: 70%;
    top: 45%;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .block-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}
.modal-landing.modal-landing--casa-etapa-02 .block-nav .nav.nav-tabs {
  width: 100%;
}
.modal-landing.modal-landing--casa-etapa-02 .block-nav .nav.nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.modal-landing.modal-landing--casa-etapa-02 .block-nav .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Bold";
  font-size: 3.6rem;
  background: #000;
  color: #FFF;
  padding: 1rem 0rem 0.7rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  border-bottom: 0.5rem solid transparent;
  border-image-source: #000;
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--casa-etapa-02 .block-nav .nav.nav-tabs .nav-item .nav-link {
    font-family: "Arsenal-Bold";
    margin: 0rem 0rem 0rem 0rem;
    color: #FFF;
    font-size: 1.8rem;
    padding: 1rem 0rem 0.6rem 0rem;
  }
}
.modal-landing.modal-landing--casa-etapa-02 .block-nav .nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-radius: 0rem;
  background: #FFF;
  color: #000;
  border-bottom: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
.modal-landing.modal-landing--casa-etapa-02 .block-nav .nav-tabs {
  border-bottom: none;
}

.modal-landing.modal-landing--tyc .modal-dialog {
  position: relative;
  max-width: 95%;
  margin-top: 0rem;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--tyc .modal-dialog {
    max-width: 90%;
    margin: 0rem auto 0rem auto;
  }
}
.modal-landing.modal-landing--tyc .modal-body {
  padding: 7rem 1rem 1rem 2rem;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--tyc .modal-body {
    padding: 5rem 0.5rem 1rem 1.5rem;
  }
}
.modal-landing.modal-landing--tyc .modal-body .content-terminos-condiciones {
  padding: 0rem 0rem 0rem 0rem;
  overflow-y: scroll;
  max-height: 580px;
}
@media (max-width: 767px) {
  .modal-landing.modal-landing--tyc .modal-body .content-terminos-condiciones {
    max-height: 430px;
  }
}

#sliderCaracteristicasCondominio.carousel .carousel-inner {
  position: relative;
  min-height: 17.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#sliderCaracteristicasCondominio.carousel .btn-left-slide,
#sliderCaracteristicasCondominio.carousel .btn-right-slide {
  font-size: 4rem;
  opacity: 0.7;
}
#sliderCaracteristicasCondominio.carousel .btn-left-slide:hover,
#sliderCaracteristicasCondominio.carousel .btn-right-slide:hover {
  opacity: 1;
}
#sliderCaracteristicasCondominio.carousel .btn-left-slide {
  position: absolute;
  left: 0;
  bottom: 45%;
}
#sliderCaracteristicasCondominio.carousel .btn-right-slide {
  position: absolute;
  right: 0;
  bottom: 45%;
}

#sliderTestimoniales.carousel .carousel-inner {
  position: relative;
  min-height: 45rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#sliderTestimoniales.carousel .btn-left-slide,
#sliderTestimoniales.carousel .btn-right-slide {
  font-size: 4rem;
  color: #112334;
  opacity: 0.7;
}
#sliderTestimoniales.carousel .btn-left-slide:hover,
#sliderTestimoniales.carousel .btn-right-slide:hover {
  opacity: 1;
}
#sliderTestimoniales.carousel .btn-left-slide {
  position: absolute;
  left: 0;
  bottom: 45%;
}
@media (max-width: 767px) {
  #sliderTestimoniales.carousel .btn-left-slide {
    bottom: 0;
    top: 30%;
  }
}
#sliderTestimoniales.carousel .btn-right-slide {
  position: absolute;
  right: 0;
  bottom: 45%;
}
@media (max-width: 767px) {
  #sliderTestimoniales.carousel .btn-right-slide {
    bottom: 0;
    top: 30%;
  }
}

#sliderCondominio.carousel .carousel-inner {
  position: relative;
  min-height: 300px;
}
@media (max-width: 767px) {
  #sliderCondominio.carousel .carousel-inner {
    height: auto;
  }
}
#sliderCondominio.carousel .btn-left-slide,
#sliderCondominio.carousel .btn-right-slide {
  font-size: 4rem;
  color: #112334;
  opacity: 0.7;
}
#sliderCondominio.carousel .btn-left-slide:hover,
#sliderCondominio.carousel .btn-right-slide:hover {
  opacity: 1;
}
@media (max-width: 991px) {
  #sliderCondominio.carousel .btn-left-slide,
#sliderCondominio.carousel .btn-right-slide {
    font-size: 3.6rem;
  }
}

.slider-galeria-etapa {
  position: relative;
}
.slider-galeria-etapa .carousel-indicators {
  position: static;
  margin-top: 1.5rem;
}
.slider-galeria-etapa .carousel-indicators li {
  height: 1rem;
  width: 4rem;
  border: none;
  border-radius: 0rem;
}
.slider-galeria-etapa .carousel-indicators li.active {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
}
.slider-galeria-etapa .control-slide-prev {
  position: absolute;
  left: 5%;
  bottom: 45%;
  opacity: 0.7;
}
.slider-galeria-etapa .control-slide-prev:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .slider-galeria-etapa .control-slide-prev {
    left: 2.5%;
  }
}
.slider-galeria-etapa .control-slide-prev span i:before {
  font-size: 5rem;
  color: #FFF;
}
@media (max-width: 767px) {
  .slider-galeria-etapa .control-slide-prev span i:before {
    font-size: 3.6rem;
  }
}
.slider-galeria-etapa .control-slide-next {
  position: absolute;
  right: 5%;
  bottom: 45%;
  opacity: 0.7;
}
.slider-galeria-etapa .control-slide-next:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .slider-galeria-etapa .control-slide-next {
    right: 2.5%;
  }
}
.slider-galeria-etapa .control-slide-next span i:before {
  font-size: 5rem;
  color: #FFF;
}
@media (max-width: 767px) {
  .slider-galeria-etapa .control-slide-next span i:before {
    font-size: 3.6rem;
  }
}

.slider-galeria-specs-condominio .control-slide-prev {
  position: absolute;
  left: 5%;
  bottom: 45%;
  opacity: 0.7;
}
.slider-galeria-specs-condominio .control-slide-prev:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .slider-galeria-specs-condominio .control-slide-prev {
    left: 2.5%;
  }
}
.slider-galeria-specs-condominio .control-slide-prev span i:before {
  font-size: 5rem;
  color: #FFF;
}
@media (max-width: 767px) {
  .slider-galeria-specs-condominio .control-slide-prev span i:before {
    font-size: 3.6rem;
  }
}
.slider-galeria-specs-condominio .control-slide-next {
  position: absolute;
  right: 5%;
  bottom: 45%;
  opacity: 0.7;
}
.slider-galeria-specs-condominio .control-slide-next:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .slider-galeria-specs-condominio .control-slide-next {
    right: 2.5%;
  }
}
.slider-galeria-specs-condominio .control-slide-next span i:before {
  font-size: 5rem;
  color: #FFF;
}
@media (max-width: 767px) {
  .slider-galeria-specs-condominio .control-slide-next span i:before {
    font-size: 3.6rem;
  }
}
.slider-galeria-specs-condominio .carousel-item .block {
  background: #FFF;
  max-width: 210px;
  margin: 0 auto;
}
.slider-galeria-specs-condominio .carousel-item h5 {
  padding: 0.5rem 0.5rem 0.3rem 0.5rem;
  text-align: center;
}

/* HEADER */
.identidad-visual {
  max-width: 200px;
}
@media (max-width: 1270px) {
  .identidad-visual {
    max-width: 145px;
  }
}
@media (max-width: 991px) {
  .identidad-visual {
    margin-left: 3rem;
  }
}

@keyframes opacity-icon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.icon-transition {
  animation-name: opacity-icon;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

.header {
  padding: 1.5rem 0rem;
  box-shadow: 0rem 0rem 1.5rem #e5e5e5;
  background: #FFF;
  position: fixed;
  top: 0rem;
  z-index: 1100;
  width: 100%;
}
@media (max-width: 767px) {
  .header {
    padding: 2.2rem 0rem;
  }
}
@media (max-width: 991px) {
  .header.active {
    height: 100vh;
  }
}
.header .navbar {
  padding: 0rem 0rem;
}
.header .navbar-expand-lg {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
}
.header .navbar-expand-lg .navbar-toggler {
  margin-right: 3rem;
  padding: 0rem;
  outline: none;
}
.header .navbar-expand-lg .navbar-toggler span i:before {
  font-size: 3rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 1;
}
.header .navbar-expand-lg .navbar-collapse {
  flex-grow: unset;
}
.header .navbar-expand-lg .navbar-collapse .navbar-nav {
  align-items: center;
  gap: 0rem 0.7rem;
}
@media (max-width: 1270px) {
  .header .navbar-expand-lg .navbar-collapse .navbar-nav {
    gap: 0rem 0.5rem;
  }
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .navbar-nav {
    box-shadow: inset 0rem 0.5rem 1.5rem #e5e5e5;
    margin-top: 1.5rem;
  }
}
@media (max-width: 767px) {
  .header .navbar-expand-lg .navbar-collapse .navbar-nav {
    margin-top: 2.2rem;
  }
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item {
    display: block;
    width: 100%;
    text-align: center;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link {
  color: #112334;
  font-family: "Arsenal-Bold";
  padding: 0.6rem 1.8rem 0.6rem 1.8rem;
  border-bottom: 0.2rem solid transparent;
}
@media (max-width: 1270px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link {
    font-size: 1.8rem;
    padding: 0.6rem 1.5rem 0.6rem 1.5rem;
  }
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link {
    padding: 2.5rem 3rem 2.5rem 3rem;
    font-family: "Arsenal-Regular";
    font-size: 2.6rem;
    border-bottom: 1px solid #e5e5e5;
    margin: 0rem 3rem;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link:hover {
  color: #bc913e;
  transition: ease-in-out 0.3s;
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.active {
  color: #112334;
  border-bottom: 0.2rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.active {
    border-bottom: 0.5rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs {
    padding: 2.5rem 3rem 2.5rem 3rem;
  }
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs:hover {
    color: #112334;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs:hover .faqs {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  transition: ease-in-out 0.3s;
  color: #112334;
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs:hover .faqs {
    background: #112334;
    color: #FFF;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs.active {
  border: none;
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs.active .faqs {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs.active .faqs {
    background: #112334;
    color: #FFF;
  }
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs.active {
    border-bottom: 0.5rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
    color: #112334;
  }
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs.active:hover .faqs {
    background: #112334;
    color: #FFF;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs .faqs {
  padding: 0.5rem 1.2rem;
  border-radius: 1.75rem;
  background: #112334;
  color: #FFF;
  margin: 0rem 3.5rem 0rem 3.5rem;
}
@media (max-width: 1270px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs .faqs {
    margin: 0.5rem 2.5rem 0.5rem 2.5rem;
    padding: 0.4rem 1rem;
  }
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.faqs .faqs {
    margin: 0.5rem 1.2rem 0.5rem 0rem;
    padding: 0.4rem 1.3rem;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.contacto {
  color: #112334;
  font-size: 2.6rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  padding: 1.4rem 3rem 1.4rem 3rem;
  font-family: "Arsenal-Bold";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0rem 1.5rem;
}
@media (max-width: 1270px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.contacto {
    font-size: 2.6rem;
    padding: 1rem 2.5rem 1rem 2.5rem;
    margin: 1rem 0rem 1rem 0rem;
  }
}
@media (max-width: 991px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.contacto {
    margin: 2.5rem 2rem 1.5rem 2rem;
  }
}
@media (max-width: 767px) {
  .header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.contacto {
    margin: 2.5rem 2rem 0.8rem 2rem;
  }
}
.header .navbar-expand-lg .navbar-collapse .nav-item .nav-link.contacto:hover {
  color: #112334;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  transition: ease-in-out 0.3s;
}

.section-caracteristicas-condominio {
  padding: 0rem 0rem 9rem 0rem;
}
.section-caracteristicas-condominio.etapa-1 .box {
  border-top: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 5rem 0rem 0rem 0rem;
}
.section-caracteristicas-condominio.etapa-1 .box h2:nth-of-type(1) {
  text-align: center;
  color: #bc913e;
  margin: 0rem 0rem 6rem 0rem;
}
.section-caracteristicas-condominio.etapa-1 .box h2:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .section-caracteristicas-condominio.etapa-1 .box {
    padding: 0rem 0rem 0rem 0rem;
    border: none;
  }
}
.section-caracteristicas-condominio.etapa-1 .content-block:nth-of-type(1) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
}
@media (max-width: 991px) {
  .section-caracteristicas-condominio.etapa-1 .content-block:nth-of-type(1) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }
}
@media (max-width: 767px) {
  .section-caracteristicas-condominio.etapa-1 .content-block:nth-of-type(1) {
    grid-template-columns: 1fr;
  }
}
.section-caracteristicas-condominio.etapa-1 .content-block:nth-of-type(1) .item-box {
  text-align: center;
  border-top: 0.2rem solid transparent;
  border-right: 0.2rem solid transparent;
  border-bottom: 0.2rem solid transparent;
  border-left: 0.2rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-1 .content-block:nth-of-type(1) .item-box span i:before {
  color: #bc913e;
  font-size: 4rem;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-1 .content-block:nth-of-type(1) .item-box h6:nth-of-type(1) {
  color: #bc913e;
}
.section-caracteristicas-condominio.etapa-1 .carousel-inner .item-box {
  text-align: center;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-1 .carousel-inner .item-box span i:before {
  color: #bc913e;
  font-size: 5rem;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-1 .carousel-inner .item-box h6:nth-of-type(1) {
  color: #bc913e;
}
.section-caracteristicas-condominio.etapa-1 .btn-left-slide,
.section-caracteristicas-condominio.etapa-1 .btn-right-slide {
  color: #bc913e;
}
.section-caracteristicas-condominio.etapa-2 .box {
  border-top: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 5rem 0rem 0rem 0rem;
}
.section-caracteristicas-condominio.etapa-2 .box h2:nth-of-type(1) {
  text-align: center;
  color: #112334;
  margin: 0rem 0rem 6rem 0rem;
}
.section-caracteristicas-condominio.etapa-2 .box h2:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .section-caracteristicas-condominio.etapa-2 .box {
    padding: 0rem 0rem 0rem 0rem;
    border: none;
  }
}
.section-caracteristicas-condominio.etapa-2 .content-block:nth-of-type(1) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
}
@media (max-width: 991px) {
  .section-caracteristicas-condominio.etapa-2 .content-block:nth-of-type(1) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }
}
@media (max-width: 767px) {
  .section-caracteristicas-condominio.etapa-2 .content-block:nth-of-type(1) {
    grid-template-columns: 1fr;
  }
}
.section-caracteristicas-condominio.etapa-2 .content-block:nth-of-type(1) .item-box {
  text-align: center;
  border-top: 0.2rem solid transparent;
  border-right: 0.2rem solid transparent;
  border-bottom: 0.2rem solid transparent;
  border-left: 0.2rem solid transparent;
  border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-image-slice: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-2 .content-block:nth-of-type(1) .item-box span i:before {
  color: #112334;
  font-size: 4rem;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-2 .content-block:nth-of-type(1) .item-box h6:nth-of-type(1) {
  color: #112334;
}
.section-caracteristicas-condominio.etapa-2 .carousel-inner .item-box {
  text-align: center;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-2 .carousel-inner .item-box span i:before {
  color: #112334;
  font-size: 5rem;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-caracteristicas-condominio.etapa-2 .carousel-inner .item-box h6:nth-of-type(1) {
  color: #112334;
}
.section-caracteristicas-condominio.etapa-2 .btn-left-slide,
.section-caracteristicas-condominio.etapa-2 .btn-right-slide {
  color: #112334;
}

.section-como-llegar {
  padding: 0rem 0rem 0rem 0rem;
}
.section-como-llegar .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-como-llegar .content-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 7.5rem;
  background: #000;
  padding: 5rem 8.33% 5rem 8.33%;
}
@media (max-width: 991px) {
  .section-como-llegar .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
    border-bottom: 0.6rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
  }
}
.section-como-llegar .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #FFF;
  margin: 0rem 0rem 1rem 0rem;
}
@media (max-width: 991px) {
  .section-como-llegar .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
    text-align: center;
    margin: 0rem 0rem 3rem 0rem;
  }
}
.section-como-llegar .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
  color: #FFF;
}
@media (max-width: 991px) {
  .section-como-llegar .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
    text-align: center;
    font-size: 1.8rem;
  }
}
.section-como-llegar .content-block .item-box:nth-of-type(1) p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
.section-como-llegar .content-block .item-box:nth-of-type(1) p:nth-of-type(1) span i:before {
  font-size: 2.6rem;
}
.section-como-llegar .content-block .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 2rem;
}
@media (max-width: 991px) {
  .section-como-llegar .content-block .item-box:nth-of-type(2) {
    flex-direction: column;
    gap: 2rem 0rem;
  }
}
.section-como-llegar .content-block .item-box:nth-of-type(2) p:nth-of-type(1),
.section-como-llegar .content-block .item-box:nth-of-type(2) p:nth-of-type(2) {
  display: block;
  width: 100%;
  text-align: center;
}

.section-testimoniales {
  padding: 8rem 0rem 6rem 0rem;
}
@media (max-width: 767px) {
  .section-testimoniales {
    padding: 6rem 0rem 0rem 0rem;
  }
}
.section-testimoniales .block-content {
  width: 85%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 4rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 0rem 0rem 7rem 0rem;
}
@media (max-width: 767px) {
  .section-testimoniales .block-content {
    width: 100%;
    grid-template-columns: 1fr;
    column-gap: 0rem;
    row-gap: 2rem;
    padding: 0rem 0rem 5rem 0rem;
  }
}
.section-testimoniales .block-content .item-box:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem 0rem;
}
.section-testimoniales .block-content .item-box:nth-of-type(1) h2:nth-of-type(1) {
  text-align: center;
  color: #bc913e;
  margin: 0rem 0rem 2.5rem 0rem;
}
.section-testimoniales .block-content .item-box:nth-of-type(1) h4:nth-of-type(1) {
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-testimoniales .block-content .item-box:nth-of-type(1) h4:nth-of-type(1) {
    font-size: 2.6rem;
  }
}
.section-testimoniales .block-content .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 3rem 0rem;
}
.section-testimoniales .block-content .item-box:nth-of-type(2) h2:nth-of-type(1) {
  text-align: center;
  color: #bc913e;
}
.section-testimoniales .block-content .item-box:nth-of-type(2) p:nth-of-type(1) {
  text-align: center;
}
@media (max-width: 767px) {
  .section-testimoniales .block-content .item-box:nth-of-type(2) p:nth-of-type(1) {
    font-size: 1.4rem;
  }
}

.section-planes-financiacion {
  padding: 0rem 0rem 11rem 0rem;
}
@media (max-width: 767px) {
  .section-planes-financiacion {
    padding: 5rem 0rem 6rem 0rem;
  }
}
.section-planes-financiacion .padding-alterno {
  padding-left: 0rem;
}
@media (max-width: 767px) {
  .section-planes-financiacion .padding-alterno {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.section-planes-financiacion .content-block {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  column-gap: 7.5rem;
}
@media (max-width: 991px) {
  .section-planes-financiacion .content-block {
    grid-template-columns: 1fr;
  }
}
.section-planes-financiacion .content-block .item-box:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) {
  color: #bc913e;
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 991px) {
  .section-planes-financiacion .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) {
    margin: 4rem 0rem 4rem 0rem;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .section-planes-financiacion .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
  margin: 0rem 0rem 3.5rem 0rem;
}
@media (max-width: 767px) {
  .section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 991px) {
  .section-planes-financiacion .content-block .item-box:nth-of-type(2) img:nth-of-type(1) {
    margin: 0rem 0rem 5rem 0rem;
  }
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(2),
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(3),
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(4) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1.5rem;
  margin: 0rem 0rem 4rem 0rem;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(2),
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(3),
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(4) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.4rem;
  }
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(2) span i:before,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(3) span i:before,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(4) span i:before {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45px;
  color: #bc913e;
  font-size: 4rem;
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(2) span a,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(3) span a,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(4) span a {
  font-size: 1.6rem;
  text-decoration: underline;
  color: #112334;
}
@media (max-width: 767px) {
  .section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(2) span a,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(3) span a,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(4) span a {
    font-size: 1.4rem;
  }
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(2) span a:focus,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(3) span a:focus,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(4) span a:focus {
  color: #bc913e;
  text-decoration: underline;
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(2) span a:hover,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(3) span a:hover,
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:nth-of-type(4) span a:hover {
  color: #bc913e;
  text-decoration: underline;
}
.section-planes-financiacion .content-block .item-box:nth-of-type(2) p:last-of-type {
  margin: 0rem 0rem 0rem 0rem;
}

.section-form {
  background: #000;
  padding: 0rem 0rem 0rem 0rem;
  border-top: 0.6rem solid transparent;
  border-bottom: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  /* PRISTINE IMPLEMENTATION */
}
.section-form .padding-alterno {
  padding-right: 0rem;
}
@media (max-width: 767px) {
  .section-form .padding-alterno {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.section-form .content-block {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  column-gap: 7.5rem;
}
@media (max-width: 991px) {
  .section-form .content-block {
    grid-template-columns: 1fr;
  }
}
.section-form .content-block .item-box:nth-of-type(2) {
  background: url("./../../build/img/casa-fachada-posterior-piscina-formulario-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.section-form .formulario {
  padding: 5rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .section-form .formulario {
    padding: 5rem 1.5rem 4rem 1.5rem;
  }
}
.section-form .formulario h3:nth-of-type(1) {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  font-size: 2.4rem;
  -webkit-background-clip: text;
  color: transparent;
  opacity: 1;
  margin: 0rem 0rem 2rem 0rem;
}
@media (max-width: 767px) {
  .section-form .formulario h3:nth-of-type(1) {
    font-size: 2.6rem;
    text-align: center;
  }
}
.section-form .formulario .descripcion {
  color: #FFF;
  margin: 0rem 0rem 3rem 0rem;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .section-form .formulario .descripcion {
    font-size: 1.6rem;
  }
}
.section-form .formulario .form-group {
  margin-bottom: 1rem;
}
.section-form .formulario .form-group.submit {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  column-gap: 1.5rem;
}
@media (max-width: 767px) {
  .section-form .formulario .form-group.submit {
    grid-template-columns: 1fr;
  }
  .section-form .formulario .form-group.submit .pristine-error {
    grid-row: 2/3;
    margin: 0.5rem 0rem 1.25rem 1rem;
  }
  .section-form .formulario .form-group.submit .btn-submit {
    margin: 3rem 0rem 0rem 0rem;
  }
}
.section-form .formulario .form-control {
  display: block;
  width: 100%;
  height: 3.3rem;
  padding: 0.3rem 2rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #112334;
  background-color: #fff;
  background-clip: padding-box;
  border: 0.1rem solid #bc913e;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.section-form .formulario .item-checbox {
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1.5rem;
}
.section-form .formulario .item-checbox p:nth-of-type(1) {
  color: #FFF;
  font-size: 1rem;
}
.section-form .formulario .item-checbox p:nth-of-type(1) span a {
  color: #FFF;
  font-size: 1rem;
  text-decoration: underline;
}
.section-form .pristine-error.text-help {
  font-size: 1rem;
  color: #FFF;
  margin: 0.5rem 0rem 0rem 1rem;
}
.section-form .input-error {
  font-size: 1rem;
  color: #FFF;
  margin: 0.5rem 0rem 0rem 1rem;
}

@keyframes transicion-envio {
  0% {
    transform: scale(initial);
  }
  20% {
    transform: scale(0.9);
  }
  35% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.25);
    opacity: 1;
  }
  90% {
    transform: scale(0.25);
  }
  100% {
    opacity: 0;
  }
}
.animacion-form {
  animation-name: transicion-envio;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  opacity: 1;
}

@keyframes opacidad-contenido {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.suavizar-opacidad {
  animation-name: opacidad-contenido;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.spinner-border.loader-form {
  color: #bc913e;
  width: 60px;
  height: 60px;
  margin-top: 15px;
}

@media screen and (max-width: 575px) {
  .spinner-border.loader-form {
    margin-top: 35px;
  }
}
.procesando-datos {
  min-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tx-seccion-form-send {
  color: #FFF;
  font-size: 1.6rem;
  text-align: center;
  margin: 20px 0px 20px 0px;
}
@media screen and (max-width: 991px) {
  .tx-seccion-form-send {
    font-size: 1.4rem;
    margin: 20px 0px 100px 0px;
  }
}

.tx-seccion-form-send-chatbot {
  color: #000;
  font-size: 1.4rem;
  text-align: center;
  margin: 20px 0px 20px 0px;
}

/* FOOTER */
.footer {
  padding: 3rem 0rem 3rem 0rem;
}
.footer .block-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  column-gap: 3rem;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .footer .block-footer {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
}
.footer .block-footer .content-block:nth-child(1) p {
  text-align: right;
  color: #112334;
  font-size: 1rem;
}
@media (max-width: 991px) {
  .footer .block-footer .content-block:nth-child(1) p {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(1) p {
    text-align: center;
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(1) {
    grid-row: 1/2;
  }
}
.footer .block-footer .content-block:nth-child(2) {
  display: flex;
  align-items: center;
}
.footer .block-footer .content-block:nth-child(2) h4:nth-child(1) {
  color: #bc913e;
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(2) h4:nth-child(1) {
    text-align: center;
  }
}
.footer .block-footer .content-block:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem 0rem;
}
.footer .block-footer .content-block:nth-child(3) p:nth-child(1) {
  color: #112334;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(3) p:nth-child(1) {
    text-align: center;
  }
}
.footer .block-footer .content-block:nth-child(3) .item-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 3rem;
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(3) .item-box {
    flex-direction: column;
    align-items: center;
    gap: 2rem 0rem;
  }
}
.footer .block-footer .content-block:nth-child(3) .item-box p:nth-child(1) {
  color: #112334;
  font-size: 1rem;
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(3) .item-box p:nth-child(1) {
    text-align: center;
    font-size: 1.4rem;
  }
}
.footer .block-footer .content-block:nth-child(3) .item-box ul {
  display: flex;
  flex-direction: row;
  gap: 0rem 2rem;
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(3) .item-box ul {
    gap: 0rem 3rem;
  }
}
.footer .block-footer .content-block:nth-child(3) .item-box ul span i:before {
  color: #898989;
  font-size: 2.9rem;
}
@media (max-width: 767px) {
  .footer .block-footer .content-block:nth-child(3) .item-box ul span i:before {
    font-size: 4.4rem;
  }
}
.footer .block-footer .content-block:nth-child(3) .item-box ul span i:hover:before {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 1;
}

.main-home .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-home .content-block {
  padding: 3.5rem 8.33% 4rem 8.33%;
  background: #000;
  border-top: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .main-home .content-block {
    border-top: none;
    padding: 3.5rem 1.5rem 4rem 1.5rem;
  }
}
.main-home h1:nth-of-type(1) {
  color: #FFF;
  font-family: "Arsenal-Bold";
  text-align: center;
  line-height: 1.5;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .main-home h1:nth-of-type(1) {
    line-height: 1.3;
    border-bottom: none;
    padding: 0rem 0rem 4rem 0rem;
  }
}
.main-home h1:nth-of-type(1) span {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #000;
  padding: 0rem 7rem;
}
@media (max-width: 767px) {
  .main-home h1:nth-of-type(1) span {
    padding: 0rem 0.25rem;
  }
}
.main-home h3:nth-of-type(1) {
  padding: 5rem 0rem 0rem 0rem;
  font-family: "Arsenal-Regular";
  color: #FFF;
  text-align: center;
}
.main-home h3:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .main-home h3:nth-of-type(1) {
    padding: 0rem 0rem 0rem 0rem;
  }
}

.section-home-01 {
  padding: 0rem 0rem 8rem 0rem;
}
@media (max-width: 767px) {
  .section-home-01 {
    padding: 0rem 0rem 5rem 0rem;
  }
}
.section-home-01 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-home-01 .content-block.head {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.section-home-01 .content-block.head .item-box:nth-of-type(1) {
  height: 440px;
  background: url("./../../build/img/casa-campestre-etapa-uno-home-altos-del-palmar.webp");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section-home-01 .content-block.head .item-box:nth-of-type(1) h2:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #FFF;
  text-align: center;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
  font-size: 4rem;
  padding: 1rem 1.5rem;
}
@media (max-width: 767px) {
  .section-home-01 .content-block.head .item-box:nth-of-type(1) h2:nth-of-type(1) {
    font-size: 3.2rem;
  }
}
.section-home-01 .content-block.head .item-box:nth-of-type(1) h4:nth-of-type(1) {
  background: rgba(0, 0, 0, 0.8);
  color: #bc913e;
  border-bottom: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 1rem 1.5rem;
  margin: 0rem 4.5rem 3rem 4.5rem;
  text-align: center;
  display: inline-block;
}
.section-home-01 .content-block.head .item-box:nth-of-type(1) h4:nth-of-type(1) span {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.section-home-01 .content-block.head .item-box:nth-of-type(2) {
  height: 440px;
  background: url("./../../build/img/casa-campestre-etapa-dos-home-altos-del-palmar.webp");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section-home-01 .content-block.head .item-box:nth-of-type(2) h2:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 4rem;
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  color: #FFF;
  padding: 1rem 1.5rem;
}
@media (max-width: 767px) {
  .section-home-01 .content-block.head .item-box:nth-of-type(2) h2:nth-of-type(1) {
    font-size: 3.2rem;
  }
}
.section-home-01 .content-block.head .item-box:nth-of-type(2) h4:nth-of-type(1) {
  background: rgba(255, 255, 255, 0.8);
  color: #112334;
  border-bottom: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-image-slice: 1;
  padding: 1rem 1.5rem;
  margin: 0rem 4.5rem 3rem 4.5rem;
  text-align: center;
  display: inline-block;
}
.section-home-01 .content-block.head .item-box:nth-of-type(2) h4:nth-of-type(1) span {
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  -webkit-background-clip: text;
  color: transparent;
}
.section-home-01 .block-section-06:nth-of-type(1) {
  padding: 0rem 0rem 0rem 0rem;
  display: grid;
}
.section-home-01 .block-section-06:nth-of-type(1) .content-block h2 {
  text-align: center;
  padding: 0rem 3rem 4rem 3rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-home-01 .block-section-06:nth-of-type(1) .content-block h2 {
    background: #FFF;
    color: #bc913e;
    padding: 6rem 8.3% 4rem 8.3%;
  }
}
.section-home-01 .mobile-content .block-nav-mobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.section-home-01 .mobile-content .block-nav-mobile .nav.nav-tabs {
  width: 100%;
}
.section-home-01 .mobile-content .block-nav-mobile .nav.nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.section-home-01 .mobile-content .block-nav-mobile .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  background: #E5E5E5;
  color: #112334;
  padding: 1rem 0rem 1rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
}
.section-home-01 .mobile-content .block-nav-mobile .nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-radius: 0rem;
}
.section-home-01 .mobile-content .block-nav-mobile .nav.nav-tabs .nav-item:nth-of-type(1) .nav-link.active {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
}
.section-home-01 .mobile-content .block-nav-mobile .nav.nav-tabs .nav-item:nth-of-type(2) .nav-link.active {
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  color: #FFF;
}
.section-home-01 .mobile-content .block-nav-mobile .nav-tabs {
  border-bottom: none;
}
.section-home-01 .mobile-content .tab-content {
  position: relative;
}
@media (max-width: 767px) {
  .section-home-01 .mobile-content .tab-content .content-block {
    padding: 0rem 0rem 6rem 0rem;
    border-bottom: 0.6rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
  }
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-1 {
  background: url("./../../build/img/casa-campestre-etapa-uno-xs-home-altos-del-palmar.webp");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  height: 250px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-1 .descripcion {
  display: block;
  width: 100%;
  text-align: center;
  color: #bc913e;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-1 .descripcion.etapa-1 {
  background: rgba(0, 0, 0, 0.8);
  padding: 1rem 1.5rem;
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-1 .descripcion.etapa-1 span {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-2 {
  background: url("./../../build/img/casa-campestre-etapa-dos-xs-home-altos-del-palmar.webp");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  height: 250px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-2 .descripcion {
  display: block;
  width: 100%;
  text-align: center;
  color: #112334;
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-2 .descripcion.etapa-2 {
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem 1.5rem;
}
.section-home-01 .mobile-content .tab-content .content-block .bg-etapa-2 .descripcion.etapa-2 span {
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  -webkit-background-clip: text;
  color: transparent;
}
.section-home-01 .mobile-content .tab-content .content-block .precio {
  font-size: 2.6rem;
  font-family: "Helvetica-Bold";
  text-align: center;
  padding: 1.5rem 0rem;
}
.section-home-01 .mobile-content .tab-content .content-block .precio.etapa-1 {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
}
.section-home-01 .mobile-content .tab-content .content-block .precio.etapa-2 {
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  color: #FFF;
}
.section-home-01 .mobile-content .tab-content .content-block .block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0rem 1.5rem;
  padding: 1.5rem 0rem 1.5rem 0rem;
  max-width: 280px;
  margin: 0 auto;
}
.section-home-01 .mobile-content .tab-content .content-block .block span {
  width: 7rem;
  text-align: center;
}
.section-home-01 .mobile-content .tab-content .content-block .block span i:before {
  color: #bc913e;
  font-size: 3.6rem;
}
.section-home-01 .mobile-content .tab-content .content-block .block .box p:nth-of-type(1) {
  color: #112334;
  font-size: 1.4rem;
  margin: 0rem 0rem 0.5rem 0rem;
  font-family: "Arsenal-Regular";
}
.section-home-01 .mobile-content .tab-content .content-block .block .box p:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
.section-home-01 .mobile-content .tab-content .content-block .block:nth-of-type(5) {
  padding: 2rem 0rem 4.5rem 0rem;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0rem 0rem 0rem 0rem;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(2),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(3),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(4),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(5) {
  padding: 0rem 8.33% 0rem 8.33%;
}
@media (max-width: 767px) {
  .section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(2),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(3),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(4),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(5) {
    padding: 0rem 1.5rem 0rem 1.5rem;
  }
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(2) ul,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(3) ul,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(4) ul,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(5) ul {
  display: grid;
  grid-template-columns: 1fr 15% 1fr;
  border-bottom: 0.1rem solid #E5E5E5;
  padding: 1.5rem 0rem 1.5rem 0rem;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(2) li,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(3) li,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(4) li,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(5) li {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem 0.5rem;
  font-family: "Arsenal-Regular";
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(2) li span,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(3) li span,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(4) li span,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(5) li span {
  font-family: "Arsenal-Bold";
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(2) li span i:before,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(3) li span i:before,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(4) li span i:before,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(5) li span i:before {
  font-size: 3.6rem;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(2) li:nth-of-type(2),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(3) li:nth-of-type(2),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(4) li:nth-of-type(2),
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(5) li:nth-of-type(2) {
  color: #bc913e;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(6) ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul li,
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(6) ul li {
  text-align: center;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul {
  margin: 0rem 0rem 2.5rem 0rem;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul li.etapa-1 {
  font-family: "Arsenal-Regular";
  padding: 1rem 1.5rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul li.etapa-1 h3:nth-of-type(1) {
  font-family: "Arsenal-Regular";
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul li.etapa-1 span {
  font-family: "Arsenal-Bold";
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul li.etapa-2 {
  font-family: "Arsenal-Regular";
  padding: 1rem 1.5rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-image-slice: 1;
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul li.etapa-2 h3:nth-of-type(1) {
  font-family: "Arsenal-Regular";
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(1) ul li.etapa-2 span {
  font-family: "Arsenal-Bold";
}
.section-home-01 .desktop-content .block-section-06:nth-of-type(1) .content-block:nth-of-type(6) ul {
  padding: 2.5rem 0rem 0rem 0rem;
}
.section-home-01 .description {
  margin: 8rem 0rem 0rem 0rem;
  padding: 0rem 1.5rem;
  font-family: "Arsenal-Regular";
  text-align: center;
}
.section-home-01 .description span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .section-home-01 .description {
    margin: 6rem 3rem 0rem 3rem;
    padding: 0rem 0rem 5rem 0rem;
    border-bottom: 0.3rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
  }
}

.section-home-02 {
  padding: 0rem 0rem 6.5rem 0rem;
}
.section-home-02 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-home-02 .border-section {
  width: 60%;
  height: 0.6rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  margin: 0rem 0rem 9rem 0rem;
}
.section-home-02 .content-block {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  column-gap: 5rem;
}
@media (max-width: 767px) {
  .section-home-02 .content-block {
    grid-template-columns: 1fr;
  }
}
.section-home-02 .content-block .item-box:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .section-home-02 .content-block .item-box:nth-of-type(1) {
    grid-row: 2/3;
  }
}
.section-home-02 .content-block .item-box:nth-of-type(1) .ubicacion-xs {
  margin-bottom: 3.5rem;
}
.section-home-02 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #bc913e;
  padding: 0rem 1.5rem 4rem 1.5rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .section-home-02 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
    text-align: center;
    border: none;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-home-02 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
  color: #112334;
  padding: 0rem 1.5rem 4rem 1.5rem;
}
.section-home-02 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
@media (max-width: 767px) {
  .section-home-02 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
    font-size: 1.8rem;
  }
}
.section-home-02 .content-block .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.section-home-02 .box {
  background: #000;
  padding: 5rem 4.5rem;
  border-bottom: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-home-02 .box h2:nth-of-type(1) {
  color: #FFF;
  margin: 0rem 0rem 2rem 0rem;
}
@media (max-width: 767px) {
  .section-home-02 .box h2:nth-of-type(1) {
    text-align: center;
  }
}
.section-home-02 .box h4:nth-of-type(1) {
  color: #FFF;
  margin: 0rem 0rem 2rem 0rem;
}
@media (max-width: 767px) {
  .section-home-02 .box h4:nth-of-type(1) {
    text-align: center;
  }
}
.section-home-02 .box p:nth-of-type(1) {
  color: #FFF;
  padding: 0rem 0rem 4rem 0rem;
}
.section-home-02 .box p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
@media (max-width: 767px) {
  .section-home-02 .box p:nth-of-type(1) {
    font-size: 1.8rem;
  }
}
.section-home-02 .box .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 2rem;
}
@media (max-width: 767px) {
  .section-home-02 .box .item {
    flex-direction: column;
    gap: 1.5rem 0rem;
  }
}
.section-home-02 .box .item a {
  color: #FFF;
  border: 0.1rem solid #FFF;
  font-family: "Arsenal-Bold";
  font-size: 2rem;
  padding: 0.7rem 1rem;
  display: inline-block;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.section-home-02 .box .item a:focus {
  color: #112334;
  background: #FFF;
}
.section-home-02 .box .item a:hover {
  color: #112334;
  background: #FFF;
}
.section-home-02 .box .item a span i:before {
  margin: 0rem 3rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-home-02 .box .item a {
    font-size: 2.6rem;
  }
}

.section-home-03 {
  padding: 0rem 0rem 10rem 0rem;
}
@media (max-width: 767px) {
  .section-home-03 {
    padding: 0rem 0rem 0rem 0rem;
  }
}
.section-home-03 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-home-03 .border-section {
  width: 60%;
  height: 0.6rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  margin: 0rem 0rem 9rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.section-home-03 .content-block {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  column-gap: 5rem;
}
@media (max-width: 767px) {
  .section-home-03 .content-block {
    grid-template-columns: 1fr;
    row-gap: 5rem;
  }
}
.section-home-03 .content-block .item-box:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .section-home-03 .content-block .item-box:nth-of-type(1) {
    padding: 0rem 1.5rem;
  }
}
.section-home-03 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #bc913e;
  padding: 0rem 0rem 4rem 0rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .section-home-03 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
    text-align: center;
    border: none;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-home-03 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
  color: #112334;
  padding: 0rem 0rem 4rem 0rem;
}
.section-home-03 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
@media (max-width: 767px) {
  .section-home-03 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
    font-size: 1.8rem;
  }
}
.section-home-03 .content-block .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.main-etapa-1 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-etapa-1 .content-block {
  padding: 3.5rem 1.5rem 4rem 1.5rem;
  background: #000;
  border-top: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .main-etapa-1 .content-block {
    border-top: none;
  }
}
.main-etapa-1 h1:nth-of-type(1) {
  color: #FFF;
  font-family: "Arsenal-Bold";
  text-align: center;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .main-etapa-1 h1:nth-of-type(1) {
    line-height: 1.3;
  }
}
.main-etapa-1 h1:nth-of-type(1) span {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #000;
  padding: 0rem 7rem;
}
@media (max-width: 767px) {
  .main-etapa-1 h1:nth-of-type(1) span {
    padding: 0rem 0.25rem;
  }
}

.section-etapa-1-01 {
  padding: 5rem 0rem 9rem 0rem;
}
.section-etapa-1-01 .content-block:nth-of-type(1) {
  padding: 0rem 0rem 5rem 0rem;
}
.section-etapa-1-01 .content-block:nth-of-type(1) h4:nth-of-type(1) {
  font-family: "Arsenal-Regular";
  text-align: center;
}
.section-etapa-1-01 .content-block:nth-of-type(1) h4:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .section-etapa-1-01 .content-block:nth-of-type(1) h4:nth-of-type(1) {
    font-size: 2.6rem;
  }
}
.section-etapa-1-01 .content-block:nth-of-type(2) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
}
@media (max-width: 991px) {
  .section-etapa-1-01 .content-block:nth-of-type(2) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-1-01 .content-block:nth-of-type(2) {
    grid-template-columns: 1fr;
  }
}
.section-etapa-1-01 .content-block:nth-of-type(2) .item-box {
  text-align: center;
  border-top: 0.2rem solid transparent;
  border-right: 0.2rem solid transparent;
  border-bottom: 0.2rem solid transparent;
  border-left: 0.2rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-01 .content-block:nth-of-type(2) .item-box {
    padding: 2rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-1-01 .content-block:nth-of-type(2) .item-box {
    flex-direction: row;
    align-items: center;
    gap: 0rem 1.5rem;
  }
}
.section-etapa-1-01 .content-block:nth-of-type(2) .item-box span i:before {
  color: #bc913e;
  font-size: 4rem;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-etapa-1-01 .content-block:nth-of-type(2) .item-box h6:nth-of-type(1) {
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-etapa-1-01 .content-block:nth-of-type(2) .item-box h6:nth-of-type(1) {
    font-size: 1.6rem;
  }
}

.section-etapa-1-02 {
  padding: 0rem 0rem 0rem 0rem;
}
.section-etapa-1-02 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-etapa-1-02 .content-block {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  column-gap: 7.5rem;
}
@media (max-width: 991px) {
  .section-etapa-1-02 .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-1-02 .content-block .item-box:nth-of-type(1) {
    padding: 0rem 3rem;
  }
}
.section-etapa-1-02 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #bc913e;
  padding: 0rem 0rem 3rem 0rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  margin: 0rem 0rem 3rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-02 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
    text-align: center;
    border: none;
    padding: 0rem 0rem 0rem 0rem;
    margin: 0rem 0rem 4rem 0rem;
  }
}
.section-etapa-1-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) {
    padding: 0rem 0rem 5rem 0rem;
    border-bottom: 0.3rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
  }
}
.section-etapa-1-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 3.5rem;
}
@media (max-width: 767px) {
  .section-etapa-1-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) li {
    font-size: 1.4rem;
  }
}
.section-etapa-1-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) li span i:before {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45px;
  color: #bc913e;
  font-size: 4rem;
}
@media (max-width: 991px) {
  .section-etapa-1-02 .content-block .item-box:nth-of-type(2) {
    grid-row: 1/2;
  }
}

.section-etapa-1-03 {
  padding: 8rem 0rem 10rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-03 {
    padding: 6rem 0rem 6rem 0rem;
  }
}
.section-etapa-1-03 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-etapa-1-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #bc913e;
  text-align: center;
  margin: 0rem 0rem 6rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) h2:nth-of-type(1) {
    padding: 0rem 1.5rem 0rem 1.5rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) p:nth-of-type(1) {
  text-align: center;
  margin: 0rem 0rem 7.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) p:nth-of-type(1) {
    padding: 0rem 1.5rem 0rem 1.5rem;
    font-size: 1.8rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) {
  display: grid;
  grid-template-columns: 65% 35%;
  padding: 3.5rem 0rem 3.5rem 0rem;
}
@media (max-width: 991px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) {
    padding: 3.5rem 0rem 3.5rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) {
    grid-template-columns: 1fr;
    padding: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction {
  padding: 0rem 6rem 0rem 3.5rem;
}
@media (max-width: 991px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction {
    padding: 1rem 2.5rem 1rem 2.5rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction {
    padding: 1rem 1rem 1rem 1rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1rem;
  margin: 0rem 0rem 3rem 0rem;
  font-size: 1.6rem;
}
@media (max-width: 480px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li {
    flex-direction: column;
    text-align: center;
    gap: 0.2rem 0rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span {
  width: 8rem;
  text-align: center;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span {
    width: 4rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span i:before {
  font-size: 5rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span i:before {
    font-size: 2.6rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
  font-size: 4rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
    font-size: 2.4rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li {
    margin: 0rem 0rem 0rem 0rem;
    font-size: 1.2rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(1) {
  font-family: "Arsenal-Bold";
  color: #bc913e;
  text-align: center;
  margin: 0rem 0rem 5rem 0rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(1) {
    font-family: "Helvetica-Bold";
    font-size: 1.4rem;
    color: #bc913e;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(6) {
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(6) {
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:last-of-type {
  font-size: 1.2rem;
  text-align: center;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:last-of-type {
    display: none;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li span i:before {
  color: #898989;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li span .icon-spec-parqueadero:before {
  color: #898989;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li {
    color: #898989;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li:nth-of-type(1) {
  color: #898989;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li:nth-of-type(1) {
    color: #898989;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .block-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .block-nav .nav.nav-tabs {
  width: 100%;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .block-nav .nav.nav-tabs .nav-item {
  width: 33.3%;
  text-align: center;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .block-nav .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Bold";
  background: #112334;
  color: #FFF;
  padding: 1.5rem 0rem 1.2rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  border-top: 0.5rem solid transparent;
  border-image-source: #000;
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .block-nav .nav.nav-tabs .nav-item .nav-link {
    margin: 0rem 0rem 0rem 0rem;
    color: #FFF;
    font-size: 1.8rem;
    padding: 1rem 0rem 0.6rem 0rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .block-nav .nav.nav-tabs .nav-item .nav-link.active {
  border: none;
  border-radius: 0rem;
  background: #FFF;
  color: #000;
  border-top: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .block-nav .nav-tabs {
  border-bottom: none;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content {
  position: relative;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane {
  position: relative;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs {
  width: 100%;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Regular";
  font-size: 2.6rem;
  background: #FFF;
  color: #112334;
  padding: 1rem 0rem 0.7rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  border-bottom: 0.5rem solid transparent;
  border-image-source: #000;
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link {
    border: 0.2rem solid transparent;
    border-image-source: #000;
    border-image-slice: 1;
    font-family: "Helvetica-Regular";
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.active {
  font-family: "Arsenal-Bold";
  border: none;
  border-radius: 0rem;
  background: #E5E5E5;
  color: #000;
  border-bottom: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.active {
    border: 0.2rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
    font-family: "Helvetica-Bold";
    font-size: 1.4rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.disabled {
  border: none;
  border-radius: 0rem;
  background: #E5E5E5;
  font-family: "Arsenal-Bold";
  color: #898989;
  border-top: 0.5rem solid transparent;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.disabled {
    border: 0.2rem solid transparent;
    font-family: "Helvetica-Regular";
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .content-tab {
  display: block;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .content-tab {
    display: grid;
    grid-template-columns: 1fr;
  }
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .content-tab .block-nav {
    grid-row: 1/2;
    padding: 1rem 3.5rem 0rem 3.5rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador {
  width: 1.9rem;
  height: 1.9rem;
  background: url("./../../build/img/indicador-tooltip.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
}
@media (max-width: 380px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador:hover {
  transform: scale(1.1);
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-01 {
  left: 23%;
  top: 55%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-01 {
    left: 30%;
    top: 20%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-02 {
  left: 55%;
  top: 50%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-02 {
    left: 42%;
    top: 50%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-03 {
  left: 65%;
  top: 15%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-03 {
    left: 80%;
    top: 65%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-04 {
  left: 75%;
  top: 60%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-01-04 {
    left: 35%;
    top: 80%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-01 {
  left: 22%;
  top: 47%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-01 {
    left: 47%;
    top: 20%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-02 {
  left: 37%;
  top: 47%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-02 {
    left: 47%;
    top: 35%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-03 {
  left: 55%;
  top: 60%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-03 {
    left: 32%;
    top: 55%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-04 {
  left: 73.5%;
  top: 45%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-a-02-04 {
    left: 37%;
    top: 75%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-01 {
  left: 11%;
  top: 67%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-01 {
    left: 27%;
    top: 8%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-02 {
  left: 27%;
  top: 50%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-02 {
    left: 45%;
    top: 27%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-03 {
  left: 52%;
  top: 47%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-03 {
    left: 48%;
    top: 52%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-04 {
  left: 82%;
  top: 59%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-04 {
    left: 35%;
    top: 83%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-05 {
  left: 73%;
  top: 11%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-01-05 {
    left: 83%;
    top: 75%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-01 {
  left: 26%;
  top: 48%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-01 {
    left: 46%;
    top: 26%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-02 {
  left: 61%;
  top: 47%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-02 {
    left: 46%;
    top: 63%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-03 {
  left: 61%;
  top: 40%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-03 {
    left: 54%;
    top: 63%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-04 {
  left: 55%;
  top: 67%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-04 {
    left: 27%;
    top: 57%;
  }
}
.section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-05 {
  left: 77%;
  top: 40%;
}
@media (max-width: 767px) {
  .section-etapa-1-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-b-02-05 {
    left: 54%;
    top: 78%;
  }
}

.section-etapa-1-04 {
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-04 {
    padding: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem 0rem;
  min-height: 390px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 img {
    width: 160px;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 h2 {
  text-align: center;
  color: #FFF;
  margin: 0rem 0rem 4.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 h2 {
    margin: 0rem 0rem 4rem 0rem;
    padding: 0rem 3rem 0rem 3rem;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span i:before {
  color: #FFF;
  font-size: 10rem;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span .icon-galeria:before {
  font-size: 10rem;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span .icon-youtube:before {
  font-size: 12rem;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span .icon-recorrido360:before {
  font-size: 7rem;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01 {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-uno-galeria-etapa-uno-altos-del-palmar.webp");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01:hover {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-uno-galeria-etapa-uno-altos-del-palmar.webp");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01 {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-uno-galeria-etapa-uno-xs-altos-del-palmar.webp");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 310px;
  }
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01:hover {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-uno-galeria-etapa-uno-xs-altos-del-palmar.webp");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02 {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-dos-video-condominio-etapa-uno-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02:hover {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-dos-video-condominio-etapa-uno-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02 {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-dos-video-condominio-etapa-uno-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 310px;
  }
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02:hover {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-dos-video-condominio-etapa-uno-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03 {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-tres-vista-360-casa-campestre-etapa-uno-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03:hover {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-tres-vista-360-casa-campestre-etapa-uno-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03 {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-tres-vista-360-casa-campestre-etapa-uno-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 310px;
  }
  .section-etapa-1-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03:hover {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-1/slider-tres-vista-360-casa-campestre-etapa-uno-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content .video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.section-etapa-1-04 .block-section-04 .content-block .tab-content video {
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
  width: 100%;
  height: auto;
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs {
  width: 100%;
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item {
  width: 33.3%;
  text-align: center;
}
@media (max-width: 767px) {
  .section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item {
    width: 100%;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Regular";
  font-size: 2.6rem;
  background: #E5E5E5;
  color: #112334;
  padding: 2rem 0rem 1.2rem 0rem;
  border: none;
  border-radius: 0rem;
  border-bottom: 0.6rem solid transparent;
  cursor: pointer;
}
@media (max-width: 767px) {
  .section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item .nav-link {
    font-size: 2.6rem;
    padding: 1rem 0rem 0.7rem 0rem;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item .nav-link.active {
  font-family: "Arsenal-Bold";
  background: #FFF;
  border: none;
  border-radius: 0rem;
  border-bottom: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item:nth-of-type(2) .nav-link {
  border-left: 0.1rem solid #FFF;
  border-right: 0.1rem solid #FFF;
}
@media (max-width: 767px) {
  .section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item:nth-of-type(2) .nav-link {
    border-left: none;
    border-right: none;
  }
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item:nth-of-type(2) .nav-link.active {
  border-left: none;
  border-right: none;
}
.section-etapa-1-04 .block-section-04 .content-block .block-nav-tab .nav-tabs {
  border-bottom: none;
}

.section-etapa-1-05 {
  padding: 10rem 0rem 8rem 0rem;
}
.section-etapa-1-05 .padding-alterno {
  padding-left: 0rem;
}
@media (max-width: 767px) {
  .section-etapa-1-05 .padding-alterno {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.section-etapa-1-05 .content-block {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  column-gap: 7.5rem;
}
@media (max-width: 991px) {
  .section-etapa-1-05 .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.section-etapa-1-05 .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) {
  font-family: "Arsenal-Regular";
  margin: 0rem 0rem 4rem 0rem;
  padding: 0rem 0rem 3.5rem 0rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
.section-etapa-1-05 .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 991px) {
  .section-etapa-1-05 .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) {
    padding: 0rem 1.5rem 3.5rem 1.5rem;
  }
}
.section-etapa-1-05 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
  margin: 0rem 0rem 5rem 0rem;
}
.section-etapa-1-05 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
@media (max-width: 991px) {
  .section-etapa-1-05 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
    padding: 0rem 1.5rem 0rem 1.5rem;
    font-size: 1.8rem;
  }
}

.main-etapa-2 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-etapa-2 .content-block {
  padding: 3.5rem 1.5rem 4rem 1.5rem;
  background: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-top: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .main-etapa-2 .content-block {
    border-top: none;
  }
}
.main-etapa-2 h1:nth-of-type(1) {
  color: #FFF;
  font-family: "Arsenal-Bold";
  text-align: center;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .main-etapa-2 h1:nth-of-type(1) {
    line-height: 1.3;
  }
}
.main-etapa-2 h1:nth-of-type(1) span {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  color: #112334;
  padding: 0rem 7rem;
}
@media (max-width: 767px) {
  .main-etapa-2 h1:nth-of-type(1) span {
    padding: 0rem 0.25rem;
  }
}

.section-etapa-2-01 {
  padding: 5rem 0rem 9rem 0rem;
}
.section-etapa-2-01 .content-block:nth-of-type(1) {
  padding: 0rem 0rem 5rem 0rem;
}
.section-etapa-2-01 .content-block:nth-of-type(1) h4:nth-of-type(1) {
  font-family: "Arsenal-Regular";
  text-align: center;
}
.section-etapa-2-01 .content-block:nth-of-type(1) h4:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 767px) {
  .section-etapa-2-01 .content-block:nth-of-type(1) h4:nth-of-type(1) {
    font-size: 2.6rem;
  }
}
.section-etapa-2-01 .content-block:nth-of-type(2) {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
}
@media (max-width: 991px) {
  .section-etapa-2-01 .content-block:nth-of-type(2) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-01 .content-block:nth-of-type(2) {
    grid-template-columns: 1fr;
  }
}
.section-etapa-2-01 .content-block:nth-of-type(2) .item-box {
  text-align: center;
  border-top: 0.2rem solid transparent;
  border-right: 0.2rem solid transparent;
  border-bottom: 0.2rem solid transparent;
  border-left: 0.2rem solid transparent;
  border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-image-slice: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-01 .content-block:nth-of-type(2) .item-box {
    padding: 2rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-01 .content-block:nth-of-type(2) .item-box {
    flex-direction: row;
    align-items: center;
    gap: 0rem 1.5rem;
  }
}
.section-etapa-2-01 .content-block:nth-of-type(2) .item-box span i:before {
  color: #112334;
  font-size: 4rem;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-etapa-2-01 .content-block:nth-of-type(2) .item-box h6:nth-of-type(1) {
  color: #112334;
}
@media (max-width: 767px) {
  .section-etapa-2-01 .content-block:nth-of-type(2) .item-box h6:nth-of-type(1) {
    font-size: 1.6rem;
  }
}

.section-etapa-2-02 {
  padding: 0rem 0rem 0rem 0rem;
}
.section-etapa-2-02 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-etapa-2-02 .content-block {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  column-gap: 7.5rem;
}
@media (max-width: 991px) {
  .section-etapa-2-02 .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-02 .content-block .item-box:nth-of-type(1) {
    padding: 0rem 3rem;
  }
}
.section-etapa-2-02 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #112334;
  padding: 0rem 0rem 3rem 0rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  margin: 0rem 0rem 3rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-02 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
    text-align: center;
    border: none;
    padding: 0rem 0rem 0rem 0rem;
    margin: 0rem 0rem 4rem 0rem;
  }
}
.section-etapa-2-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) {
    padding: 0rem 0rem 5rem 0rem;
    border-bottom: 0.3rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
  }
}
.section-etapa-2-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 3.5rem;
}
@media (max-width: 767px) {
  .section-etapa-2-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) li {
    font-size: 1.4rem;
  }
}
.section-etapa-2-02 .content-block .item-box:nth-of-type(1) ul:nth-of-type(1) li span i:before {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 45px;
  color: #bc913e;
  font-size: 4rem;
}
@media (max-width: 991px) {
  .section-etapa-2-02 .content-block .item-box:nth-of-type(2) {
    grid-row: 1/2;
  }
}

.section-etapa-2-03 {
  padding: 8rem 0rem 10rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-03 {
    padding: 6rem 0rem 6rem 0rem;
  }
}
.section-etapa-2-03 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-etapa-2-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #112334;
  text-align: center;
  margin: 0rem 0rem 6rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) h2:nth-of-type(1) {
    padding: 0rem 1.5rem 0rem 1.5rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) p:nth-of-type(1) {
  text-align: center;
  margin: 0rem 0rem 7.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(1) .item-box:nth-of-type(1) p:nth-of-type(1) {
    padding: 0rem 1.5rem 0rem 1.5rem;
    font-size: 1.8rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) {
  display: grid;
  grid-template-columns: 65% 35%;
  padding: 3.5rem 0rem 3.5rem 0rem;
}
@media (max-width: 991px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) {
    padding: 3.5rem 0rem 3.5rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) {
    grid-template-columns: 1fr;
    padding: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .head-tab p:nth-of-type(1) {
  border-top: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-image-slice: 1;
  padding: 1.5rem 0rem 1.2rem 0rem;
  background: #FFF;
  text-align: center;
  font-family: "Arsenal-Bold";
  font-size: 2rem;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .head-tab p:nth-of-type(1) {
    padding: 1rem 0rem 0.6rem 0rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction {
  padding: 0rem 6rem 0rem 3.5rem;
}
@media (max-width: 991px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction {
    padding: 1rem 2.5rem 1rem 2.5rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction {
    padding: 1rem 1rem 1rem 1rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1rem;
  margin: 0rem 0rem 3rem 0rem;
  font-size: 1.6rem;
}
@media (max-width: 480px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li {
    flex-direction: column;
    text-align: center;
    gap: 0.2rem 0rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span {
  width: 8rem;
  text-align: center;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span {
    width: 4rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span i:before {
  font-size: 5rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span i:before {
    font-size: 2.6rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
  font-size: 4rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span .icon-spec-parqueadero:before {
    font-size: 2.4rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span .icon-spec-pasillo:before {
  font-size: 3.6rem;
  color: #bc913e;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li span .icon-spec-pasillo:before {
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li {
    margin: 0rem 0rem 0rem 0rem;
    font-size: 1.2rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(1) {
  font-family: "Arsenal-Bold";
  color: #112334;
  text-align: center;
  margin: 0rem 0rem 5rem 0rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(1) {
    font-family: "Helvetica-Bold";
    font-size: 1.4rem;
    color: #bc913e;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(6) {
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:nth-of-type(6) {
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:last-of-type {
  font-size: 1.2rem;
  text-align: center;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction ul li:last-of-type {
    display: none;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li span i:before {
  color: #898989;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li span .icon-spec-parqueadero:before {
  color: #898989;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li {
    color: #898989;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li:nth-of-type(1) {
  color: #898989;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .content-spec .content-interaction.disabled ul li:nth-of-type(1) {
    color: #898989;
  }
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .item-box {
    display: grid;
    grid-template-columns: 1fr;
  }
  .section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav {
    grid-row: 2/3;
    padding: 1rem 3.5rem 0rem 3.5rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav .nav.nav-tabs {
  width: 100%;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav .nav.nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Regular";
  font-size: 2.6rem;
  background: #FFF;
  color: #112334;
  padding: 1rem 0rem 0.7rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  border-bottom: 0.5rem solid transparent;
  border-image-source: #000;
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav .nav.nav-tabs .nav-item .nav-link {
    border: 0.2rem solid transparent;
    border-image-source: #000;
    border-image-slice: 1;
    font-family: "Helvetica-Regular";
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav .nav.nav-tabs .nav-item .nav-link.active {
  font-family: "Arsenal-Bold";
  border: none;
  border-radius: 0rem;
  background: #E5E5E5;
  color: #000;
  border-bottom: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav .nav.nav-tabs .nav-item .nav-link.active {
    border: 0.2rem solid transparent;
    border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
    border-image-slice: 1;
    font-family: "Helvetica-Bold";
    font-size: 1.4rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .item-box .block-nav .nav-tabs {
  border-bottom: none;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content {
  position: relative;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane {
  position: relative;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs {
  width: 100%;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item {
  width: 50%;
  text-align: center;
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Regular";
  font-size: 2.6rem;
  background: #FFF;
  color: #112334;
  padding: 1rem 0rem 0.7rem 0rem;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
  border-bottom: 0.5rem solid transparent;
  border-image-source: #000;
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link {
    border: 0.2rem solid transparent;
    border-image-source: #000;
    border-image-slice: 1;
    font-family: "Helvetica-Regular";
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.active {
  font-family: "Arsenal-Bold";
  border: none;
  border-radius: 0rem;
  background: #E5E5E5;
  color: #000;
  border-bottom: 0.5rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .nav.nav-tabs .nav-item .nav-link.active {
    border: 0.2rem solid transparent;
    border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
    border-image-slice: 1;
    font-family: "Helvetica-Bold";
    font-size: 1.4rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .content-tab {
  display: block;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .content-tab {
    display: grid;
    grid-template-columns: 1fr;
  }
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .content-tab .block-nav {
    grid-row: 1/2;
    padding: 1rem 3.5rem 0rem 3.5rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador {
  width: 1.9rem;
  height: 1.9rem;
  background: url("./../../build/img/indicador-tooltip.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
}
@media (max-width: 380px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador {
    width: 1.6rem;
    height: 1.6rem;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador:hover {
  transform: scale(1.1);
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-01 {
  left: 15%;
  top: 20%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-01 {
    left: 21%;
    top: 85%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-02 {
  left: 39%;
  top: 20%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-02 {
    left: 21%;
    top: 58%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-03 {
  left: 39%;
  top: 35%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-03 {
    left: 35%;
    top: 58%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-04 {
  left: 55%;
  top: 27%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-04 {
    left: 28%;
    top: 41%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-05 {
  left: 92%;
  top: 3%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-05 {
    left: 3%;
    top: 5%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-06 {
  left: 80%;
  top: 27%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-06 {
    left: 25%;
    top: 20%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-07 {
  left: 39%;
  top: 70%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-07 {
    left: 65%;
    top: 58%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-08 {
  left: 51%;
  top: 88%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-08 {
    left: 88%;
    top: 45%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-09 {
  left: 63%;
  top: 66%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-01-09 {
    left: 70%;
    top: 25%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-01 {
  left: 25.5%;
  top: 33%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-01 {
    left: 33%;
    top: 70%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-02 {
  left: 60%;
  top: 40%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-02 {
    left: 40%;
    top: 45%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-03 {
  left: 72%;
  top: 35%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-03 {
    left: 36%;
    top: 26%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-04 {
  left: 23%;
  top: 90%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-04 {
    left: 90%;
    top: 73%;
  }
}
.section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-05 {
  left: 45%;
  top: 75%;
}
@media (max-width: 767px) {
  .section-etapa-2-03 .content-block:nth-of-type(2) .tab-content .tab-pane .indicador.item-c-02-05 {
    left: 70%;
    top: 45%;
  }
}

.section-etapa-2-04 {
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-04 {
    padding: 0rem 0rem 0rem 0rem;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem 0rem;
  min-height: 390px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 img {
    width: 160px;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 h2 {
  text-align: center;
  color: #FFF;
  margin: 0rem 0rem 4.5rem 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 h2 {
    margin: 0rem 0rem 4rem 0rem;
    padding: 0rem 3rem 0rem 3rem;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span i:before {
  color: #FFF;
  font-size: 10rem;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span .icon-galeria:before {
  font-size: 10rem;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span .icon-youtube:before {
  font-size: 12rem;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1 span .icon-recorrido360:before {
  font-size: 7rem;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01 {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-altos-del-palmar.webp");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01:hover {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-altos-del-palmar.webp");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01 {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-xs-altos-del-palmar.webp");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 310px;
  }
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01:hover {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-xs-altos-del-palmar.webp");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02 {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02:hover {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02 {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 310px;
  }
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02:hover {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03 {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03:hover {
  background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-altos-del-palmar.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03 {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 310px;
  }
  .section-etapa-2-04 .block-section-04 .content-block .tab-content .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03:hover {
    background: linear-gradient(90deg, rgba(17, 35, 52, 0.7) 0%, rgba(17, 35, 52, 0.7) 100%), url("./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-xs-altos-del-palmar.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content .video {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.section-etapa-2-04 .block-section-04 .content-block .tab-content video {
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
  width: 100%;
  height: auto;
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs {
  width: 100%;
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item {
  width: 33.3%;
  text-align: center;
}
@media (max-width: 767px) {
  .section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item {
    width: 100%;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item .nav-link {
  font-family: "Arsenal-Regular";
  font-size: 2.6rem;
  background: #E5E5E5;
  color: #112334;
  padding: 2rem 0rem 1.2rem 0rem;
  border: none;
  border-radius: 0rem;
  border-bottom: 0.6rem solid transparent;
  cursor: pointer;
}
@media (max-width: 767px) {
  .section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item .nav-link {
    font-size: 2.6rem;
    padding: 1rem 0rem 0.7rem 0rem;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item .nav-link.active {
  font-family: "Arsenal-Bold";
  background: #FFF;
  border: none;
  border-radius: 0rem;
  border-bottom: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #112334 12.62%, #375C80 50.64%, #112334 90.85%);
  border-image-slice: 1;
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item:nth-of-type(2) .nav-link {
  border-left: 0.1rem solid #FFF;
  border-right: 0.1rem solid #FFF;
}
@media (max-width: 767px) {
  .section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item:nth-of-type(2) .nav-link {
    border-left: none;
    border-right: none;
  }
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav.nav-tabs .nav-item:nth-of-type(2) .nav-link.active {
  border-left: none;
  border-right: none;
}
.section-etapa-2-04 .block-section-04 .content-block .block-nav-tab .nav-tabs {
  border-bottom: none;
}

.section-etapa-2-05 {
  padding: 10rem 0rem 8rem 0rem;
}
.section-etapa-2-05 .padding-alterno {
  padding-left: 0rem;
}
@media (max-width: 767px) {
  .section-etapa-2-05 .padding-alterno {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.section-etapa-2-05 .content-block {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  column-gap: 7.5rem;
}
@media (max-width: 991px) {
  .section-etapa-2-05 .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.section-etapa-2-05 .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) {
  font-family: "Arsenal-Regular";
  margin: 0rem 0rem 4rem 0rem;
  padding: 0rem 0rem 3.5rem 0rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
.section-etapa-2-05 .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) span {
  font-family: "Arsenal-Bold";
}
@media (max-width: 991px) {
  .section-etapa-2-05 .content-block .item-box:nth-of-type(2) h2:nth-of-type(1) {
    padding: 0rem 1.5rem 3.5rem 1.5rem;
  }
}
.section-etapa-2-05 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
  margin: 0rem 0rem 5rem 0rem;
}
.section-etapa-2-05 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
@media (max-width: 991px) {
  .section-etapa-2-05 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
    padding: 0rem 1.5rem 0rem 1.5rem;
    font-size: 1.8rem;
  }
}

.main-condominio .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-condominio .content-block {
  padding: 3.5rem 8.33% 4rem 8.33%;
  background: #000;
  border-top: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .main-condominio .content-block {
    border-top: none;
    padding: 3.5rem 1.5rem 4rem 1.5rem;
  }
}
.main-condominio h1:nth-of-type(1) {
  color: #FFF;
  font-family: "Arsenal-Bold";
  text-align: center;
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .main-condominio h1:nth-of-type(1) {
    line-height: 1.3;
    border-bottom: none;
    padding: 0rem 0rem 0rem 0rem;
  }
}

.section-condominio-01 {
  padding: 7rem 0rem 4.5rem 0rem;
}
@media (max-width: 767px) {
  .section-condominio-01 {
    padding: 5rem 0rem 0rem 0rem;
  }
}
.section-condominio-01 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-condominio-01 .border-content {
  width: 60%;
  height: 0.6rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  margin: 0rem 0rem 9rem 0rem;
}
.section-condominio-01 .content-block {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  column-gap: 0rem;
}
@media (max-width: 991px) {
  .section-condominio-01 .content-block {
    grid-template-columns: 1fr;
  }
}
.section-condominio-01 .content-block .item-box:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-condominio-01 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #bc913e;
  padding: 0rem 1.5rem 4rem 1.5rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  margin: 0rem 0rem 4rem 0rem;
}
@media (max-width: 767px) {
  .section-condominio-01 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
    text-align: center;
    border: none;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-condominio-01 .content-block .item-box:nth-of-type(1) .descripcion {
  color: #112334;
  padding: 0rem 1.5rem 4rem 1.5rem;
}
.section-condominio-01 .content-block .item-box:nth-of-type(1) .descripcion span {
  font-family: "Helvetica-Bold";
}
.section-condominio-01 .content-block .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  .section-condominio-01 .content-block .item-box:nth-of-type(2) {
    display: block;
  }
}
.section-condominio-01 .box {
  background: #000;
  padding: 5rem 4.5rem;
  border-bottom: 0.6rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-condominio-01 .box h4:nth-of-type(1) {
  color: #FFF;
  margin: 0rem 0rem 3rem 0rem;
}
@media (max-width: 767px) {
  .section-condominio-01 .box h4:nth-of-type(1) {
    text-align: center;
    font-size: 3.2rem;
  }
}
.section-condominio-01 .box p:nth-of-type(1) {
  color: #FFF;
  padding: 0rem 0rem 4rem 0rem;
  font-size: 1.6rem;
}
.section-condominio-01 .box p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
@media (max-width: 767px) {
  .section-condominio-01 .box p:nth-of-type(1) {
    font-size: 1.8rem;
  }
}
.section-condominio-01 .box .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 2rem;
}
@media (max-width: 767px) {
  .section-condominio-01 .box .item {
    flex-direction: column;
    gap: 1.5rem 0rem;
  }
}
.section-condominio-01 .box .item a {
  color: #FFF;
  border: 0.1rem solid #FFF;
  font-family: "Arsenal-Bold";
  font-size: 2rem;
  padding: 0.7rem 1rem;
  display: inline-block;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.section-condominio-01 .box .item a:focus {
  color: #112334;
  background: #FFF;
}
.section-condominio-01 .box .item a:hover {
  color: #112334;
  background: #FFF;
}
.section-condominio-01 .box .item a span i:before {
  margin: 0rem 3rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-condominio-01 .box .item a {
    font-size: 2.6rem;
  }
}

.container-targets-modal {
  position: relative;
}
.container-targets-modal .bg-planta-condominio {
  position: static;
}
.container-targets-modal .mascara-zona-01 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.container-targets-modal .mascara-zona-02 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.container-targets-modal .mascara-zona-03 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.container-targets-modal .popover-01,
.container-targets-modal .popover-02,
.container-targets-modal .popover-03 {
  width: 27.5%;
  padding: 0.7rem;
}
.container-targets-modal .popover-01:hover,
.container-targets-modal .popover-02:hover,
.container-targets-modal .popover-03:hover {
  cursor: pointer;
}
@media (max-width: 767px) {
  .container-targets-modal .popover-01,
.container-targets-modal .popover-02,
.container-targets-modal .popover-03 {
    transform: scale(1.5);
  }
}
.container-targets-modal .popover-01 {
  position: absolute;
  top: 25%;
  left: 37.5%;
  z-index: 15;
}
.container-targets-modal .popover-02 {
  position: absolute;
  bottom: 17%;
  left: 50.5%;
  z-index: 15;
}
.container-targets-modal .popover-03 {
  position: absolute;
  top: 52.5%;
  right: 7%;
  z-index: 15;
}

.section-condominio-02 {
  padding: 7rem 0rem 10rem 0rem;
}
@media (max-width: 767px) {
  .section-condominio-02 {
    padding: 7rem 0rem 0rem 0rem;
  }
}
.section-condominio-02 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-condominio-02 h2:nth-of-type(1) {
  color: #bc913e;
  text-align: center;
  padding: 7rem 1.5rem 4.5rem 1.5rem;
  border-top: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .section-condominio-02 h2:nth-of-type(1) {
    padding: 0rem 1.5rem 4.5rem 1.5rem;
    border: none;
  }
}
.section-condominio-02 .content-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 8.33% 2.5rem 8.33%;
  box-shadow: 0rem 0rem 1rem #e5e5e5;
  position: relative;
}
@media (max-width: 991px) {
  .section-condominio-02 .content-block {
    padding: 2.5rem 1.5rem 2.5rem 1.5rem;
  }
}
.section-condominio-02 .content-block .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.section-condominio-02 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
  font-family: "Arsenal-Bold";
  font-size: 2.6rem;
  text-align: center;
}
@media (max-width: 991px) {
  .section-condominio-02 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
    font-family: "Helvetica-Regular";
    font-size: 1.8rem;
    max-width: 290px;
  }
}
@media (max-width: 767px) {
  .section-condominio-02 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
    max-width: 210px;
  }
}
.section-condominio-02 .content-block .item-box:nth-of-type(3) {
  position: absolute;
  right: 17.5%;
}
@media (max-width: 991px) {
  .section-condominio-02 .content-block .item-box:nth-of-type(3) {
    right: 12.5%;
  }
}
.section-condominio-02 .content-block .item-box:nth-of-type(3) p:nth-of-type(1) {
  font-family: "Arsenal-Bold";
  font-size: 2rem;
  text-align: center;
}
@media (max-width: 991px) {
  .section-condominio-02 .content-block .item-box:nth-of-type(3) p:nth-of-type(1) {
    font-family: "Helvetica-Regular";
    font-size: 1.8rem;
  }
}

.main-constructora .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-constructora .content-block {
  padding: 0rem 0rem 3rem 0rem;
  background: #000;
}
@media (max-width: 767px) {
  .main-constructora .content-block {
    padding: 0rem 0rem 2rem 0rem;
  }
}
.main-constructora h1:nth-of-type(1) {
  color: #000;
  font-family: "Arsenal-Bold";
  text-align: center;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  padding: 3rem 8.33% 3rem 8.33%;
}
@media (max-width: 767px) {
  .main-constructora h1:nth-of-type(1) {
    line-height: 1.3;
    border-bottom: none;
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
}
.main-constructora h4:nth-of-type(1) {
  padding: 3rem 0rem 2rem 0rem;
  margin: 0rem 1.5rem 0rem 1.5rem;
  font-family: "Helvetica-Regular";
  font-size: 1.8rem;
  line-height: 1.2;
  background: #000;
  color: #FFF;
  text-align: center;
}
@media (max-width: 767px) {
  .main-constructora h4:nth-of-type(1) {
    font-size: 1.8rem;
  }
}
.main-constructora h4:nth-of-type(2) {
  padding: 3rem 0rem 2rem 0rem;
  margin: 0rem 8.33% 0rem 8.33%;
  font-family: "Arsenal-Regular";
  background: #000;
  color: #FFF;
  text-align: center;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .main-constructora h4:nth-of-type(2) {
    font-size: 1.8rem;
  }
}

.section-constructora-01 {
  padding: 9rem 0rem 8rem 0rem;
}
@media (max-width: 767px) {
  .section-constructora-01 {
    padding: 5rem 0rem 0rem 0rem;
  }
}
.section-constructora-01 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-constructora-01 .content-block {
  display: grid;
  grid-template-columns: 1fr 1.25fr;
  column-gap: 7.5rem;
}
@media (max-width: 767px) {
  .section-constructora-01 .content-block {
    grid-template-columns: 1fr;
  }
}
.section-constructora-01 .content-block .item-box:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .section-constructora-01 .content-block .item-box:nth-of-type(1) {
    padding: 0rem 0rem;
    align-items: center;
  }
}
.section-constructora-01 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
  color: #bc913e;
  padding: 0rem 0rem 5rem 0rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  margin: 0rem 0rem 5rem 0rem;
}
@media (max-width: 767px) {
  .section-constructora-01 .content-block .item-box:nth-of-type(1) h2:nth-of-type(1) {
    text-align: center;
    border: none;
    padding: 0rem 1.5rem 5rem 1.5rem;
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-constructora-01 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
  color: #112334;
  padding: 0rem 0rem 5rem 0rem;
  line-height: 1.2;
}
.section-constructora-01 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) span {
  font-family: "Helvetica-Bold";
}
@media (max-width: 767px) {
  .section-constructora-01 .content-block .item-box:nth-of-type(1) p:nth-of-type(1) {
    padding: 5rem 1.5rem 5rem 1.5rem;
    font-size: 1.8rem;
  }
}
.section-constructora-01 .content-block .item-box:nth-of-type(1) .box {
  border-left: 0.7rem solid transparent;
  border-image-source: linear-gradient(180deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
  padding: 0rem 0rem 0rem 3rem;
}
@media (max-width: 767px) {
  .section-constructora-01 .content-block .item-box:nth-of-type(1) .box {
    margin: 0rem 1.5rem 0rem 1.5rem;
  }
}
.section-constructora-01 .content-block .item-box:nth-of-type(1) .box h3:nth-of-type(1) {
  margin: 0rem 0rem 1rem 0rem;
}
.section-constructora-01 .content-block .item-box:nth-of-type(1) .box h6:nth-of-type(1) {
  font-family: "Arsenal-Regular";
}
@media (max-width: 767px) {
  .section-constructora-01 .content-block .item-box:nth-of-type(1) .box h6:nth-of-type(1) {
    font-size: 1.8rem;
  }
}
.section-constructora-01 .content-block .item-box:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.section-constructora-01 .border-section {
  width: 100%;
  height: 0.3rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  margin: 8rem 0rem 0rem 0rem;
}

.main-contacto .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-contacto .content-block {
  padding: 0rem 0rem 3rem 0rem;
  background: #000;
}
@media (max-width: 767px) {
  .main-contacto .content-block {
    padding: 0rem 0rem 2rem 0rem;
  }
}
.main-contacto h1:nth-of-type(1) {
  color: #000;
  font-family: "Arsenal-Bold";
  text-align: center;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  padding: 3rem 8.33% 3rem 8.33%;
}
@media (max-width: 767px) {
  .main-contacto h1:nth-of-type(1) {
    line-height: 1.3;
    border-bottom: none;
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
}
.main-contacto h4:nth-of-type(1) {
  padding: 3rem 0rem 2rem 0rem;
  margin: 0rem 1.5rem 0rem 1.5rem;
  font-family: "Helvetica-Regular";
  font-size: 1.8rem;
  line-height: 1.2;
  background: #000;
  color: #FFF;
  text-align: center;
}
@media (max-width: 767px) {
  .main-contacto h4:nth-of-type(1) {
    font-size: 1.8rem;
  }
}
.main-contacto h4:nth-of-type(2) {
  padding: 3rem 0rem 2rem 0rem;
  margin: 0rem 8.33% 0rem 8.33%;
  font-family: "Arsenal-Regular";
  background: #000;
  color: #FFF;
  text-align: center;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .main-contacto h4:nth-of-type(2) {
    font-size: 1.8rem;
  }
}

.section-contacto-01 {
  padding: 9rem 0rem 8rem 0rem;
}
@media (max-width: 767px) {
  .section-contacto-01 {
    padding: 0rem 0rem 0rem 0rem;
  }
}
.section-contacto-01 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.section-contacto-01 .content-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
}
@media (max-width: 991px) {
  .section-contacto-01 .content-block {
    grid-template-columns: 1fr;
    row-gap: 5rem;
  }
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block {
    row-gap: 0rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) img:nth-of-type(1) {
    margin-bottom: 3.5rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box {
  position: relative;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box {
    margin: 0rem 0rem 0rem 0rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .border-content {
  position: absolute;
  right: 0rem;
  top: -0.5rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  height: 1rem;
  width: 35%;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .border-content {
    width: 50%;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .head-content {
  background: #000;
  padding: 1.5rem 16.66% 1.5rem 16.66%;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .head-content {
    padding: 3.5rem 8.33% 3.5rem 8.33%;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .head-content h2:nth-of-type(1) {
  color: #FFF;
  margin: 0rem 0rem 1rem 0rem;
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .head-content h5:nth-of-type(1) {
  color: #FFF;
  font-family: "Arsenal-Regular";
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .head-content h5:nth-of-type(1) {
    font-size: 1.8rem;
    font-family: "Helvetica-Regular";
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content {
  padding: 3.5rem 16.66% 3.5rem 16.66%;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content {
    padding: 3.5rem 8.33% 3.5rem 8.33%;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul {
  display: flex;
  flex-direction: column;
  gap: 2rem 0rem;
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0rem 2rem;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li {
    font-size: 1.4rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link {
  color: #375c80;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link {
    font-size: 1.4rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link:focus {
  color: #375c80;
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link:hover {
  color: #bc913e;
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link-mail {
  color: #112334;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link-mail {
    font-size: 1.4rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link-mail:focus {
  color: #112334;
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li .link-mail:hover {
  color: #bc913e;
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li span i:before {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 4rem;
  font-size: 3rem;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li span i:before {
    font-size: 2.4rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li span .icon-email:before {
  font-size: 2rem;
}
@media (max-width: 767px) {
  .section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li span .icon-email:before {
    font-size: 1.8rem;
  }
}
.section-contacto-01 .content-block .item-box:nth-of-type(1) .box .content ul li:nth-of-type(2) .order-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem 0rem;
}
.section-contacto-01 .border-section {
  width: 100%;
  height: 0.3rem;
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  margin: 8rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-contacto-01 .border-section {
    margin: 5rem 0rem 0rem 0rem;
  }
}

.main-gracias .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-gracias .content-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
}
@media (max-width: 991px) {
  .main-gracias .content-block {
    grid-template-columns: 1fr;
    row-gap: 8rem;
  }
}
@media (max-width: 991px) {
  .main-gracias .content-block .item-box:nth-of-type(1) {
    grid-row: 2/3;
  }
}
.main-gracias .content-block .item-box:nth-of-type(2) {
  padding: 1.5rem 8.33% 1.5rem 8.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 991px) {
  .main-gracias .content-block .item-box:nth-of-type(2) {
    padding: 6rem 8.33% 1.5rem 8.33%;
  }
}
.main-gracias .content-block .item-box:nth-of-type(2) h1:nth-of-type(1) {
  margin: 0rem 0rem 3.5rem 0rem;
}
@media (max-width: 991px) {
  .main-gracias .content-block .item-box:nth-of-type(2) h1:nth-of-type(1) {
    text-align: center;
  }
}
.main-gracias .content-block .item-box:nth-of-type(2) h4:nth-of-type(1) {
  line-height: 1.2;
  margin: 0rem 0rem 3.5rem 0rem;
  font-family: "Arsenal-Regular";
  font-size: 2.4rem;
}
@media (max-width: 991px) {
  .main-gracias .content-block .item-box:nth-of-type(2) h4:nth-of-type(1) {
    font-family: "Helvetica-Regular";
    font-size: 1.6rem;
  }
}
.main-gracias .content-block .item-box:nth-of-type(2) h4:nth-of-type(2) {
  line-height: 1.2;
  margin: 0rem 0rem 2.5rem 0rem;
  font-family: "Arsenal-Regular";
  font-size: 2.4rem;
}
@media (max-width: 991px) {
  .main-gracias .content-block .item-box:nth-of-type(2) h4:nth-of-type(2) {
    font-family: "Helvetica-Regular";
    font-size: 1.6rem;
  }
}
.main-gracias .content-block .item-box:nth-of-type(2) ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0rem 4rem;
  margin: 0rem 0rem 7rem 0rem;
}
@media (max-width: 767px) {
  .main-gracias .content-block .item-box:nth-of-type(2) ul {
    gap: 0rem 3rem;
    justify-content: center;
  }
}
.main-gracias .content-block .item-box:nth-of-type(2) ul span i:before {
  color: #898989;
  font-size: 4.4rem;
}
.main-gracias .content-block .item-box:nth-of-type(2) ul span i:hover:before {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  -webkit-background-clip: text;
  color: transparent;
  opacity: 1;
}

.main-error-404 .padding-alterno {
  padding-left: 0rem;
  padding-right: 0rem;
}
.main-error-404 .content-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
}
@media (max-width: 991px) {
  .main-error-404 .content-block {
    grid-template-columns: 1fr;
    row-gap: 8rem;
  }
}
@media (max-width: 991px) {
  .main-error-404 .content-block .item-box:nth-of-type(1) {
    grid-row: 2/3;
  }
}
.main-error-404 .content-block .item-box:nth-of-type(2) {
  padding: 1.5rem 8.33% 1.5rem 8.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 991px) {
  .main-error-404 .content-block .item-box:nth-of-type(2) {
    padding: 6rem 8.33% 1.5rem 8.33%;
  }
}
.main-error-404 .content-block .item-box:nth-of-type(2) h1:nth-of-type(1) {
  margin: 0rem 0rem 3.5rem 0rem;
}
@media (max-width: 991px) {
  .main-error-404 .content-block .item-box:nth-of-type(2) h1:nth-of-type(1) {
    text-align: center;
  }
}
.main-error-404 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
  line-height: 1.2;
  margin: 0rem 0rem 3rem 0rem;
  font-family: "Arsenal-Regular";
}
@media (max-width: 991px) {
  .main-error-404 .content-block .item-box:nth-of-type(2) p:nth-of-type(1) {
    font-family: "Helvetica-Regular";
    font-size: 1.8rem;
  }
}

.main-preguntas-frecuentes {
  padding: 4rem 0rem 0rem 0rem;
}
.main-preguntas-frecuentes .content-block {
  padding: 7rem 0rem 0rem 0rem;
}
.main-preguntas-frecuentes .content-block .item-box .accordion {
  display: grid;
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card {
  border: none;
  border-radius: 1rem;
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header {
  padding: 1.5rem;
  background: #FFF;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box .accordion .card-header {
    font-size: 1.6rem;
    padding: 1rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header a {
  box-shadow: 0rem 0.5rem 1rem #898989;
  padding: 2.5rem 5.2rem;
  font-family: "Helvetica-Regular";
  font-size: 2.4rem;
  color: #112334;
  background: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0rem 1rem;
  align-items: center;
  border-radius: 1rem;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box .accordion .card-header a {
    box-shadow: 0rem 0.25rem 0.5rem #898989;
    font-size: 1.6rem;
    padding: 2rem 1.5rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header a:focus {
  outline: none;
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header a:hover {
  text-decoration: none;
  color: #112334;
  cursor: pointer;
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header a span {
  pointer-events: none;
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header a span i {
  pointer-events: none;
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header a span i:before {
  pointer-events: none;
  font-size: 1rem;
  font-weight: bolder;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box .accordion .card-header a span i:before {
    font-size: 0.7rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-header a span.active {
  transform: rotate(-180deg);
}
.main-preguntas-frecuentes .content-block .item-box .accordion .card-body {
  font-family: "Helvetica-Regular";
  font-size: 2rem;
  color: #112334;
  background: #FFF;
  padding: 3.5rem 6.7rem 3.5rem 6.7rem;
  border-top: none;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box .accordion .card-body {
    padding: 1rem 3rem 1rem 3rem;
    font-size: 1.4rem;
    line-height: 1.2;
  }
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(1) h1:nth-of-type(1) {
  text-align: center;
  margin: 0rem 0rem 7rem 0rem;
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(1) h4:nth-of-type(1) {
  font-family: "Arsenal-Regular";
  margin: 0rem 0rem 5rem 0rem;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box:nth-of-type(1) h4:nth-of-type(1) {
    font-size: 1.6rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(1) h4:nth-of-type(2) {
  font-family: "Arsenal-Regular";
  margin: 0rem 0rem 5rem 0rem;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box:nth-of-type(1) h4:nth-of-type(2) {
    font-size: 1.6rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(2) {
  padding: 0rem 5rem 5rem 5rem;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box:nth-of-type(2) {
    padding: 0rem 1.5rem 3.5rem 1.5rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(2) .title-accordion {
  text-align: center;
  padding: 1.5rem 1.5rem;
  margin: 0rem 0rem 7rem 0rem;
  border-bottom: 0.3rem solid transparent;
  border-image-source: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  border-image-slice: 1;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box:nth-of-type(2) .title-accordion {
    margin: 0rem 0rem 4rem 0rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(3) {
  padding: 0rem 5rem 10rem 5rem;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box:nth-of-type(3) {
    padding: 0rem 1.5rem 7rem 1.5rem;
  }
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(3) .border-content {
  background: linear-gradient(90deg, #BC913E 0%, #ECEBA2 50%, #BC913E 100%);
  height: 0.3rem;
  width: 100%;
  margin: 0rem 0rem 4rem 0rem;
}
.main-preguntas-frecuentes .content-block .item-box:nth-of-type(3) p:nth-of-type(1) {
  text-align: center;
  margin: 0rem 0rem 2.5rem 0rem;
}
@media (max-width: 767px) {
  .main-preguntas-frecuentes .content-block .item-box:nth-of-type(3) p:nth-of-type(1) {
    font-size: 1.8rem;
  }
}