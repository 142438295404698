@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-etapa-2-04{
  padding: 0rem 0rem 0rem 0rem;
  @include m.mq-sm{
    padding: 0rem 0rem 0rem 0rem;
  }
  .block-section-04{
    .content-block{
      .tab-content{
        .btn-tabs-proyecto-etapa-1{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2rem 0rem;
          min-height: 390px;
          cursor: pointer;
          img{
            @include m.mq-sm{
              width: 160px;
            }
          }
          h2{
            text-align: center;
            color: v.$blanco;
            margin: 0rem 0rem 4.5rem 0rem;
            @include m.mq-sm{
              margin: 0rem 0rem 4rem 0rem;
              padding: 0rem 3rem 0rem 3rem;
            }
          }
          span{
            i:before{
              color: v.$blanco;
              font-size: 10rem;
            }
            .icon-galeria:before{
              font-size: 10rem;
            }
            .icon-youtube:before{
              font-size: 12rem;
            }
            .icon-recorrido360:before{
              font-size: 7rem;
            }
          }
        }
        .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--01{
          background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-altos-del-palmar.webp');
          background-position: center top;
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 500px;
          &:hover{
            background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-altos-del-palmar.webp');
            background-position: center top;
            background-size: cover;
            background-repeat: no-repeat;
          }
          @include m.mq-xs{
            background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-xs-altos-del-palmar.webp');
            background-position: center top;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 310px;
            &:hover{
              background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-uno-galeria-etapa-dos-xs-altos-del-palmar.webp');
              background-position: center top;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
        }

        .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--02{
          background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-altos-del-palmar.webp');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 500px;
          &:hover{
            background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-altos-del-palmar.webp');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
          @include m.mq-xs{
            background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-xs-altos-del-palmar.webp');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 310px;
            &:hover{
              background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-dos-vista-360-casa-campestre-etapa-dos-xs-altos-del-palmar.webp');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
        }

        .btn-tabs-proyecto-etapa-1.btn-tabs-proyecto-etapa-1--03{
          background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-altos-del-palmar.webp');
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 500px;
          &:hover{
            background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-altos-del-palmar.webp');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
          @include m.mq-xs{
            background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-xs-altos-del-palmar.webp');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 310px;
            &:hover{
              background:v.$gradient-azul-02, url('./../../build/img/landing-etapa-2/slider-tres-video-condominio-etapa-dos-xs-altos-del-palmar.webp');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
        }
        .video{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        
        video{
          box-shadow: 0rem .5rem 1rem rgba(0,0,0,.3);
          // max-width: 945px;
          width: 100%;
          height: auto;
          // border-radius: 1.5rem;
        }
      }
      .block-nav-tab{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        // padding: 1rem 0rem 0rem 0rem;
        .nav.nav-tabs{
          width: 100%;
          .nav-item{
            // width: 50%;
            width: 33.3%;
            text-align: center;
            @include m.mq-sm{
              width: 100%;
            }
            .nav-link{
              font-family: v.$font-regular-01;
              font-size: v.$h3-sm;
              background: v.$gris-03;
              color: v.$azul-02;
              padding: 2rem 0rem 1.2rem 0rem;
              border: none;
              border-radius: 0rem;
              border-bottom: .6rem solid transparent;
              cursor: pointer;
              @include m.mq-sm{
                font-size: v.$h3-sm;
                padding: 1rem 0rem .7rem 0rem;
              }
            }
            .nav-link.active{
              font-family: v.$font-bold-01;
              background: v.$blanco;
              border: none;
              border-radius: 0rem;

              border-bottom: .6rem solid transparent;
              border-image-source: v.$gradient-azul-01; 
              border-image-slice: 1;
            }
          }
          .nav-item:nth-of-type(2){
            .nav-link{
              border-left: .1rem solid v.$blanco;
              border-right: .1rem solid v.$blanco;
              @include m.mq-sm{
                border-left: none;
                border-right: none;
              }
            }
            .nav-link.active{
              border-left: none;
              border-right: none;
            }
          }
          // .nav-item:nth-of-type(1){
          //   .nav-link.active{
          //     background: v.$blanco;
          //     border-bottom: .6rem solid v.$dorado-01;
          //   }
          // }
          // .nav-item:nth-of-type(2){
          //   .nav-link.active{
          //     background: v.$blanco;
          //     border-bottom: .6rem solid v.$azul-01;
          //   }
          // }
        }
        .nav-tabs {
          border-bottom: none;
        }
      }
    }
  }
}