@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-caracteristicas-condominio {
  padding: 0rem 0rem 9rem 0rem;
  &.etapa-1{
    .box{
      border-top: .3rem solid transparent;
      border-image-source: v.$gradient-dorado-01; 
      border-image-slice: 1;
      padding: 5rem 0rem 0rem 0rem;
      h2:nth-of-type(1){
        text-align: center;
        color: v.$dorado-02;
        margin: 0rem 0rem 6rem 0rem;
        span{
          font-family: v.$font-bold-01;
        }
      }
      @include m.mq-sm{
        padding: 0rem 0rem 0rem 0rem;
        border: none;
      }
    }
    .content-block:nth-of-type(1){
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 1.5rem;
      @include m.mq-md{
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1.5rem;
      }
      @include m.mq-sm{
        grid-template-columns: 1fr;
      }
      .item-box{
        text-align: center;
        border-top: .2rem solid transparent;
        border-right: .2rem solid transparent;
        border-bottom: .2rem solid transparent;
        border-left: .2rem solid transparent;
        border-image-source: v.$gradient-dorado-01; 
        border-image-slice: 1;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem 0rem;
        span{
          i:before{
            color: v.$dorado-02;
            font-size: v.$h2-xl;
            margin: 0rem 0rem 1.5rem 0rem;
          }
        }
        h6:nth-of-type(1){
          color: v.$dorado-02;
        }
      }
    }
    .carousel-inner{
      .item-box{
        text-align: center;
        // border-top: .2rem solid transparent;
        // border-right: .2rem solid transparent;
        // border-bottom: .2rem solid transparent;
        // border-left: .2rem solid transparent;
        // border-image-source: v.$gradient-dorado-01; 
        // border-image-slice: 1;
  
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem 0rem;
        span{
          i:before{
            color: v.$dorado-02;
            font-size: v.$h1-xl;
            margin: 0rem 0rem 1.5rem 0rem;
          }
        }
        h6:nth-of-type(1){
          color: v.$dorado-02;
        }
      }
    }
    .btn-left-slide,
    .btn-right-slide{
      color: v.$dorado-02;
    }
  }
  &.etapa-2{
    .box{
      border-top: .3rem solid transparent;
      border-image-source: v.$gradient-dorado-01; 
      border-image-slice: 1;
      padding: 5rem 0rem 0rem 0rem;
      h2:nth-of-type(1){
        text-align: center;
        color: v.$azul-01;
        margin: 0rem 0rem 6rem 0rem;
        span{
          font-family: v.$font-bold-01;
        }
      }
      @include m.mq-sm{
        padding: 0rem 0rem 0rem 0rem;
        border: none;
      }
    }
    .content-block:nth-of-type(1){
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 1.5rem;
      @include m.mq-md{
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1.5rem;
      }
      @include m.mq-sm{
        grid-template-columns: 1fr;
      }
      .item-box{
        text-align: center;
        border-top: .2rem solid transparent;
        border-right: .2rem solid transparent;
        border-bottom: .2rem solid transparent;
        border-left: .2rem solid transparent;
        border-image-source: v.$gradient-azul-01; 
        border-image-slice: 1;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem 0rem;
        span{
          i:before{
            color: v.$azul-01;
            font-size: v.$h2-xl;
            margin: 0rem 0rem 1.5rem 0rem;
          }
        }
        h6:nth-of-type(1){
          color: v.$azul-01;
        }
      }
    }
    .carousel-inner{
      .item-box{
        text-align: center;
        // border-top: .2rem solid transparent;
        // border-right: .2rem solid transparent;
        // border-bottom: .2rem solid transparent;
        // border-left: .2rem solid transparent;
        // border-image-source: v.$gradient-dorado-01; 
        // border-image-slice: 1;
  
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem 0rem;
        span{
          i:before{
            color: v.$azul-01;
            font-size: v.$h1-xl;
            margin: 0rem 0rem 1.5rem 0rem;
          }
        }
        h6:nth-of-type(1){
          color: v.$azul-01;
        }
      }
    }
    .btn-left-slide,
    .btn-right-slide{
      color: v.$azul-01;
    }
  }
}