@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-testimoniales{
  padding: 8rem 0rem 6rem 0rem;
  @include m.mq-sm{
    padding: 6rem 0rem 0rem 0rem;
  }
  .block-content{
    width: 85%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 4rem;

    border-bottom: .3rem solid transparent;
    border-image-source: v.$gradient-dorado-01; 
    border-image-slice: 1;

    padding: 0rem 0rem 7rem 0rem;
    @include m.mq-sm{
      width: 100%;
      grid-template-columns: 1fr;
      column-gap: 0rem;
      row-gap: 2rem;
      padding: 0rem 0rem 5rem 0rem;
    }
    .item-box:nth-of-type(1){
      display: flex;
      flex-direction: column;
      align-items: center;
      gap:3rem 0rem;
      h2:nth-of-type(1){
        text-align: center;
        color: v.$dorado-02;
        margin: 0rem 0rem 2.5rem 0rem;
      }
      h4:nth-of-type(1){
        color: v.$dorado-02;
        @include m.mq-sm{
          font-size: v.$h3-sm;
        }
      }
    }
    .item-box:nth-of-type(2){
      display: flex;
      flex-direction: column;
      gap:3rem 0rem;
      h2:nth-of-type(1){
        text-align: center;
        color: v.$dorado-02;
      }
      p:nth-of-type(1){
        text-align: center;
        @include m.mq-sm{
          font-size: v.$body-04;
        }
      }
    }
  }
}