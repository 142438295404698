@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.main-constructora{
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block{
    padding: 0rem 0rem 3rem 0rem;
    background: v.$negro;

    // border-top: .6rem solid transparent;
    // border-image-source: v.$gradient-dorado-01; 
    // border-image-slice: 1;
    @include m.mq-sm{
    //   border-top: none;
      padding: 0rem 0rem 2rem 0rem;
    }
  }
  h1:nth-of-type(1){
    // color: v.$azul-01;
    color: v.$negro;
    font-family: v.$font-bold-01;
    text-align: center;
    // line-height: 1.5;
    background: v.$gradient-dorado-01;
    padding: 3rem 8.33% 3rem 8.33%;
    @include m.mq-sm{
      line-height: 1.3;
      border-bottom: none;
      padding: 2rem 1.5rem 2rem 1.5rem;
    }
  }
  h4:nth-of-type(1){
    padding: 3rem 0rem 2rem 0rem;
    margin: 0rem 1.5rem 0rem 1.5rem;
    font-family: v.$font-regular-02;
    font-size: v.$body-02;
    line-height: 1.2;
    background: v.$negro;
    color: v.$blanco;
    text-align: center;
    @include m.mq-sm{
      font-size: v.$body-02;
    }
  }
  h4:nth-of-type(2){
    padding: 3rem 0rem 2rem 0rem;
    margin: 0rem 8.33% 0rem 8.33%;
    font-family: v.$font-regular-01;
    background: v.$negro;
    color: v.$blanco;
    text-align: center;

    border-bottom: .3rem solid transparent;
    border-image-source: v.$gradient-dorado-01; 
    border-image-slice: 1;
    @include m.mq-sm{
      font-size: v.$body-02;
    }
  }
}