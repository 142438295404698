@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.main-preguntas-frecuentes{
  padding: 4rem 0rem 0rem 0rem;
  .content-block{
    padding: 7rem 0rem 0rem 0rem;
    .item-box{
      .accordion{
        display: grid;
        // row-gap: .5rem;
        .card{
          // box-shadow: 0rem .5rem 1.5rem v.$gris-02;
          border: none;
          border-radius: 1rem;
        }
        .card-header{
          padding: 1.5rem;
          background: v.$blanco;
          @include m.mq-sm{
            font-size: v.$body-03;
            padding: 1rem;
          }
          a{
            box-shadow: 0rem .5rem 1rem v.$gris-02;
            padding: 2.5rem 5.2rem;
            font-family: v.$font-regular-02;
            // font-size: v.$body-01;
            font-size: v.$h5-xl;
            color: v.$azul-01;
            background: v.$blanco;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap:0rem 1rem;
            align-items: center;
            // border: none;
            border-radius: 1rem;
            @include m.mq-sm{
              box-shadow: 0rem .25rem .5rem v.$gris-02;
              font-size: v.$body-03;
              padding: 2rem 1.5rem;
            }
            &:focus{
              outline: none;
            }
            &:hover{
              text-decoration: none;
              color: v.$azul-01;
              cursor: pointer;
            }
            span{
              pointer-events: none;
              // transform: rotate(90deg);
              i{
                pointer-events: none;
              }
              i:before{
                pointer-events: none;
                font-size: v.$body-06;
                font-weight: bolder;
                @include m.mq-sm{
                  font-size: v.$body-07;
                }
              }
            }
            span.active{
              transform: rotate(-180deg);
            }
          }
        }
        .card-body{
          font-family: v.$font-regular-02;
          font-size: v.$body-01;
          color: v.$azul-01;
          background: v.$blanco;
          padding: 3.5rem 6.7rem 3.5rem 6.7rem;
          // border-top: .1rem solid v.$gris-01;
          border-top: none;
          @include m.mq-sm{
            padding: 1rem 3rem 1rem 3rem;
            font-size: v.$body-04;
            line-height: 1.2;
          }
        }
      }
    }

    .item-box:nth-of-type(1){
      h1:nth-of-type(1){
        text-align: center;
        margin:0rem 0rem 7rem 0rem;
      }
      h4:nth-of-type(1){
        font-family: v.$font-regular-01;
        margin:0rem 0rem 5rem 0rem;
        @include m.mq-sm{
          font-size: v.$body-03;
        }
      }
      h4:nth-of-type(2){
        font-family: v.$font-regular-01;
        margin:0rem 0rem 5rem 0rem;
        @include m.mq-sm{
          font-size: v.$body-03;
        }
      }
    }
    
    .item-box:nth-of-type(2){
      padding: 0rem 5rem 5rem 5rem;
      @include m.mq-sm{
        padding: 0rem 1.5rem 3.5rem 1.5rem;
      }
      .title-accordion{
        text-align: center;
        padding: 1.5rem 1.5rem;
        margin:0rem 0rem 7rem 0rem;

        border-bottom: .3rem solid transparent;
        border-image-source: v.$gradient-dorado-01; 
        border-image-slice: 1;
        @include m.mq-sm{
          margin:0rem 0rem 4rem 0rem;
        }
      }
    }
    
    // .item-box:nth-of-type(3){
    //   padding: 0rem 5rem 5rem 5rem;
    //   @include m.mq-sm{
    //     padding: 0rem 1.5rem 3.5rem 1.5rem;
    //   }
    //   .title-accordion{
    //     text-align: center;
    //     padding: 1.5rem 1.5rem;
    //     margin:0rem 0rem 7rem 0rem;

    //     border-bottom: .3rem solid transparent;
    //     border-image-source: v.$gradient-azul-01; 
    //     border-image-slice: 1;
    //     @include m.mq-sm{
    //       margin:0rem 0rem 4rem 0rem;
    //     }
    //   }
    // }

    .item-box:nth-of-type(3){
      padding: 0rem 5rem 10rem 5rem;
      @include m.mq-sm{
        padding: 0rem 1.5rem 7rem 1.5rem;
      }
      .border-content{
        background: v.$gradient-dorado-01;
        height: .3rem;
        width: 100%;
        margin: 0rem 0rem 4rem 0rem;
      }
      p:nth-of-type(1){
        text-align: center;
        margin: 0rem 0rem 2.5rem 0rem;
        @include m.mq-sm{
          font-size: v.$body-02;
        }
      }
    }
  }
}