@use 'base/variables' as v;
@use 'base/mixins' as m;

#sliderCaracteristicasCondominio.carousel{
  .carousel-inner{
    position: relative;
    min-height: 17.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btn-left-slide,
  .btn-right-slide{
    font-size: v.$h2-xl;
    // color: v.$dorado-02;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
  }
  .btn-left-slide{
    position: absolute;
    left: 0;
    bottom: 45%;
    // @include m.mq-sm{
    //   bottom: 0;
    //   top: 12.5%;
    // }
  }
  .btn-right-slide{
    position: absolute;
    right: 0;
    bottom: 45%;
    // @include m.mq-sm{
    //   bottom: 0;
    //   top: 12.5%;
    // }
  }
}

#sliderTestimoniales.carousel{
  .carousel-inner{
    position: relative;

    min-height: 45rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btn-left-slide,
  .btn-right-slide{
    font-size: v.$h2-xl;
    color: v.$azul-01;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
  }
  .btn-left-slide{
    position: absolute;
    left: 0;
    bottom: 45%;
    @include m.mq-sm{
      bottom: 0;
      top: 30%;
    }
  }
  .btn-right-slide{
    position: absolute;
    right: 0;
    bottom: 45%;
    @include m.mq-sm{
      bottom: 0;
      top: 30%;
    }
  }
}

#sliderCondominio.carousel{
  .carousel-inner{
    position: relative;
    min-height: 300px;
    @include m.mq-sm{
      height: auto;
    }
  }
  .btn-left-slide,
  .btn-right-slide{
    font-size: v.$h2-xl;
    color: v.$azul-01;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
    @include m.mq-md{
      font-size: v.$h1-sm;
    }
  }
  // .btn-left-slide{
  //   position: absolute;
  //   left: 0;
  //   bottom: 45%;
  //   @include m.mq-sm{
  //     bottom: 0;
  //     top: 12.5%;
  //   }
  // }
  // .btn-right-slide{
  //   position: absolute;
  //   right: 0;
  //   bottom: 45%;
  //   @include m.mq-sm{
  //     bottom: 0;
  //     top: 12.5%;
  //   }
  // }
}


.slider-galeria-etapa{
  position: relative;
  .carousel-indicators{
    position: static;
    margin-top: 1.5rem;
    li{
      height: 1rem;
      width: 4rem;
      border: none;
      border-radius: 0rem;
    }
    li.active{
      background: v.$gradient-dorado-01;
    }
    // li.active{
    //   background: v.$gradient-azul-01;
    // }
  }
  .control-slide-prev{
    position: absolute;
    left:5%;
    bottom:45%;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
    @include m.mq-sm{
      left:2.5%;
      // opacity: 0;
      // &:hover{
        // opacity: 0;
      // }
    }
    span{
      i:before{
        font-size: v.$h1-xl;
        color: v.$blanco;
        @include m.mq-sm{
          font-size: v.$h1-sm;
        }
      }
    }
  }
  .control-slide-next{
    position: absolute;
    right:5%;
    bottom:45%;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
    @include m.mq-sm{
      right:2.5%;
      // opacity: 0;
      // &:hover{
        // opacity: 0;
      // }
    }
    span{
      i:before{
        font-size: v.$h1-xl;
        color: v.$blanco;
        @include m.mq-sm{
          font-size: v.$h1-sm;
        }
      }
    }
  }
}

.slider-galeria-specs-condominio{
  .control-slide-prev{
    position: absolute;
    left:5%;
    bottom:45%;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
    @include m.mq-sm{
      left:2.5%;
    }
    span{
      i:before{
        font-size: v.$h1-xl;
        color: v.$blanco;
        @include m.mq-sm{
          font-size: v.$h1-sm;
        }
      }
    }
  }
  .control-slide-next{
    position: absolute;
    right:5%;
    bottom:45%;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
    @include m.mq-sm{
      right:2.5%;
    }
    span{
      i:before{
        font-size: v.$h1-xl;
        color: v.$blanco;
        @include m.mq-sm{
          font-size: v.$h1-sm;
        }
      }
    }
  }
  .carousel-item{
    .block{
      background: v.$blanco;
      max-width: 210px;
      margin: 0 auto;
    }
    h5{
      // background: v.$blanco;
      padding: .5rem .5rem .3rem .5rem;
      text-align: center;
    }
  }
}