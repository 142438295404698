@use 'base/variables' as v;
@use 'base/mixins' as m;

/** TERMINOS CONDICIONES **/
.main-terminos-condiciones{
  padding: 4rem 0rem 0rem 0rem;
}

.content-terminos-condiciones{
  padding: 7rem 0rem 0rem 0rem;
  h1{
    font-family: v.$font-bold-01;
    // font-size: v.$h3-xl;
    @include m.mq-sm{
      font-size: v.$h1-sm;
    }
  }
  h2{
    // margin: 5rem 0rem 0rem 0rem;
    font-family: v.$font-bold-01;
    // font-size: v.$h4-xl;
    @include m.mq-sm{
      font-size: v.$h2-sm;
    }
  }
  p{
    font-family: v.$font-regular-02;
    font-size: v.$body-01;
    @include m.mq-sm{
      font-size: v.$body-03;
    }
  }
}