@use '../../base/variables' as v;
@use '../../base/mixins' as m;


.section-etapa-2-03{
  padding: 8rem 0rem 10rem 0rem;
  @include m.mq-sm{
    padding: 6rem 0rem 6rem 0rem;
  }
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block:nth-of-type(1){
    .item-box:nth-of-type(1){
      h2:nth-of-type(1){
        color: v.$azul-01;
        text-align: center;
        margin: 0rem 0rem 6rem 0rem;
        @include m.mq-sm{
          padding: 0rem 1.5rem 0rem 1.5rem;
        }
      }
      p:nth-of-type(1){
        text-align: center;
        margin: 0rem 0rem 7.5rem 0rem;
        @include m.mq-sm{
          padding: 0rem 1.5rem 0rem 1.5rem;
          font-size: v.$body-02;
        }
      }
    }
  }
  .content-block:nth-of-type(2){
    display: grid;
    grid-template-columns: 65% 35%;
    // column-gap: 8rem;
    padding:3.5rem 0rem 3.5rem 0rem;
    @include m.mq-md{
      padding:3.5rem 0rem 3.5rem 0rem;
    }
    @include m.mq-sm{
      grid-template-columns: 1fr;
      padding:0rem 0rem 0rem 0rem;
    }
    .head-tab{
      p:nth-of-type(1){
        border-top: .5rem solid transparent;
        border-image-source: v.$gradient-azul-01; 
        border-image-slice: 1;

        padding: 1.5rem 0rem 1.2rem 0rem;

        background: v.$blanco;
        text-align: center;
        font-family: v.$font-bold-01;
        font-size: v.$body-01;
        @include m.mq-sm{
          // margin:0rem 0rem 0rem 0rem;
          // color: v.$blanco;
          // font-size: v.$body-02;
          padding: 1rem 0rem .6rem 0rem;
        }
      }
    }
    .content-spec{
      .content-interaction{
        padding: 0rem 6rem 0rem 3.5rem;
        @include m.mq-md{
          padding: 1rem 2.5rem 1rem 2.5rem;
        }
        @include m.mq-sm{
          padding: 1rem 1rem 1rem 1rem;
          // background: v.$negro;
        }
        ul{
          @include m.mq-sm{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 1.5rem;
            row-gap: 1.5rem;
          }
          li{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap:0rem 1rem;
            margin:0rem 0rem 3rem 0rem;
            font-size: v.$body-03;
            @include m.mq-xs-01{
              flex-direction: column;
              text-align: center;
              gap:.2rem 0rem;
            }
            span{
              width: 8rem;
              text-align: center;
              @include m.mq-sm{
                width: 4rem;
              }
              i:before{
                font-size: v.$h1-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h3-sm;
                }
              }
              .icon-spec-parqueadero:before{
                font-size: v.$h2-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h4-sm;
                }
              }
              .icon-spec-pasillo:before{
                font-size: v.$h3-xl;
                color: v.$dorado-02;
                @include m.mq-sm{
                  font-size: v.$h5-sm;
                }
              }
            }
            @include m.mq-sm{
              // color: v.$blanco;
              margin:0rem 0rem 0rem 0rem;
              font-size: v.$body-05;
            }
          }
          li:nth-of-type(1){
            // font-size: v.$h3-xl;
            font-family: v.$font-bold-01;
            // color: v.$dorado-02;
            color: v.$azul-01;
            text-align: center;
            margin: 0rem 0rem 5rem 0rem;
            justify-content: center;
            align-items: center;
            @include m.mq-sm{
              font-family: v.$font-bold-02;
              font-size: v.$body-04;
              // color: v.$blanco;
              color: v.$dorado-02;
              margin: 0rem 0rem 0rem 0rem;
            }
          }
          li:nth-of-type(6){
            margin:0rem 0rem 4rem 0rem;
            @include m.mq-sm{
              margin: 0rem 0rem 0rem 0rem;
            }
          }
          li:last-of-type{
            font-size: v.$body-05;
            text-align: center;
            @include m.mq-sm{
              display: none;
            }
          }
        }
      }
      .content-interaction.disabled{
        ul{
          // @include m.mq-sm{
          //   display: grid;
          //   grid-template-columns: repeat(3, 1fr);
          //   column-gap: 1.5rem;
          //   row-gap: 1.5rem;
          // }
          li{
            span{
              i:before{
                color: v.$gris-02;
              }
              .icon-spec-parqueadero:before{
                color: v.$gris-02;
              }
            }
            @include m.mq-sm{
              color: v.$gris-02;
            }
          }
          li:nth-of-type(1){
            color: v.$gris-02;
            @include m.mq-sm{
              color: v.$gris-02;
            }
          }
        }
      }
    }

    .item-box{
      @include m.mq-sm{
        display: grid;
        grid-template-columns: 1fr;
        .block-nav{
          grid-row: 2/3;
          padding: 1rem 3.5rem 0rem 3.5rem;
        }
      }
      .block-nav{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        // align-items: flex-end;
        align-items: flex-start;
        // padding: 1rem 0rem 0rem 0rem;
        .nav.nav-tabs{
          width: 100%;
          .nav-item{
            width: 50%;
            text-align: center;
            .nav-link{
              font-family: v.$font-regular-01;
              font-size: v.$h3-sm;
              // background: v.$negro;
              background: v.$blanco;
              // color: v.$blanco;
              color: v.$azul-01;
              padding: 1rem 0rem .7rem 0rem;
              border: none;
              border-radius: 0rem;
              cursor: pointer;
              border-bottom: .5rem solid transparent;
              border-image-source: v.$negro; 
              border-image-slice: 1;
              // @include m.mq-sm{
              //   font-size: v.$h3-sm;
              //   padding: .5rem 0rem .2rem 0rem;
              // }
              @include m.mq-sm{
                border: .2rem solid transparent;
                border-image-source: v.$negro; 
                border-image-slice: 1;
                font-family: v.$font-regular-02;
                font-size: v.$body-04;
                text-transform: uppercase;
              }
            }
            .nav-link.active{
              font-family: v.$font-bold-01;
              border: none;
              border-radius: 0rem;
              // background: v.$blanco;
              background: v.$gris-03;
              color: v.$negro;
              border-bottom: .5rem solid transparent;
              // border-image-source: v.$gradient-dorado-01; 
              border-image-source: v.$gradient-azul-01; 
              border-image-slice: 1;
              @include m.mq-sm{
                border: .2rem solid transparent;
                border-image-source: v.$gradient-azul-01; 
                border-image-slice: 1;
                font-family: v.$font-bold-02;
                font-size: v.$body-04;
              }
            }
          }
        }
        .nav-tabs {
          border-bottom: none;
        }
      }
    }
    // BK NAV TABS ETAPA 2
    // .block-nav{
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: flex-end;
    //   // align-items: flex-end;
    //   align-items: flex-start;
    //   // padding: 1rem 0rem 0rem 0rem;
    //   .nav.nav-tabs{
    //     width: 100%;
    //     .nav-item{
    //       width: 50%;
    //       text-align: center;
    //       .nav-link{
    //         font-family: v.$font-bold-01;
    //         font-size: v.$body-03;
    //         text-transform: uppercase;
    //         // background: v.$negro;
    //         background: v.$gris-03;
    //         color: v.$azul-01;
    //         padding: 1.5rem 0rem 1.2rem 0rem;
    //         border: none;
    //         border-radius: 0rem;
    //         cursor: pointer;
    //         border-bottom: .5rem solid transparent;
    //         border-image-source: v.$negro; 
    //         border-image-slice: 1;
    //         // @include m.mq-sm{
    //         //   font-size: v.$h3-sm;
    //         //   padding: .5rem 0rem .2rem 0rem;
    //         // }
    //         @include m.mq-sm{
    //           font-family: v.$font-regular-01;
    //           margin:0rem 0rem 0rem 0rem;
    //           // color: v.$blanco;
    //           color: v.$azul-01;
    //           font-size: v.$body-02;
    //           padding: 1rem 0rem .6rem 0rem;
    //         }
    //       }
    //       .nav-link.active{
    //         border: none;
    //         border-radius: 0rem;
    //         background: v.$blanco;
    //         color: v.$negro;
    //         border-bottom: .5rem solid transparent;
    //         border-image-source: v.$gradient-azul-01; 
    //         border-image-slice: 1;
    //         font-family: v.$font-bold-01;
    //       }
    //     }
    //   }
    //   .nav-tabs {
    //     border-bottom: none;
    //   }
    // }


    .tab-content{
      position: relative;
      .tab-pane{
        position: relative;
      // padding: 1rem 0rem 0rem 0rem;
        .nav.nav-tabs{
          width: 100%;
          .nav-item{
            width: 50%;
            text-align: center;
            .nav-link{
              font-family: v.$font-regular-01;
              font-size: v.$h3-sm;
              // background: v.$negro;
              background: v.$blanco;
              // color: v.$blanco;
              color: v.$azul-01;
              padding: 1rem 0rem .7rem 0rem;
              border: none;
              border-radius: 0rem;
              cursor: pointer;
              border-bottom: .5rem solid transparent;
              border-image-source: v.$negro; 
              border-image-slice: 1;
              // @include m.mq-sm{
              //   font-size: v.$h3-sm;
              //   padding: .5rem 0rem .2rem 0rem;
              // }
              @include m.mq-sm{
                border: .2rem solid transparent;
                border-image-source: v.$negro; 
                border-image-slice: 1;
                font-family: v.$font-regular-02;
                font-size: v.$body-04;
                text-transform: uppercase;
              }
            }
            .nav-link.active{
              font-family: v.$font-bold-01;
              border: none;
              border-radius: 0rem;
              // background: v.$blanco;
              background: v.$gris-03;
              color: v.$negro;
              border-bottom: .5rem solid transparent;
              border-image-source: v.$gradient-dorado-01; 
              border-image-slice: 1;
              @include m.mq-sm{
                border: .2rem solid transparent;
                border-image-source: v.$gradient-dorado-01; 
                border-image-slice: 1;
                font-family: v.$font-bold-02;
                font-size: v.$body-04;
              }
            }
          }
        }
        .content-tab{
          display: block;
          @include m.mq-sm{
            display: grid;
            grid-template-columns: 1fr;
            .block-nav{
              grid-row: 1/2;
              padding: 1rem 3.5rem 0rem 3.5rem;
            }
          }
        }
        .indicador{
          width: 1.9rem;
          height: 1.9rem;
          background: url('./../../build/img/indicador-tooltip.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          cursor: pointer;
          position: absolute; // -> Testing
          z-index: 1000;
          @include m.mq-xs-02{
            width: 1.6rem;
            height: 1.6rem;
          }
          &:hover{
            transform: scale(1.1);
          }
          // CASA C PISO 01
          &.item-c-01-01{
            left:15%;
            top:20%;
            @include m.mq-sm{
              left:21%;
              top:85%;
            }
          }
          &.item-c-01-02{
            left:39%;
            top:20%;
            @include m.mq-sm{
              left:21%;
              top:58%;
            }
          }
          &.item-c-01-03{
            left:39%;
            top:35%;
            @include m.mq-sm{
              left:35%;
              top:58%;
            }
          }
          &.item-c-01-04{
            left:55%;
            top:27%;
            @include m.mq-sm{
              left:28%;
              top:41%;
            }
          }
          &.item-c-01-05{
            left:92%;
            top:3%;
            @include m.mq-sm{
              left:3%;
              top:5%;
            }
          }
          &.item-c-01-06{
            left:80%;
            top:27%;
            @include m.mq-sm{
              left:25%;
              top:20%;
            }
          }
          &.item-c-01-07{
            left:39%;
            top:70%;
            @include m.mq-sm{
              left:65%;
              top:58%;
            }
          }
          &.item-c-01-08{
            left:51%;
            top:88%;
            @include m.mq-sm{
              left:88%;
              top:45%;
            }
          }
          &.item-c-01-09{
            left:63%;
            top:66%;
            @include m.mq-sm{
              left:70%;
              top:25%;
            }
          }
          // CASA C PISO 02
          &.item-c-02-01{
            left:25.5%;
            top:33%;
            @include m.mq-sm{
              left:33%;
              top:70%;
            }
          }
          &.item-c-02-02{
            left:60%;
            top:40%;
            @include m.mq-sm{
              left:40%;
              top:45%;
            }
          }
          &.item-c-02-03{
            left:72%;
            top:35%;
            @include m.mq-sm{
              left:36%;
              top:26%;
            }
          }
          &.item-c-02-04{
            left:23%;
            top:90%;
            @include m.mq-sm{
              left:90%;
              top:73%;
            }
          }
          &.item-c-02-05{
            left:45%;
            top:75%;
            @include m.mq-sm{
              left:70%;
              top:45%;
            }
          }
        }
      }
    }
  }
}