@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-contacto-01{
  padding: 9rem 0rem 8rem 0rem;
  @include m.mq-sm{
    padding: 0rem 0rem 0rem 0rem;
  }
  .padding-alterno{
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .content-block{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    @include m.mq-md{
      grid-template-columns: 1fr;
      row-gap: 5rem;
    }
    @include m.mq-sm{
      row-gap: 0rem;
    }
    .item-box:nth-of-type(1){
      display: flex;
      flex-direction: column;
      justify-content: center;
      img:nth-of-type(1){
        @include m.mq-sm{
          margin-bottom: 3.5rem;
        }
      }
      .box{
        position: relative;
        @include m.mq-sm{
          margin: 0rem 0rem 0rem 0rem;
        }
        .border-content{
          position: absolute;
          right: 0rem;
          top: -.5rem;
          background: v.$gradient-dorado-01;
          height: 1rem;
          width: 35%;
          @include m.mq-sm{
            width: 50%;
          }
        }
        .head-content{
          background: v.$negro;
          padding: 1.5rem 16.66% 1.5rem 16.66%; 
          @include m.mq-sm{
            padding: 3.5rem 8.33% 3.5rem 8.33%;
          }
          h2:nth-of-type(1){
            color: v.$blanco;
            margin: 0rem 0rem 1rem 0rem;
          }
          h5:nth-of-type(1){
            color: v.$blanco;
            font-family: v.$font-regular-01;
            @include m.mq-sm{
              font-size: v.$body-02;
              font-family: v.$font-regular-02;
            }
          }
        }
        .content{
          padding: 3.5rem 16.66% 3.5rem 16.66%;
          @include m.mq-sm{
            padding: 3.5rem 8.33% 3.5rem 8.33%;
          }
          ul{
            display: flex;
            flex-direction: column;
            gap: 2rem 0rem;
            li{
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 0rem 2rem;
              font-size: v.$body-03;
              @include m.mq-sm{
                font-size: v.$body-04;
              }
              .link{
                color: v.$azul-05;
                font-size: v.$body-03;
                @include m.mq-sm{
                  font-size: v.$body-04;
                }
                &:focus{
                  color: v.$azul-05;
                }
                &:hover{
                  color: v.$dorado-02;
                }
              }
              .link-mail{
                color: v.$azul-01;
                font-size: v.$body-03;
                @include m.mq-sm{
                  font-size: v.$body-04;
                }
                &:focus{
                  color: v.$azul-01;
                }
                &:hover{
                  color: v.$dorado-02;
                }
              }
              span{
                i:before{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  width: 4rem;
                  font-size: v.$h4-xl;
                  @include m.mq-sm{
                    font-size: v.$h5-xl;
                  }
                }
                .icon-email:before{
                  font-size: v.$h6-xl;
                  @include m.mq-sm{
                    font-size: v.$h6-sm;
                  }
                }
              }
            }
            li:nth-of-type(2){
              .order-horizontal{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap:.5rem 0rem;
              }
            }
          }
        }
      }
    }
    // .item-box:nth-of-type(2){
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: flex-end;
    //   align-items: center;
    // }
  }
  .border-section{
    width: 100%;
    height: .3rem;
    background: v.$gradient-dorado-01;
    margin: 8rem 0rem 0rem 0rem;
    @include m.mq-sm{
      margin: 5rem 0rem 0rem 0rem;
    }
  }
}