@use 'base/variables' as v;
@use 'base/mixins' as m;

@keyframes pulse-scale {
	0% {
		transform: scale(1.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.menu-agil{
  position: fixed;
  // bottom: 2.5rem;
  z-index: 1000;
  // bottom: 2.5rem;
  bottom: 17.5%;
  right: 4rem;
  @include m.mq-sm{
    bottom: 1rem;
    right: 1rem;
  }
  span{
    i:before{
      display: block;
      color:v.$blanco;
      font-size: 5rem;
      padding: 2rem;
      background: v.$verde-01;
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      opacity: 1;
      transform: scale(1);
      animation: pulse 2s infinite;
      z-index: 1200;
      transition: ease-in-out .3s;
      @include m.mq-sm{
        font-size: 3.5rem;
        padding: 1.5rem;
      }
    }
  }
  &:hover{
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    opacity: 1;
    border-radius: 50%;
    transform: scale(1.1);
    animation: pulse-scale 2s infinite;
  }
  // @media screen and (max-width: v.$sm) {
  //   // bottom: 1rem;
  //   bottom: 1.7rem;
  //   right: 1.1rem;
  // }

  // .contendor-btn{
  //   position: relative;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-end;
  //   align-items: flex-start;
  //   // padding: .7rem;
  //   img:nth-of-type(1){
  //     margin: .7rem;
  //     @media screen and (max-width: v.$sm) {
  //       margin: .7rem;
  //       width: 50px;
  //       height: 50px;
  //     }
  //   }
  // }
}