@use 'base/variables' as v;
@use 'base/mixins' as m;

/* CHATBOT */
@keyframes pulse-scale {
	0% {
		transform: scale(1.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes number-transition {
	0% {
		opacity: 0;
    transform: scale(.25);
	}
    25% {
		opacity: 1;
    transform: scale(.9);
	}
	100% {
		opacity: 1;
    transform: scale(1);
	}
}

.chatbot-initial-state {
  position: fixed;
  // bottom: 2.5rem;
  bottom: 2.5rem;
  right: 2rem;
  background: v.$gradient-dorado-01;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  transform: scale(1);
  animation: pulse 2s infinite;
  z-index: 1200;
  padding: .7rem;
  @media screen and (max-width: v.$sm) {
    // bottom: 1rem;
    bottom: 1.7rem;
    right: 1.1rem;
    padding: .3rem;
  }
  &:hover{
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    opacity: 1;
    transform: scale(1.1);
    animation: pulse-scale 2s infinite;
  }
  .contendor-btn{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    // padding: .7rem;
    img:nth-of-type(1){
      margin: .7rem;
      @media screen and (max-width: v.$sm) {
        margin: .7rem;
        width: 50px;
        height: 50px;
      }
    }
    .contador-msn{
      position: absolute;
      background: v.$dorado-03;
      color: v.$azul-02;
      font-family: v.$font-bold-02;
      font-size: v.$body-03;
      border-radius: 50%;
      padding: .5rem;
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid v.$azul-02;

      animation-name: number-transition;
      animation-duration: .5s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      @media screen and (max-width: v.$sm) {
        font-size: v.$body-05;
        padding: .25rem;
        width: 17px;
        height: 17px;
      }
    }
  }
}


/* CHATBOT SMALL */
.chatbot-small{
  z-index: 1150;
  position: fixed;
  // bottom:1.5rem;
  bottom:2rem;
  right:6.2rem;
  width: 300px;
  transition: .5s;
  @media screen and (max-width: v.$sm) {
    // bottom:.5rem;
    bottom:1.7rem;
    right:0;
    left: 0;
    margin: 0 auto;
    width: 95%;
  }
  .card{
    border: none;
    box-shadow: 0px 5px 10px rgba(0,0,0,.3);
    border-radius: 0rem;
    @media screen and (max-width: v.$sm) {
      // border-radius: 1rem 1rem 50% 1rem;
      // border-radius: 1rem 1rem 1rem 1rem;
      border-radius: 0rem;
    }
  }
  .card-body{
    padding: 1.2rem 3rem 2rem 1.4rem;
  }
}

// .head-chabot-small{
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   height: 100%;

//   // border-bottom: 1px solid #B0B0B0;
//   border-bottom: .1rem solid transparent;
//   border-image-source: v.$gradient-dorado-01; 
//   border-image-slice: 1;

//   padding: 0rem 0rem 1rem 0rem;
//   .logo-chatbot{
//     width: 13.8rem;
//   }
//   .content-head{
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//     align-items: center;
//     height: 100%;
//     gap: 0rem .7rem;
//   }
//   .status-chatbot{
//     color: v.$negro;
//     font-family: v.$font-regular-02;
//     font-size: v.$body-03;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;
//     gap: 0rem .8rem;
//     height: 100%;
//     .online-circle{
//       width: 1.2rem;
//       height: 1.2rem;
//       background: #81C342;
//       border-radius: 50%;
//     }
//   }
//   .close-chatbot{
//     cursor: pointer;
//     // .icon-arrow-down:before{
//     .icon-menu-close:before{
//       padding: .5rem;
//       border-radius: 50%;
//       font-size: v.$body-05;
//       text-align: center;
//       // background: v.$dorado-02;
//       // color: v.$blanco;
//       color: v.$azul-02;
//       font-weight: bolder;
//     }
//   }
// }


@keyframes transition-content-chatbot{
  0%{
    opacity: 0;
    transform: scale(0);
  }
  75%{
    opacity: 0;
    transform: scale(.75);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
}

.body-chatbot-small{
  padding: 1.5rem 1.6rem 0rem 0rem;
  .msn-default{
    color: v.$negro;
    font-family: v.$font-regular-02;
    font-size: v.$body-03;
    margin: 0rem 0rem 1rem 0rem;
  }
  &.transition{
    animation-name: transition-content-chatbot;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: .75s;
    opacity: 1;
    transform: scale(1);
  }
  p:nth-of-type(3){
    color: #B0B0B0;
    font-family: v.$font-regular-01;
    font-size: v.$body-04;
    margin: 1.5rem 0rem 0rem 0rem;
  }
}

/* CHATBOT FULL */
.chatbot-full{
  z-index: 1350;
  position: fixed;
  // bottom:1.5rem;
  bottom:2rem;
  right:2rem;
  width: 350px;
  transition: .5s;
  // min-height: 373px;
  @media screen and (max-width: v.$sm) {
    width: 100%;
    // height: 100%;
    bottom:0rem;
    right:0;
    left:0;
    margin: 0 auto;
  }
  .card{
    border: none;
    box-shadow: 0px 5px 10px rgba(0,0,0,.3);
    border-radius: 0rem;
    @media screen and (max-width: v.$sm) {
      height: 100vh;
      border-radius: 0rem;
      // height: calc(100vh - 48px);
    }
  }
  .card-body{
    padding: 1.2rem 3rem 2rem 1.4rem;

    border-top: .8rem solid transparent;
    border-image-source: v.$gradient-dorado-01; 
    border-image-slice: 1;
    @media screen and (max-width: v.$sm) {
      // padding: 1.2rem 1.4rem 2rem 1.4rem;
      border: none;
      overflow-y: scroll;
      padding: 6rem 1.4rem 2rem 1.4rem;
    }
  }
}

.head-chabot-full{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  
  // border-bottom: 1px solid #B0B0B0;
  border-bottom: .1rem solid transparent;
  border-image-source: v.$gradient-dorado-01; 
  border-image-slice: 1;

  padding: 0rem 0rem 1rem 0rem;
  background: v.$blanco;
  @media screen and (max-width: v.$sm) {
    // align-items: flex-start;
    height: auto;
  }
  &.fixed{
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 1600;
    padding: 1.2rem 1.4rem 1rem 1.4rem;
  }
  .logo-chatbot{
    width: 13.8rem;
  }
  .content-head{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 0rem .7rem;
    @media screen and (max-width: v.$sm) {
      align-items: center;
      height: auto;
    }
  }
  .status-chatbot{
    color: v.$negro;
    font-family: v.$font-regular-02;
    font-size: v.$body-04;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0rem .8rem;
    height: 100%;
    .online-circle{
      width: 1.2rem;
      height: 1.2rem;
      background: #81C342;
      border-radius: 50%;
    }
  }
//   .close-chatbot{
//     cursor: pointer;
//     // .icon-arrow-down:before{
//     .icon-menu-close:before{
//       padding: .5rem;
//       border-radius: 50%;
//       font-size: v.$body-05;
//       text-align: center;
//       // background: v.$dorado-02;
//       // color: v.$blanco;
//       color: v.$azul-02;
//       font-weight: bolder;
//     }
//   }
  .close-chatbot{
    span{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        i:before{
            display: inline-flex;
            color: v.$blanco;
            background: v.$dorado-02;
            padding: .5rem .5rem .5rem .5rem;
            border-radius: 50%;
            font-size: v.$body-06;
        }
    }
  }
}

.body-chatbot-full{
  // padding: 4rem 0rem 0rem 0rem;
  padding: 2rem 0rem 0rem 0rem;
  margin: 0rem 1.2rem 0rem 2.6rem;
  @media screen and (max-width: v.$sm) {
    padding: 0rem 0rem 0rem 0rem;
    margin:0rem 1.2rem 0rem 1.2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .powered{
    color: #B0B0B0;
    font-family: v.$font-bold-01;
    font-size: v.$body-05;
    text-align: center;
    margin: 2.5rem 0rem 0rem 0rem;
    @media screen and (max-width: v.$sm) {
      margin: 2.5rem 0rem 0rem 0rem;
    }
  }
  .content-chatbot-full-inicio{
    // p:nth-of-type(1){
    //   color: v.$negro;
    //   font-family: v.$font-regular-02;
    //   font-size: v.$body-05;
    //   margin: 0rem 0rem 1.5rem 0rem;
    //   // text-align: center;
    //   line-height: 1;
    //   span{
    //     font-family: v.$font-bold-02;
    //   }
    //   @media screen and (max-width: v.$sm) {
    //     // margin: 0rem 0rem 7rem 0rem;
    //     margin: 0rem 0rem 1.5rem 0rem;
    //   }
    // }
    // p:nth-of-type(2){
    //   color: v.$negro;
    //   font-family: v.$font-regular-02;
    //   font-size: v.$body-05;
    //   margin: 0rem 0rem 2rem 0rem;
    //   // text-align: center;
    //   @media screen and (max-width: v.$sm) {
    //     margin: 0rem 0rem 3rem 0rem;
    //   }
    // }
    p{
    //   color: v.$negro;
      color: v.$dorado-02;
      font-family: v.$font-regular-02;
      font-size: v.$body-03;
      margin: 0rem 0rem 2rem 0rem;
      text-align: center;
      // text-align: center;
      @media screen and (max-width: v.$sm) {
        margin: 0rem 0rem 3rem 0rem;
        font-size: v.$body-03;
      }
    }
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        margin: 0rem 0rem 1rem 0rem;
        @media screen and (max-width: v.$sm) {
          margin: 0rem 0rem 2rem 0rem;
        }
        // a{
        button{
          background-color: v.$blanco;
          position: relative;
          display: block;
          // color: v.$blanco;
          color: v.$dorado-02;
          font-family: v.$font-bold-01;
          font-size: v.$body-03;
          width: 100%;
          // border-radius: 1.5rem;
          // background: v.$dorado-02;
          border: .1rem solid v.$dorado-02;
          padding: 1.4rem .5rem 1.1rem .5rem;
          text-align: center;
          box-shadow: 0px 5px 10px rgba(0,0,0,.3);
          @media screen and (max-width: v.$sm) {
            font-size: v.$h2-sm - .4;
          }
          &:hover{
            // color: v.$blanco;
            color: v.$azul-02;
            cursor: pointer;
            // background: darken(v.$dorado-02, 5) ;
            // background: darken(v.$gradient-dorado-01, 5) ;
            background: v.$gradient-dorado-01;
            border: .1rem solid transparent;
          }
          .icon-arrow-right:before{
            position: absolute;
            right: 1.5rem;
            top: 1.2rem;
            color: v.$blanco;
            font-size: v.$body-05 - .4;
            font-weight: bolder;
          }
        }
      }
      li:last-of-type{
        margin: 0rem 0rem 0rem 0rem;
      }
    }
    &.transition{
      animation-name: transition-content-chatbot;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-duration: .75s;
      opacity: 1;
      transform: scale(1);
    }
  }

  .content-chatbot-full-asesoria{
    p:nth-of-type(1){
      color: v.$dorado-02;
      font-family: v.$font-regular-02;
      font-size: v.$body-03;
      // padding: 1.5rem;
      margin: 0rem 0rem 1.5rem 0rem;
      text-align: center;
      // background: v.$azul-02;
    }
    // p:nth-of-type(2){
    //   color: v.$negro;
    //   font-family: v.$font-regular-02;
    //   font-size: v.$body-05;
    //   margin: 0rem 0rem 1.5rem 0rem;
    //   // text-align: center;
    //   span{
    //     font-family: v.$font-bold-02;
    //   }
    // }

    // p:nth-of-type(3){
    //   color: v.$dorado-02;
    //   font-family: v.$font-bold-01;
    //   font-size: v.$body-01 - .2;
    //   margin: 0rem 0rem 2rem 0rem;
    //   text-align: center;
    // }
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      li{
        margin: 0rem 0rem 1rem 0rem;
        @media screen and (max-width: v.$sm) {
          margin: 0rem 0rem 2rem 0rem;
        }
        // a{
        button{
          background-color: v.$blanco;
          position: relative;
          display: block;
          // color: v.$blanco;
          color: v.$azul-02;
          font-family: v.$font-bold-01;
          font-size: v.$body-05;
          width: 100%;
          // border-radius: 1.5rem;
          // background: v.$dorado-02;
          border: .1rem solid v.$dorado-02;
          padding: .6rem .5rem;
          text-align: center;
          box-shadow: 0px 5px 10px rgba(0,0,0,.3);
          background-color: none;
          &:hover{
            // color: v.$blanco;
            color: v.$azul-02;
            cursor: pointer;
            // background: darken(v.$dorado-02, 5) ;
            // background: darken(v.$gradient-dorado-01, 5) ;
            background: v.$gradient-dorado-01;
            border: .1rem solid transparent;
          }
          .icon-arrow-right:before{
            position: absolute;
            right: 1.5rem;
            top: 1.2rem;
            color: v.$blanco;
            font-size: v.$body-05 - .4;
            font-weight: bolder;
          }
        }
      }
      li:last-of-type{
        margin: 0rem 0rem 0rem 0rem;
      }
    }
    &.transition{
      animation-name: transition-content-chatbot;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-duration: .75s;
      opacity: 1;
      transform: scale(1);
    }
  }
}


.body-chatbot-full.step-02,
.body-chatbot-full.step-03{
  // display: block;
  padding: 2rem 0rem 0rem 0rem;
}

/* CONTENT FORM CHATBOT */
.content-form-chatbot{
  .form-group.has-error{
    margin-bottom: .5rem;
  }
  .form-group {
    // position: relative;
    margin-bottom: .7rem;
    @media screen and (max-width: v.$sm) {
      margin-bottom: .7rem;
    }
    .container-option-check{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid v.$gris-01;
      // border: 1px solid v.$negro;
      padding: 0px 10px;
      // margin-bottom: 10px;
      margin-bottom: 1rem;
    }
    .container-option-check.error{
      border: 1px solid v.$rojo-alerta;
    }
    .container-option-check.ultimo{
      margin-bottom: 0px;
    }
    .option-checked{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      // border: 1px solid v.$dorado-02;
      border: .1rem solid transparent;
      border-image-source: v.$gradient-dorado-01; 
      border-image-slice: 1;

      padding: 0px 10px;
      margin-bottom: 10px;
      color: v.$dorado-02;
    }
    .option-checked > .tx-opcion-check{
      border-left:1px solid v.$dorado-02;
    }
    .checkbox-opcion{
      color: v.$azul-02;
      font-family: v.$font-regular-02;
      font-size: v.$body-05 !important;
      margin-right:10px;
      cursor: pointer;
    }
    .tx-opcion-check{
      font-family: v.$font-regular-02;
      font-size: v.$body-05;
      // letter-spacing: 1px;
      border-left:1px solid v.$gris-01;
      padding:8px 0px 2px 10px;
      height: 33px;
      @media screen and (max-width: v.$xl) {
        padding:7px 0px 0px 10px;
        font-size: v.$body-05;
        height: 30px;
      }
      @media screen and (max-width: v.$lg) {
        font-size: v.$body-05;
      }
    }
    .tx-opcion-check.error{
      border-left:1px solid v.$rojo-alerta;
      color: v.$rojo-alerta;
    }
    .help-block.with-errors .list-unstyled{
      font-family: v.$font-regular-02;
      color:v.$rojo-alerta !important;
      margin:3px 0px 0px 1rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      line-height: 1;
      li{
        font-size: v.$body-05;
        @media screen and (max-width: v.$sm) {
          font-size: v.$body-05;
        }
      }
      @media screen and (max-width: v.$sm) {
        // bottom: 5px;
        margin:1.5px 0px 0px 1rem;
      }
    }
  }
  .checkbox{
    font-family: v.$font-regular-02;
    // font-size: v.$body-04 - .2;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 0rem 1rem;
    margin: 0rem 0rem 0rem 0rem;
    input{
      margin: .5rem 0rem 0rem 0rem;
    }
    p:nth-of-type(1){
      color: v.$azul-02;
      font-family: v.$font-regular-02;
      font-size: v.$body-06;
      margin:0rem 0rem 0rem 0rem;
      line-height: 1.25;
      text-align: left;
      // width: 80%;
      // width: 268px;
      .tx_terminos{
        color: v.$azul-02;
        font-family: v.$font-regular-02;
        font-size: v.$body-06;
        text-decoration: underline;
        cursor: pointer;
        @media screen and (max-width: v.$sm) {
          font-size: v.$body-06;
        }
      }
      @media screen and (max-width: v.$sm) {
        font-size: v.$body-06;
        width: auto;
      }
    }
  }
//   #msgSubmitCondiciones_chatbot,
//   #msgSubmit_chatbot{
//     text-align: center;
//     font-size: v.$body-05;
//     font-family: v.$font-regular-02;
//     color:v.$rojo-alerta !important;
//     margin:3px 0px 0px 0px;
//     @media screen and (max-width: v.$sm) {
//       font-family: v.$font-regular-02;
//       margin:1.5px 0px 0px 0px;
//       font-size: v.$body-05;
//     }
//   }
//   #msgSubmitRecaptchaBot{
//     text-align: center;
//     font-size: v.$body-05;
//     font-family: v.$font-regular-02;
//     color:v.$rojo-alerta !important;
//     margin:3px 0px 0px 0px;
//     @media screen and (max-width: v.$sm) {
//       font-family: v.$font-regular-02;
//       margin:1.5px 0px 0px 1rem;
//       font-size: v.$body-05;
//       text-align: left;
//     }
//   }
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    // color: #495057;
    color: v.$gris-01;
    background-color: #fff;
    background-clip: padding-box;

    border: .1rem solid transparent;
    border-image-source: v.$gradient-dorado-01; 
    border-image-slice: 1;
    border-radius: 0rem;

    // color: v.$gris-01;
    color: v.$azul-01;
    font-family: v.$font-regular-02;
    font-size: v.$body-05;

    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus{
        box-shadow: none;
    }
  }
  .item-checbox{
    padding: 0rem 0rem 0rem 0rem;
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    align-items: center;
    gap:0rem 1.5rem;
    p:nth-of-type(1){
      color: v.$azul-01;
      font-size: v.$body-06;
      span{
        a{
          color: v.$azul-01;
          // font-size: v.$body-05;
          font-size: v.$body-06;
          text-decoration: underline;
        }
      }
    }
  }
//   .form-control:focus {
//     // color: #495057;
//     // color: v.$gris-01;
//     color: v.$azul-01;
//     background-color: #fff;
//     // border-color: #80bdff;
//     border-color: v.$dorado-02;
//     outline: 0;
//     // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
//     box-shadow: none;
//   }
//   .form-control.error{
//     // border-color: v.$rojo-alerta;
//     border: .1rem solid v.$rojo-alerta;
//     box-shadow: none;
//     color: v.$rojo-alerta;
//   }
//   .form-control.error::placeholder{
//     color: v.$rojo-alerta;
//   }
  /* PRISTINE IMPLEMENTATION */
  .pristine-error.text-help{
    // font-size: v.$body-05;
    font-size: v.$body-06;
    color: v.$rojo-alerta;
    margin: .5rem 0rem 0rem 1rem;
    text-align: left;
  }

  .input-error{
    // font-size: v.$body-05;
    font-size: v.$body-06;
    color: v.$rojo-alerta;
    margin: .5rem 0rem 0rem 1rem;
  }
  .form-control::placeholder{
    // color: v.$gris-01;
    color: v.$azul-01;
  }
  label{
    color: v.$azul-01;
    font-family: v.$font-regular-02;
    font-size: v.$body-04;
    line-height: 1;
    // margin: 1.5rem 0rem 2.5rem 0rem;
    margin: .3rem 0rem 1rem 0rem;
    @media screen and (max-width: v.$sm) {
      font-size: v.$body-04;
    }
  }
  .enviar-form{
    // display:block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0rem 1rem;

    background: v.$gradient-dorado-01;
    // font-size: v.$body-03;
    font-size: v.$body-01;
    font-family: v.$font-bold-01;
    padding-top: .3rem;
    padding-bottom: .3rem;
    width: 100%;
    // width: 160px;

    // border: none;
    border: .1rem solid transparent;

    margin:.7rem 0rem 0rem 0rem;
    // @media screen and (max-width: v.$xl) {
    //   font-size: v.$body-04;
    //   margin:30px 0px 0px 0px;
    //   padding-top: 7px;
    //   padding-bottom: 7px;
    // }
    // @media screen and (max-width: v.$sm) {
    //   width: 160px;
      // padding-top: .8rem;
      // padding-bottom: .8rem;
    //   padding-top: .3rem;
    //   padding-bottom: 0rem;
    // }
    &:focus{
      text-decoration: none;
      color: v.$azul-02;
      background: v.$gradient-dorado-01;
      outline: none;
    }
    &:hover{
      text-decoration: none;
    //   color:v.$blanco;
    //   background: v.$azul-02;
      color: v.$azul-02;
      background: v.$blanco;
      border: .1rem solid v.$azul-02;
    }
  }
}

.procesando-datos-chatbot{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}