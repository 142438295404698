@use '../../base/variables' as v;
@use '../../base/mixins' as m;

.section-etapa-1-01{
  padding: 5rem 0rem 9rem 0rem;
  .content-block:nth-of-type(1){
    padding: 0rem 0rem 5rem 0rem;
    h4:nth-of-type(1){
      font-family: v.$font-regular-01;
      text-align: center;
      span{
        font-family: v.$font-bold-01;
      }
      @include m.mq-sm{
        font-size: v.$h3-sm;
      }
    }
  }
  .content-block:nth-of-type(2){
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1.5rem;
    @include m.mq-md{
      grid-template-columns: repeat(2, 1fr);
      row-gap: 1.5rem;
    }
    @include m.mq-sm{
      grid-template-columns: 1fr;
    }
    .item-box{
      text-align: center;
      border-top: .2rem solid transparent;
      border-right: .2rem solid transparent;
      border-bottom: .2rem solid transparent;
      border-left: .2rem solid transparent;
      border-image-source: v.$gradient-dorado-01; 
      border-image-slice: 1;
      padding: 1.5rem;

      display: flex;
      flex-direction: column;
      gap: .5rem 0rem;
      @include m.mq-sm{
        padding: 2rem;
      }
      @include m.mq-sm{
        flex-direction: row;
        align-items: center;
        gap: 0rem 1.5rem;
      }
      span{
        i:before{
          color: v.$dorado-02;
          font-size: v.$h2-xl;
          margin: 0rem 0rem 1.5rem 0rem;
        }
      }
      h6:nth-of-type(1){
        color: v.$dorado-02;
        @include m.mq-sm{
          font-size: v.$body-03;
        }
      }
    }
  }
}